import React from 'react'
import { GrPrevious, GrNext } from 'react-icons/gr';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

import { useEffect } from 'react';
import { getEarthScopeList } from '../Redux/Earthscope/action';
import { useState } from 'react';

const Earthscoop = () => {
    const [earthScoopList, setEarthScoopList] = useState([])

    useEffect(() => {
        getEarthScopeList()((response) => {
            if (response && response.status) {
                setEarthScoopList(response?.data)
            }
        });
    }, [])
    function SampleNextArrow(props) {
        const { onClick } = props;
        return (
            <div style={{ color: "green" }} onClick={onClick}><GrNext className='next_icon' /> </div>
        );
    }

    function SamplePrevArrow(props) {
        const { onClick } = props;
        return (
            <div style={{ color: "green" }} onClick={onClick}><GrPrevious className='prev_icon' /> </div>
        );
    }
    var settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 2,
        autoplay: true,
        autoplaySpeed: 5000,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div className='earthscoop' id='earthscoop'>
            <div className='container'>
                <div className='row'>
                    <div className="col-12">
                        <div className='title'>
                            <h3>EARTHSCOOP</h3>
                            <p>Content rich library of Earthoodies’ articles on market dynamics.</p>
                        </div>
                    </div>
                </div>
                <div className='main-content'>
                    <div className='row'>
                        <Slider {...settings}>
                            {earthScoopList && earthScoopList?.map((item) =>
                                <div className=''>
                                    <div className='content-wrapper'>
                                        <div className='img-wrapper'>
                                            <img src={item?.image} className="  img-fluid" alt='earthoodie_img' />
                                        </div>
                                        <div className='content'>
                                            <p className='title'><Link to={`/${item.slug}`}> {item?.short_description}</Link></p>
                                            <p className='description'>- {item.author}
                                                <span></span>
                                            </p>
                                        </div>

                                    </div>
                                </div>
                            )}

                            {/* <div className=''>
                                <div className='content-wrapper'>
                                    <div className='img-wrapper'>
                                        <img src={img2} className="  img-fluid" alt='earthoodie_img' />
                                    </div>
                                    <div className='content'>
                                        <p className='title'><Link to='/earthscoop2'> Key Stakeholder’s Perspective On Esg</Link></p>
                                        <p className='description'>-By Avinash Kumar
                                            <span>(Executive Director)</span>
                                        </p>
                                    </div>

                                </div>
                            </div>
                            <div className=''>
                                <div className='content-wrapper'>
                                    <div className='img-wrapper'>
                                        <img src={img3} className="  img-fluid" alt='earthoodie_img' />
                                    </div>
                                    <div className='content'>
                                        <p className='title'><Link to='/earthscoop3'>United Nations’ Bridge From Kyoto to Paris</Link></p>
                                        <p className='description'>-By Dr. Kaviraj Singh
                                            <span>(MD)</span>
                                        </p>
                                    </div>

                                </div>
                            </div> */}
                            {/* <div className=''>
                                <div className='content-wrapper'>
                                    <div className='img-wrapper'>
                                        <img src={img4} className="  img-fluid" alt='earthoodie_img' />
                                    </div>
                                    <div className='content'>
                                        <p className='title'><Link to='/earthscoop4'>The Positive Side of COVID-19: Reduction in CO2 emissions</Link></p>
                                        <p className='description'>- By Dr. Kaviraj Singh
                                            <span>(MD)</span>
                                        </p>
                                    </div>

                                </div>
                            </div>
                            <div className=''>
                                <div className='content-wrapper'>
                                    <div className='img-wrapper'>
                                        <img src={img5} className="  img-fluid" alt='earthoodie_img' />
                                    </div>
                                    <div className='content'>
                                        <p className='title'><Link to='/earthscoop5'>New Climate Deal & No Clarity on CDM Continuity</Link></p>
                                        <p className='description'>- By Dr. Kaviraj Singh
                                            <span>(MD)</span>
                                        </p>
                                    </div>

                                </div>
                            </div>
                            <div className=''>
                                <div className='content-wrapper'>
                                    <div className='img-wrapper'>
                                        <img src={img6} className="  img-fluid" alt='earthoodie_img' />
                                    </div>
                                    <div className='content'>
                                        <p className='title'><Link to='/earthscoop6'>The World’s Drive Towards Carbon Neutrality</Link></p>
                                        <p className='description'>- By Archit Srivastava
                                            <span>(VP-Strategy & Growth)</span>
                                        </p>
                                    </div>

                                </div>
                            </div>
                            <div className=''>
                                <div className='content-wrapper'>
                                    <div className='img-wrapper'>
                                        <img src={img7} className="  img-fluid" alt='earthoodie_img' />
                                    </div>
                                    <div className='content'>
                                        <p className='title'><Link to='/earthscoop7'>Latest CDM EB Guidance for site visit in COVIDised</Link></p>
                                        <p className='description'>- By Dr. Kaviraj Singh
                                            <span>(MD)</span>
                                        </p>
                                    </div>

                                </div>
                            </div>
                            <div className=''>
                                <div className='content-wrapper'>
                                    <div className='img-wrapper'>
                                        <img src={img8} className="  img-fluid" alt='earthoodie_img' />
                                    </div>
                                    <div className='content'>
                                        <p className='title'><Link to='/earthscoop8'>The Future of Carbon Markets Post</Link></p>
                                        <p className='description'>-By Archit Srivastava
                                            <span>(VP-Strategy & Growth)</span>
                                        </p>
                                    </div>

                                </div>
                            </div>
                            <div className=''>
                                <div className='content-wrapper'>
                                    <div className='img-wrapper'>
                                        <img src={img9} className="  img-fluid" alt='earthoodie_img' />
                                    </div>
                                    <div className='content'>
                                        <p className='title'><Link to='/earthscoop9'>A Wake-Up Call</Link></p>
                                        <p className='description'>- By Avinash Kumar
                                            <span>(Executive Director)</span>
                                        </p>
                                    </div>

                                </div>
                            </div>
                            <div className=''>
                                <div className='content-wrapper'>
                                    <div className='img-wrapper'>
                                        <img src={img10} className="  img-fluid" alt='earthoodie_img' />
                                    </div>
                                    <div className='content'>
                                        <p className='title'><Link to='/earthscoop10'>Carbon Border Adjustment Mechanism</Link></p>
                                        <p className='description'>- By Kubra Agriman
                                            <span>(Assistant General Manager – Turkey Business)</span>
                                        </p>
                                    </div>

                                </div>
                            </div>
                            <div className=''>
                                <div className='content-wrapper'>
                                    <div className='img-wrapper'>
                                        <img src={img11} className="  img-fluid" alt='earthoodie_img' />
                                    </div>
                                    <div className='content'>
                                        <p className='title'><Link to='/earthscoop11'>Decoding Article 6.4 Of The Paris Agreement: Paris Mechanism</Link></p>
                                        <p className='description'>- By Dr. Kaviraj Singh
                                            <span>(MD)</span>
                                        </p>
                                    </div>

                                </div>
                            </div>
                            <div className=''>
                                <div className='content-wrapper'>
                                    <div className='img-wrapper'>
                                        <img src={img12} className="  img-fluid" alt='earthoodie_img' />
                                    </div>
                                    <div className='content'>
                                        <p className='title'><Link to='/earthscoop12'>Will Coronavirus Pandemic Save The World From Air Pollution and Climate
                                            Change?</Link> </p>
                                        <p className='description'>- By Dr. Kaviraj Singh
                                            <span>(MD)</span>
                                        </p>
                                    </div>

                                </div>
                            </div>
                            <div className=''>
                                <div className='content-wrapper'>
                                    <div className='img-wrapper'>
                                        <img src={img13} className="  img-fluid" alt='earthoodie_img' />
                                    </div>
                                    <div className='content'>
                                        <p className='title'><Link to='/earthscoop13'>5 Big Mistakes in First-Time ESG Reporting</Link></p>
                                        <p className='description'>-By Avinash Kumar
                                            <span>(Executive Director)</span>
                                        </p>
                                    </div>

                                </div>
                            </div> */}
                        </Slider>
                    </div>
                    {/* <div className='row'>
                        <div className='col-12 text-center'>
                            <Link to='/sustainability'><button className='view-btn'>View All</button></Link>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default Earthscoop;

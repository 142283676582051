import React from 'react'
import CountUp from 'react-countup';
import story_bg from '../assets/images/story.bg.png'
import icon1 from '../assets/images/story/icon1.png'
import icon2 from '../assets/images/story/icon2.png'
import icon3 from '../assets/images/story/icon3.png'
import icon4 from '../assets/images/story/icon4.png'
import icon5 from '../assets/images/story/icon5.png'
import icon6 from '../assets/images/story/icon6.png'
// import icon6 from '../assets/images/story/icon4.png'





const Story=() => {
    return (
        <div className='our-story' id='our-story'>
            <div className='story-bg'><img src={story_bg} className="" alt="Earthood" id='' /></div>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-4 df-center'>
                        <div className='content-wrapper'>
                            <h3>Our
                                Story</h3>
                            <p>Want to know how Earthood became the most trusted brand for carbon offset audits within a decade of its inception?</p>
                        </div>
                    </div>
                    <div className='col-lg-8'>
                        <div className='story-content'>
                            <div className='row'>
                                <div className='col-md-4  col-sm-6 pmt-40'>
                                    <div className='icon-wrapper'>
                                        <img src={icon1} className="" alt="Earthood" id='' />
                                    </div>
                                    <div className='count'>

                                        <CountUp end={1}
                                            prefix="#" />
                                    </div>
                                    <div className='count-content'>
                                        <h5>Carbon Verifier</h5>
                                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt </p> */}
                                    </div>
                                </div>
                                <div className='col-md-4  col-sm-6 pmt-40'>
                                    <div className='icon-wrapper'>
                                        <img src={icon2} className="" alt="Earthood" id='' />
                                    </div>
                                    <div className='count'>

                                        <CountUp end={21} />
                                    </div>
                                    <div className='count-content'>
                                        <h5>International Accreditations</h5>
                                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt </p> */}
                                    </div>
                                </div>
                                <div className='col-md-4  col-sm-6 pmt-40'>
                                    <div className='icon-wrapper'>
                                        <img src={icon3} className="" alt="Earthood" id='' />
                                    </div>
                                    <div className='count'>

                                        <CountUp end={3000}
                                            suffix="+" />
                                    </div>
                                    <div className='count-content'>
                                        <h5>Carbon Offset Projects</h5>
                                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt </p> */}
                                    </div>
                                </div>
                                <div className='col-md-4  col-sm-6 pmt-40'>
                                    <div className='icon-wrapper'>
                                        <img src={icon4} className="" alt="Earthood" id='' />
                                    </div>
                                    <div className='count'>

                                        <CountUp end={70}
                                            suffix="+" />
                                    </div>
                                    <div className='count-content'>
                                        <h5>Team</h5>
                                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt </p> */}
                                    </div>
                                </div>
                                <div className='col-md-4  col-sm-6 pmt-40'>
                                    <div className='icon-wrapper'>
                                        <img src={icon5} className="" alt="Earthood" id='' />
                                    </div>
                                    <div className='count'>

                                        <CountUp end={6} />
                                    </div>
                                    <div className='count-content'>
                                        <h5>Global Offices</h5>
                                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt </p> */}
                                    </div>
                                </div>
                                <div className='col-md-4  col-sm-6 pmt-40'>
                                    <div className='icon-wrapper'>
                                        <img src={icon6} className="" alt="Earthood" id='' />
                                    </div>
                                    <div className='count'>

                                        <CountUp end={40}
                                            suffix="%"
                                        />
                                    </div>
                                    <div className='count-content'>
                                        <h5>of the workforce are women</h5>
                                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt </p> */}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Story;

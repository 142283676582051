import React, { useEffect } from 'react'
import { Button, Form } from 'react-bootstrap';
import logo from '../../assets/images/footer-logo.png'
import linkedin from '../../assets/images/linkedin.png'
import instagram from '../../assets/images/instagram.png'
import twitter from '../../assets/images/twitter-logo.png'
import download from '../../assets/images/download.svg'
import brochuer from '../../assets/pdf/Earthood-Brochure-new.pdf'
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { newsLetter } from '../../Redux/Newsletter/action';
import ScrollToTop from 'react-scroll-to-top';
import TawkTo from 'tawkto-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Footer=(props) => {
    const currentYear=new Date().getFullYear();
    const showToastMessage=(message) => {
        toast.success(message, {
            position: toast.POSITION.TOP_RIGHT
        });
    };
    const { handleNavigateStory }=props;

    const navigate=useNavigate();
    // useEffect(() => {
    //     var tawk=new TawkTo('60c87f7165b7290ac6360830', '1f87j3l2c')

    //     tawk.onStatusChange((status) => {
    //         console.log(status)
    //     })
    // }, [])

    // our story navigation start
    // const handleNavigateStory=() => {
    //     if (window.location.pathname=="/") {

    //         let a=document.getElementById("navStory")

    //         a.setAttribute("href", "#our-story")
    //         const section=document.getElementById("our-story");
    //         if (section) {
    //             const topOffset=section.offsetTop;
    //             window.scrollTo({ top: topOffset, behavior: "smooth" });
    //         }
    //     } else {
    //         navigate("/");

    //         setTimeout(() => {

    //             const section=document.getElementById("our-story");
    //             console.log(section);
    //             if (section) {
    //                 const topOffset=section.offsetTop;
    //                 window.scrollTo({ top: topOffset, behavior: "smooth" });
    //             }
    //         }, 200);

    //     }
    // }
    // our story navigation end

    // Earthscoop navigation start
    const handleNavigateEarthscoop=() => {
        console.log(window.location.pathname,);
        if (window.location.pathname==="/") {
            let a=document.getElementById("navEarthscoop")
            console.log(a);
            a.setAttribute("href", "#earthscoop")
            const section=document.getElementById("earthscoop");
            if (section) {
                const topOffset=section.offsetTop;
                window.scrollTo({ top: topOffset, behavior: "smooth" });
            }
        } else {
            // navigate("/")

            setTimeout(() => {

                const section=document.getElementById("earthscoop");
                if (section) {
                    const topOffset=section.offsetTop;
                    window.scrollTo({ top: topOffset, behavior: "smooth" });
                }
            }, 20);

        }
    }
    // Earthscoop navigation end
    // Accreditation navigation start
    const handleNavigateAccreditations=() => {
        if (window.location.pathname=="/") {
            // navigate("/")
            let a=document.getElementById("navAccreditations")
            a.setAttribute("href", "#accreditations")
            const section=document.getElementById("accreditations");
            if (section) {
                const topOffset=section.offsetTop;
                window.scrollTo({ top: topOffset, behavior: "smooth" });
            }
        } else {
            navigate("/")

            setTimeout(() => {

                const section=document.getElementById("accreditations");
                if (section) {
                    const topOffset=section.offsetTop;
                    window.scrollTo({ top: topOffset, behavior: "smooth" });
                }
            }, 20);

        }
    }
    // Accreditation navigation end

    const validationSchema=Yup.object().shape({
        email: Yup.string().matches(/(\<|^)[\w\d._%+-]+@(?:[\w\d-]+\.)+(\w{2,})(\>|$)/, 'Enter a valid email')
            .required('Required'),
    });
    const formik=useFormik({
        initialValues: {
            email: "",
        },
        validationSchema,
        onSubmit: (values) => {
            let formData=new FormData();
            formData.append("email", values.email);
            newsLetter({ formData })((response) => {
                if (response&&response.status) {
                    console.log(response)
                    showToastMessage('Successfully Submitted!')
                    formik.handleReset()
                }
            });
        },
    });
    return (
        <React.Fragment>
            <ToastContainer />
            <div className='footer'>
                <div className='container'>
                    <div className='footer-top'>
                        <div className='row'>
                            <div className='col-lg-7'>
                                <h2>Solving the climate emergency
                                    starts today</h2>
                            </div>
                            <div className='col-lg-5'>
                                <div className='btn-wrapper'>
                                    <Link className='btn1' to='/contact'>Contact Us</Link>
                                    {/* <a href='/contact' className='btn1'>Contact Us</a> */}
                                    <Link to='/project' className='btn2'>Explore Projects</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className='hrline' />
                    <div className='footer-bottom'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='footer-logo'>
                                    <img src={logo} className="" alt="Earthood" id='header_image' />
                                </div>
                            </div>
                        </div>
                        <div className='footer-bottom-content'>
                            <div className='row'>
                                <div className='col-lg-8'>
                                    <div className='row'>
                                        <div className='col-md-4 col-sm-4 col-6'>
                                            <ul>
                                                <li><div className="link-title" href="">About</div></li>
                                                <li><Link id="navStory" to="/?section=our-story">Our Story</Link></li>
                                                <li><Link to="/case-study">Case Studies</Link></li>
                                                <li><Link to="/project">Projects</Link></li>
                                                <li><Link to="/earthoodies">Earthoodies</Link></li>
                                                <li><Link to="/?section=earthscoop">Earthscoop</Link></li>
                                                {/* <li><Link id="navEarthscoop" onClick={handleNavigateEarthscoop}>Earthscoop</Link></li> */}
                                                <li><Link to="/contact">Get In Touch</Link></li>
                                            </ul>
                                        </div>
                                        <div className='col-md-4 col-sm-4 col-6' >
                                            <ul>
                                                <li><div className="link-title">Services</div></li>
                                                <li><Link to="/climate">Climate Change</Link></li>
                                                <li><Link to="/esg">ESG & Decarbonization</Link></li>
                                                <li><Link to="/sustainability">Sustainability</Link></li>
                                                <li><Link to="/agriculture">Agriculture</Link></li>

                                            </ul>
                                        </div>
                                        <div className='col-md-4 col-sm-4 col-6'>
                                            <ul>
                                                <li><div className="link-title">More</div></li>
                                                <li><Link id="navAccreditations" to="/?section=accreditations">Accreditations</Link></li>
                                                <li><Link to="/privacy-policy">Policies</Link></li>
                                                <li><a target='_blank' href={brochuer}>Download Brochure</a></li>
                                                <li><Link  to='/reference-to-statements'>Reference to Statements</Link></li>


                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-8 col-sm-12'>
                                    <div className="newsletter">
                                        <p className=" link-title">Newsletter</p>
                                        <p>Enter your email address below to subscribe to our newsletter.</p>
                                        <div className="form">
                                            <Form onSubmit={formik.handleSubmit}>
                                                <Form.Group className="" controlId="formBasicEmail">
                                                    <Form.Control
                                                        name="email"
                                                        value={formik.values?.email}
                                                        onChange={formik.handleChange}
                                                        type="email"
                                                        isValid={formik.touched.email&&!formik.errors.email}
                                                        isInvalid={!!formik.errors.email}
                                                        placeholder=""

                                                    />
                                                    <Form.Control.Feedback type="invalid">{formik.errors.email}</Form.Control.Feedback>
                                                </Form.Group>
                                                <Button className='btn' type="submit">
                                                    Subscribe
                                                </Button>
                                            </Form>
                                        </div>
                                        <div className='follow'>
                                            <p>Follow us on social networks
                                                <span>
                                                    <Link className='social-icon' target='_blank' to='https://www.linkedin.com/company/earthood-services'>
                                                        <img src={linkedin} className="" alt="Earthood-linkedIn" id='header_image' />
                                                    </Link>

                                                    <Link className='social-icon' target='_blank' to='https://www.instagram.com/socialearthood/ '>
                                                        <img src={instagram} className="" alt="Instagram" id='header_image' />
                                                    </Link>

                                                    <Link className='social-icon' target='_blank' to='https://twitter.com/SocialEarthood'>
                                                        <img src={twitter} className="" alt="Twitter" id='header_image' />
                                                    </Link>
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr className='hrline' />
                        <div className='copyright'>
                            <p>Copyright © {currentYear} Earthood. All Rights Reserved.</p>
                            <p>Designed &amp; Developed by <Link to="https://www.aventusinformatics.com/" target="_blank" >Aventus Informatics</Link></p>
                        </div>
                    </div>
                </div>

                <div id="fixed-social" class="hidden-xs ">
                    <div>
                        <a href={brochuer} class="fixed-facebook" title="Download Brochure" download>
                            <img src={download} className="" alt="Earthood" id='header_image' />
                            <span>Download Brochure</span></a>
                    </div>
                </div>
            </div>
            <ScrollToTop smooth top="1000" color="#fff" width="18" height="18" style={{ backgroundColor: "rgb(16 177 229)", bottom: "60px" }} />
        </React.Fragment>
    )
}

export default Footer;
import { CLIENT_LIST, MILESTONE_LIST, TESTIMONIAL_LIST } from "../../api";
import axiosInstance from "../../axiosInstance";

export const getMileStoneList=() => onResponse => {
    try {
        axiosInstance.get(MILESTONE_LIST)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getTestimonialList=() => onResponse => {
    try {
        axiosInstance.get(TESTIMONIAL_LIST)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getClientList=() => onResponse => {
    try {
        axiosInstance.get(CLIENT_LIST)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
import React from 'react'
import { Link } from 'react-router-dom';
import banner_gif from '../assets/images/banner-gif.png'

const Earthoodies = () => {

    return (
        <div className='earthoodies' id='earthoodies'>
            <div className='overlay'></div>
            <div className='banner-gif '>
                <div className="box1 animated infinite rotate-full">
                    <img src={banner_gif} className="img-fluid" alt="Earthood" id='header_image' />
                </div>
            </div>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 df-center '>
                        <div className='content-wrapper'>
                            <h3>Meet Earthoodies
                            </h3>
                            <h6><span>"A group dedicated to inspiring global action on climate change through education, advocacy, and innovative solutions"</span></h6>
                            <h6><span>- Manish Negi</span></h6>
                            <Link to='/earthoodies'><button className='meet-btn'>Meet Earthoodies</button></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Earthoodies;

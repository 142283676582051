import React from 'react'
import Slider from 'react-slick';
import slider1 from '../assets/images/clients/slider1.png'
import slider2 from '../assets/images/clients/slider2.png'
import slider3 from '../assets/images/clients/slider3.png'
import slider4 from '../assets/images/clients/slider4.png'
import slider5 from '../assets/images/clients/slider5.png'
import slider6 from '../assets/images/clients/slider6.png'
import slider7 from '../assets/images/clients/slider7.png'
import slider8 from '../assets/images/clients/slider8.png'
import slider9 from '../assets/images/clients/slider9.png'
import slider10 from '../assets/images/clients/slider10.png'
import slider11 from '../assets/images/clients/slider11.png'
import slider12 from '../assets/images/clients/slider12.png'
import slider13 from '../assets/images/clients/slider13.png'
import slider14 from '../assets/images/clients/slider14.png'
import slider15 from '../assets/images/clients/slider15.png'
import slider16 from '../assets/images/clients/slider16.png'
import slider17 from '../assets/images/clients/slider17.png'
import slider18 from '../assets/images/clients/slider18.png'
import slider19 from '../assets/images/clients/slider19.png'
import slider20 from '../assets/images/clients/slider20.png'
import slider21 from '../assets/images/clients/slider21.png'
import slider22 from '../assets/images/clients/slider22.png'
import slider23 from '../assets/images/clients/slider23.png'
import slider24 from '../assets/images/clients/slider24.png'
import slider25 from '../assets/images/clients/slider25.png'
const Clients=() => {

    var settings={
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1000,
        initialSlide: 0,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div className='services clients' id='clients'>
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='title'>
                            <h3>Clients
                            </h3>
                            <p>Meet who benefited from our services</p>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='service-slider'>
                        <Slider {...settings}>
                            <div>
                                <div className='slider-wrapper'>
                                    <div className='img-wrapper'>
                                        <img src={slider1} alt='slider1' className='img-fluid' />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='slider-wrapper'>
                                    <div className='img-wrapper'>
                                        <img src={slider2} alt='slider1' className='img-fluid' />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='slider-wrapper'>
                                    <div className='img-wrapper'>
                                        <img src={slider3} alt='slider1' className='img-fluid' />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='slider-wrapper'>
                                    <div className='img-wrapper'>
                                        <img src={slider4} alt='slider1' className='img-fluid' />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='slider-wrapper'>
                                    <div className='img-wrapper'>
                                        <img src={slider5} alt='slider1' className='img-fluid' />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='slider-wrapper'>
                                    <div className='img-wrapper'>
                                        <img src={slider6} alt='slider1' className='img-fluid' />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='slider-wrapper'>
                                    <div className='img-wrapper'>
                                        <img src={slider7} alt='slider1' className='img-fluid' />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='slider-wrapper'>
                                    <div className='img-wrapper'>
                                        <img src={slider8} alt='slider1' className='img-fluid' />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='slider-wrapper'>
                                    <div className='img-wrapper'>
                                        <img src={slider9} alt='slider1' className='img-fluid' />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='slider-wrapper'>
                                    <div className='img-wrapper'>
                                        <img src={slider10} alt='slider1' className='img-fluid' />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='slider-wrapper'>
                                    <div className='img-wrapper'>
                                        <img src={slider11} alt='slider1' className='img-fluid' />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='slider-wrapper'>
                                    <div className='img-wrapper'>
                                        <img src={slider12} alt='slider1' className='img-fluid' />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='slider-wrapper'>
                                    <div className='img-wrapper'>
                                        <img src={slider13} alt='slider1' className='img-fluid' />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='slider-wrapper'>
                                    <div className='img-wrapper'>
                                        <img src={slider14} alt='slider1' className='img-fluid' />
                                    </div>
                                </div>
                            </div>
                            {/* <div>
                                <div className='slider-wrapper'>
                                    <div className='img-wrapper'>
                                        <img src={slider15} alt='slider1' className='img-fluid' />
                                    </div>
                                </div>
                            </div> */}
                            <div>
                                <div className='slider-wrapper'>
                                    <div className='img-wrapper'>
                                        <img src={slider16} alt='slider1' className='img-fluid' />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='slider-wrapper'>
                                    <div className='img-wrapper'>
                                        <img src={slider17} alt='slider1' className='img-fluid' />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='slider-wrapper'>
                                    <div className='img-wrapper'>
                                        <img src={slider18} alt='slider1' className='img-fluid' />
                                    </div>
                                </div>
                            </div>
                            {/* <div>
                                <div className='slider-wrapper'>
                                    <div className='img-wrapper'>
                                        <img src={slider19} alt='slider1' className='img-fluid' />
                                    </div>
                                </div>
                            </div> */}
                            {/* <div>
                                <div className='slider-wrapper'>
                                    <div className='img-wrapper'>
                                        <img src={slider20} alt='slider1' className='img-fluid' />
                                    </div>
                                </div>
                            </div> */}
                            <div>
                                <div className='slider-wrapper'>
                                    <div className='img-wrapper'>
                                        <img src={slider21} alt='slider1' className='img-fluid' />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='slider-wrapper'>
                                    <div className='img-wrapper'>
                                        <img src={slider22} alt='slider1' className='img-fluid' />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='slider-wrapper'>
                                    <div className='img-wrapper'>
                                        <img src={slider23} alt='slider1' className='img-fluid' />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='slider-wrapper'>
                                    <div className='img-wrapper'>
                                        <img src={slider24} alt='slider1' className='img-fluid' />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='slider-wrapper'>
                                    <div className='img-wrapper'>
                                        <img src={slider25} alt='slider1' className='img-fluid' />
                                    </div>
                                </div>
                            </div>


                        </Slider>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Clients;

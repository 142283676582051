import './assets/css/style.scss';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/index'
import Contact from './pages/contact-us/contact';
import Esg from './pages/esg/esg';
import Sustainability from './pages/sustainability';
import Agriculture from './pages/agriculture';
import Climate from './pages/climate';
import Casestudy from './pages/casestudy';
import Team from './pages/team';
import Policy from './pages/policy';
import Project from './pages/project';
import Earthscoop1 from './pages/earthscoop/earthscoop1';
import Earthscoop2 from './pages/earthscoop/earthscoop2';
import Earthscoop3 from './pages/earthscoop/earthscoop3';
import Earthscoop4 from './pages/earthscoop/earthscoop4';
import Earthscoop5 from './pages/earthscoop/earthscoop5';
import Earthscoop6 from './pages/earthscoop/earthscoop6';
import Earthscoop7 from './pages/earthscoop/earthscoop7';
import Earthscoop8 from './pages/earthscoop/earthscoop8';
import Earthscoop9 from './pages/earthscoop/earthscoop9';
import Earthscoop10 from './pages/earthscoop/earthscoop10';
import Earthscoop11 from './pages/earthscoop/earthscoop11';
import Earthscoop12 from './pages/earthscoop/earthscoop12';
import Earthscoop13 from './pages/earthscoop/earthscoop13';
import Webinar from './pages/webinar';
import ErrorPage from './components/404 Page';
import Error from './components/404 Page/error';
import Statements from './components/statements';
import Share from './components/share';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route exact path='/contact' element={<Contact />} />
        <Route exact path='/esg' element={<Esg />} />
        <Route exact path='/sustainability' element={<Sustainability />} />
        <Route exact path='/agriculture' element={<Agriculture />} />
        <Route exact path='/climate' element={<Climate />} />
        <Route exact path='/case-study' element={<Casestudy />} />
        <Route exact path='/earthoodies' element={<Team />} />
        <Route exact path='/privacy-policy' element={<Policy />} />
        <Route exact path='/Project' element={<Project />} />
        <Route exact path='/:slug' element={<Earthscoop1 />} />
        <Route exact path='/earthscoop2' element={<Earthscoop2 />} />
        <Route exact path='/earthscoop3' element={<Earthscoop3 />} />
        <Route exact path='/earthscoop4' element={<Earthscoop4 />} />
        <Route exact path='/earthscoop5' element={<Earthscoop5 />} />
        <Route exact path='/earthscoop6' element={<Earthscoop6 />} />
        <Route exact path='/earthscoop7' element={<Earthscoop7 />} />
        <Route exact path='/earthscoop8' element={<Earthscoop8 />} />
        <Route exact path='/earthscoop9' element={<Earthscoop9 />} />
        <Route exact path='/earthscoop10' element={<Earthscoop10 />} />
        <Route exact path='/earthscoop11' element={<Earthscoop11 />} />
        <Route exact path='/earthscoop12' element={<Earthscoop12 />} />
        <Route exact path='/earthscoop13' element={<Earthscoop13 />} />
        <Route exact path='/reference-to-statements' element={<Statements />} />
        <Route exact path='/live' element={<Webinar />} />
        <Route path='/*' element={<Error />} />
        

      </Routes>
      <Share/>
    </BrowserRouter>
  );
}

export default App;

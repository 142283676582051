import React from 'react'
import Layout from '../../components/layout';
import earthscoop5 from '../../assets/images/earthscoop/5.png';
import earthscoop2 from '../../assets/images/earthscoop/2.png'
import earthscoop3 from '../../assets/images/earthscoop/3.png'
import earthscoop6 from '../../assets/images/earthscoop/6.png'
import { Link } from 'react-router-dom';
const Earthscoop5=() => {
    return (
        <Layout>
            <div className='inner-earthscoop'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-8'>
                            <div class="content">
                                <img className="img-fluid" src={earthscoop5} alt="Earthood" id='' />
                                <div className='title'><h3><span>NEW CLIMATE DEAL & NO CLARITY ON CDM CONTINUITY</span></h3>
                                    <h6>-  BY: DR. KAVIRAJ SINGH ( M D )</h6>
                                </div>
                                <div className='pd-left'>
                                    <p>With hours to the deadline, Nigeria became the 144th country to ratify the Doha Amendment, ensuring it would come into force to tie the loose ends of the Kyoto Protocol era before it expires this year on 31st December.</p>
                                    <h5>The Backdrop</h5>
                                    <p>Over the past decade, the UN’s official carbon offset scheme faded into irrelevance, with some charge being surged through by domestic emissions trading schemes of few countries. Many buyers lost faith in the system and had insufficiently ambitious targets to drive demand. Now big businesses are falling over themselves to set net zero emissions targets, there is bound to be renewed interest in international carbon markets. So where are they at?</p>
                                    <p>Well, the Kyoto Protocol era Clean Development Mechanism’s mandate runs out at the end of 2020. Talks on its successor under the Paris Agreement have proved contentious – and the next chance to thrash out a deal is not until COP26 in November 2021.</p>
                                    <p>This decision, critical to the future of carbon trading, was supposed to be taken in a closed-door CDM EB meeting ending on Monday, but now it has been deferred to December. More updates from the EB Meeting are decoded later in this article.

                                    </p>
                                    <h5>The Ratification</h5>
                                    <p>Meanwhile, Jamaica sprinted to ratify the Doha Amendment before the deadline, with a nod to national hero Usain Bolt, but it was Nigeria that took it over the line. The amendment will now enter into force on the last day of the second commitment period of the Kyoto Protocol, marking the end of an era. UN Climate Change confirmed Nigeria had formally endorsed the climate treaty hours before a deadline that required 144 of the 192 signatories to ratify the deal for it to come into force on 31 December 2020 — a day before it is due to expire.

                                    </p>
                                    <p>The 1997 Kyoto Protocol set binding climate targets for developed countries. The amendment signed in Qatar in 2012 extended their obligations and created a second commitment period for 37 countries to cut their emissions from 2013 to 2020.</p>

                                    <p>It is a largely symbolic milestone, as the 37 developed countries bound by it have collectively exceeded the emissions cuts required. As symbols go, seeing through a climate promise made at the UN to its conclusion is a good one. Although the US signed the protocol, it never ratified it. Japan, Russia, and Canada refused to take part in the second commitment period.

                                        Under the Doha Amendment, countries were collectively required to cut emissions by at least 18% below 1990 levels by 2020. Recent data analysed by UN Climate Change shows the 37 developed countries had reduced emissions 25.3% by 2018. Countries could offset emissions by investing in carbon-cutting projects in poorer nations and most developed countries have fulfilled their commitments. UN Climate Change is expected to publish a formal review of countries’ carbon-cutting efforts during the period to 2020.</p>
                                    <h5>Key Takeaways from the EB Meeting</h5>
                                    <ul>
                                        <li>The CDM Executive Board (EB) could not take a firm stand on the continuity of CDM despite all build up pressor from its stakeholders in its latest meeting completed on 5th of October, 2020. The decision of several request for review on the Renewable of Crediting Period (RCP) and inclusion of new CPAs in a PoA post 2020, if these projects and CPA can continue beyond 2020, was scheduled for discussion. However, EB decided to postpone the decision and now the fate of these projects and also the future of CDM post 2020 will be discussed in the next meeting which is supposed to happen in December 2020.</li>
                                        <li> CDM EB clarified that all DOEs accredited by the CDM EB, to remain accredited till 31 Dec 2021 and all existing CDM requirements related to accreditation will continue until further guidance by CMP. It was also made clear that submission and processing of Request for Issuance (RFI), for emissions reduction achieved, before 31st December 2020 will continue in accordance with current CDM requirements. This implies that PPs and CMEs can make the RFI for the monitoring periods ending on 31st December, 2020 even in 2021.

                                        </li>
                                        <li> However, no clarity from the EB was provided whether Request for Registration (RFR), Inclusion of new CPAs and RCPs can be submitted and processed after 31st December 2020, but the decision has been postponed to the next EB meeting.</li>
                                        <li>Board agreed to continue the relaxation of the site visit requirements by the DOE until 31st December 2020 because of COVID-19 pandemic but with underlying conditions like the project must have the Emission Reduction Purchase Agreement (ERPA) with deadline or penalty clauses for failing the delivery of CERs at a particular time.</li>
                                        <li>Board has not extended the deadline for the overdue cases (PA/CPA) whose crediting period has expired by 30th September 2019 and extension of deadline was requested after 30th September 2020.</li>
                                        <li>Post registration changes with increase in capacity/addition of technology: deadline changed from 31st August 2020 to 31st December 2020.</li>
                                        <li>Items Pending for Discussions in the Future: a) Digitalization of methodology & web-based generation of PDD and MR and b) Revised CDM glossary of terms.</li>
                                        <li><b>New Methodologies and Revisions:</b>
                                            <ul><li>
                                                AM0121: Emission reduction from partial switching of raw material and increasing the share of additive in the production of blended cement
                                            </li>
                                                <li>AM0027: Substitution of CO2 from fossil or mineral origin by CO2 from renewable sources in the production of inorganic compounds</li>
                                                <li>ACM0022: Alternative waste treatment processes</li>
                                                <li> AMS-II.E: Energy efficiency and fuel switching measures for buildings</li>
                                                <li> AMS-III.AR: Substituting fossil fuel-based lighting with LED/CFL lighting systems

                                                </li>
                                                <li>CDM EB has provided some important clarifications, especially the one on monitoring survey and double counting, on the following methodologies;

                                                </li>
                                                <li>AMS-I.E.-SSC_780: Applicability of electric cooking projects in three different situations

                                                </li>
                                                <li>AMS-II.G. -SSC_781: Clarification on the validity and applicability of monitoring survey results under</li>
                                                <li>AMS-III.C -SSC_784: Clarification on market share and double counting</li>
                                                <li>AMS-I.C., AMS-I.E and AMS-I.I. -SSC_785: Clarification on applicability of AMS-I.C., AMS-I.E and AMS-I.I. to a fuel-switch project involving renewable biochar displacing non-renewable biomass in the context of household cooking

                                                </li>
                                                <li>AMS-II.G.-SSC_786: Clarification on monitoring of the number of utilization days for the project device</li>

                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='sticky-bar positionsticky'>
                                <div className='post-title-wrap'>
                                    <Link to=''>POPULAR POSTS</Link>
                                </div>
                                <div className='post-wrapper alignleft mrt-20'>
                                    <div className='post-img mrr-20'>
                                        <img className="img-fluid" src={earthscoop2} alt="Earthood" id='' />
                                    </div>
                                    <div className='post-content'>
                                        <div className='post-titile'>
                                            <h4><Link to='/earthscoop2'> Key Stakeholder’s Perspective On Esg</Link></h4>
                                        </div>
                                        <div className='post-author'>
                                            <h6>- AVINASH KUMAR (EXECUTIVE DIRECTOR – ESG & NET ZERO)</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className='post-wrapper alignleft mrt-20'>
                                    <div className='post-img mrr-20'>
                                        <img className="img-fluid" src={earthscoop3} alt="Earthood" id='' />
                                    </div>
                                    <div className='post-content'>
                                        <div className='post-titile'>
                                            <h4><Link to='/earthscoop3'>UNITED NATIONS’ BRIDGE FROM KYOTO TO PARIS</Link></h4>
                                        </div>
                                        <div className='post-author'>
                                            <h6>- BY: DR. KAVIRAJ SINGH ( M D )</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className='post-wrapper alignleft mrt-20'>
                                    <div className='post-img mrr-20'>
                                        <img className="img-fluid" src={earthscoop6} alt="Earthood" id='' />
                                    </div>
                                    <div className='post-content'>
                                        <div className='post-titile'>
                                            <h4><Link to='/earthscoop6'>THE WORLD’S DRIVE TOWARDS CARBON NEUTRALITY</Link></h4>
                                        </div>
                                        <div className='post-author'>
                                            <h6>- BY: ARCHIT SRIVASTAVA</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Earthscoop5;

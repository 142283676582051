import React from 'react'
import Layout from '../components/layout';
import Slider from 'react-slick';
import banner_logo from '../assets/images/climate/banner-logo.png'
import sec2_img from '../assets/images/climate/sec2-img.png'
import sec3_img from '../assets/images/climate/sec3-img.png'
import sec4_img from '../assets/images/climate/sec4-img.png'
import sec5_img from '../assets/images/climate/sec5-img.png'
import sec6_img from '../assets/images/climate/sec6-img.png'
import sec7_img from '../assets/images/climate/sec7-img.png'
import sec8_img from '../assets/images/climate/sec8-img.png'
import sec9_img from '../assets/images/climate/sec9-img.png'
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';

const Climate=() => {
    var settings={
        infinite: true,
        speed: 1000,
        autoplay: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoPlay: true,
        initialSlide: 0,
        responsive: [

            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            }

        ]
    };
    return (
        <Fragment>
            <Helmet>
                <title>Earthood</title>
                <meta name="keywords" content="Earthood" />
                <meta name="description" content="Secure carbon offset credits and achieve net zero goals with us" />
                <meta property="og:url" content="https://www.earthood.in/" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Earthood" />
                <meta property="og:description" content="Secure carbon offset credits and achieve net zero goals with us" />
                <meta property="og:image" content="https://webapi.earthood.in/assets/assets/media/logos/earthood-logo.png" />
            </Helmet>
            <Layout>
                <div className='sustainability climate'>
                    {/* banner */}
                    <div className='banner'>
                        <div className='container'>
                            <div className='banner-img'>
                                <div className='row'>
                                    <div className='col-lg-5'>
                                        <div className='img-wrapper'>
                                            <img src={banner_logo} alt='slider1' />
                                        </div>
                                    </div>
                                    <div className='col-lg-7 dv-center'>
                                        <div className='uw-line'>
                                            <h3><span>CLIMATE CHANGE</span> </h3>
                                            <p className='text-white'>Earthood was founded by a carbon market veteran Dr. Kaviraj Singh
                                                with his friend Mr. Ashok Gautam. Kavi and Ashok have been involved in
                                                some of the first CDM validations and verifications since 2006 and
                                                continue to conduct audits for some of the complex projects , because
                                                of their sheer passion towards the carbon offset audits.

                                            </p>
                                            <p className='text-white'>The auditors at Earthood are the most experienced in the field and
                                                have many success stories to share starting from doing a project in the
                                                shortest time to handling the first ever project on a particular
                                                methodology or program. Earthood offers Validations and Verifications
                                                of carbon offset credits across various program all over the world.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* banner end */}
                    {/* section 2 */}
                    <section className='sec2 pt-60'>
                        <div className='container'>
                            <div className='row mb-reverse'>
                                <div className='col-md-6'>
                                    <div className='img-wrapper'>
                                        <img className='w-lg' src={sec2_img} alt='slider1' />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='content-wrapper uline'>
                                        <h3><span>CLEAN DEVELOPMENT
                                            MECHANISM</span></h3>
                                        <p className='vline'>The Clean Development Mechanism was started by the United Nations, under
                                            which CDM executive board issues certified emission reduction (CER) for projects that
                                            are registered under CDM and verified by the DOE (Designated Operational Entity) under
                                            the rules of the Kyoto Protocol.</p>
                                        <p>Earthood is an accredited 'Designated Operational Entity' (DOE) under the CDM. Earthood
                                            has qualified professionals with an experience of over 15 years in the field of auditing
                                            of GHG projects under Clean Development Mechanism (CDM), Verified Carbon Standard (VCS),
                                            and Gold Standard (GS).</p>
                                    </div>

                                </div>
                            </div>
                            <hr className='hrline' />
                        </div>
                    </section>
                    {/* section 2 end */}
                    {/* section 3 */}
                    <section className='sec3'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className='content-wrapper rline'>
                                        <h3><span>VERIFIED CARBON STANDARD (VCS)
                                        </span></h3>
                                        <p className='vrline'> We specialize in validation and verification services for projects that
                                            voluntarily adopt measures to reduce emissions. Our primary goal is to simplify the validation and verification
                                            process for you while ensuring its effectiveness.</p>
                                        {/* <p className='pd-right'>Our team has undertaken numerous VCS projects all over the globe under varied sectoral
                                            scopes. We aim at making the process of validation and verification simpler for you yet
                                            effective.</p> */}
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='img-wrapper'>
                                        <img src={sec3_img} alt='slider1' />
                                    </div>
                                </div>
                            </div>
                            <hr className='hrline' />
                        </div>
                    </section>
                    {/* section 3 end */}
                    {/* section 4 */}

                    <section className='sec2'>
                        <div className='container'>
                            <div className='row mb-reverse' >
                                <div className='col-md-6'>
                                    <div className='img-wrapper'>
                                        <img src={sec4_img} alt='slider1' />
                                    </div>
                                </div>
                                <div className='col-md-6 brline'>
                                    <div className='content-wrapper uline'>
                                        <h3><span>GOLD STANDARD FOR GLOBAL GOALS (GS4GG)</span></h3>
                                        <p className='vline'>Our validation and verification services extend yet to other voluntary
                                            programs such as Gold Standard. The projects should be adopted on a voluntary basis and
                                            should contribute towards achieving the objectives of Kyoto protocol.</p>
                                        <p>With our expertise and experience, we deliver on time and demonstrate a high degree of competence.</p>
                                    </div>

                                </div>
                            </div>

                            <hr className='hrline' />
                        </div>
                    </section>
                    <section className='sec3'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className='content-wrapper rline pd-right'>
                                        <h3><span>GLOBAL CARBON COUNCIL</span></h3>
                                        <span className='vrline'>
                                            <p>The Government of Qatar has initiated a voluntary carbon
                                                mechanism, called the Global Carbon Council, which is also CORSIA compliant. This was in proper alignment with the
                                                aim to make FIFA World Cup 2022 (Qatar) as the World’s First Carbon Neutral
                                                Football World Cup. Earthood takes pride in being the first Validation &
                                                Verification Body to be accredited by GCC and also for making the first few
                                                submissions for Project Verifications ever done here.</p>
                                        </span>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='img-wrapper'>
                                        <img className='w-lg' src={sec5_img} alt='slider1' />
                                    </div>
                                </div>
                            </div>
                            <hr className='hrline' />
                        </div>
                    </section>
                    <section className='sec2'>
                        <div className='container'>
                            <div className='row  mb-reverse'>
                                <div className='col-md-6'>
                                    <div className='img-wrapper'>
                                        <img className='w-sm' src={sec6_img} alt='slider1' />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='content-wrapper uline'>
                                        <h3><span>CARBON INVENTORISATION</span></h3>
                                        <p className='vline'>Want to know your carbon footprint? We calculate it for you
                                            and let you know how carbon intensive are you. Whether it is a product, process or
                                            individual, we do it all. Increasing sensitivity of industry towards being green
                                            requires you to know the exact impact a product or a supply chain might have on
                                            the environment.</p>
                                        <p>An Earthood expert will also assist you in developing CO2 reduction goals and
                                            the corresponding action plans or projects that will help you achieve those goals.</p>
                                    </div>

                                </div>
                            </div>

                            <hr className='hrline' />
                        </div>
                    </section>
                    <section className='sec3'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className='content-wrapper rline pd-right'>
                                        <h3><span>THE CLIMATE COMMUNITY</span></h3>
                                        <span className='vrline'>
                                            <p>The Climate, Community & Biodiversity Alliance (CCBA) is a unique partnership of
                                                leading international NGOs founded in 2003 with a mission to stimulate
                                                and promote land management activities that credibly mitigate global climate
                                                change, improve the well-being and reduce the poverty of local communities,
                                                and conserve biodiversity.</p>
                                            <p>The CCBA brings together diverse stakeholders through a transparent and inclusive
                                                participatory process to develop standards and tools that stimulate, identify and
                                                promote high quality multiple-benefit land management activities. Earthood is
                                                accredited by the CCBA to participate in the program as a third-party auditor.
                                            </p>
                                        </span>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='img-wrapper'>
                                        <img src={sec7_img} alt='slider1' />
                                    </div>
                                </div>
                            </div>
                            <hr className='hrline' />
                        </div>
                    </section>
                    <section className='sec2'>
                        <div className='container'>
                            <div className='row  mb-reverse'>
                                <div className='col-md-6'>
                                    <div className='img-wrapper'>
                                        <img className='w-sm' src={sec8_img} alt='slider1' />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='content-wrapper uline'>
                                        <h3 className='pt-25'><span>SOCIAL CARBON
                                            STANDARD</span></h3>
                                        <p className='vline'>Social Carbon is a Standard developed by the Ecologica Institute
                                            that certifies emission reduction projects for their contributions to sustainable
                                            development. Earthood is approved verifier for the Social Carbon Standard.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <hr className='hrline' />
                        </div>
                    </section>
                    <section className='sec3'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className='content-wrapper rline pd-right'>
                                        <h3><span>GLOBAL EMISSIONS STANDARD</span></h3>
                                        <span className='vrline'>
                                            <p>The Global Emission Standard, a US-based voluntary mechanism was set up in 2020
                                                with a vision to cater to the ever growing markets and demands in the American
                                                region. With the United States of America signing the Paris Agreement under the
                                                leadership of POTUS – Joe Biden in early 2021, there has been a surge in the
                                                market activity from the Western part of the World. Earthood, having contributed
                                                to the development of standard, became the first Validation & Verification Body
                                                to be accredited with GES.</p>
                                        </span>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='img-wrapper'>
                                        <img src={sec9_img} alt='slider1' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* section 4 end*/}

                    {/* testimonial */}
                    <section className='testim'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-12'>
                                    <Slider {...settings}>
                                        <div>
                                            <div className='slider-wrapper'>
                                                <div className='text-center pb-20'>
                                                    <h5>MASTERS OF FIRST OF ITS KIND PROJECT!</h5>
                                                    <span className='autor'>- ISABELLE BOTTICELLI, COO - MOOTRAL </span>
                                                </div>
                                                <p className='pafter'>"Earthood was instrumental in the project’s progress, specially when it was a first of
                                                    it’s kind! We needed an auditor who could understand our idea and deliver on time. The audit team worked
                                                    seamlessly and their findings helped the project to take a better shape. Thank you Kavi for your value added
                                                    technical approach to make this one a success. Looking forward to more"</p>

                                            </div>
                                        </div>
                                        <div>
                                            <div className='slider-wrapper'>
                                                <div className='text-center pb-20'>
                                                    <h5>TOOK ALL THE PRESSURE OFF!</h5>
                                                    <span className='autor'>- JAMES BEAUMONT, CMD - DELAGUA GROUP</span>
                                                </div>
                                                <p className='pafter'>"Earthood changed our perception of auditors completely once we switched to them.
                                                    Seamless deliveries, constant support and fail-proof auditing.
                                                    "</p>

                                            </div>
                                        </div>
                                        {/* <div>
                                        <div className='slider-wrapper'>
                                            <div className='text-center pb-20'>
                                                <h5>MASTERS OF FIRST OF ITS KIND PROJECT!</h5>
                                                <span className='autor'>- ISABELLE BOTTICELLI, COO - MOOTRAL </span>
                                            </div>
                                            <p className='pafter'>"Earthood was instrumental in the project's progress,
                                                specially when it was a first of it's kind! we needed an
                                                auditor who could understand our idea and deliver on
                                                time. The audit team worked seamlessly and their
                                                findings helped the project to take a better shape.
                                                Thank you kavi for your value added technical
                                                approach to make this one a success. looking forward
                                                to more"</p>

                                        </div>
                                    </div> */}
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* testimonial end*/}
                </div>
            </Layout>
        </Fragment>

    )
}

export default Climate;

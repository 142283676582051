import React from 'react'
import Layout from '../../components/layout';
import earthscoop7 from '../../assets/images/earthscoop/7.png';
import earthscoop2 from '../../assets/images/earthscoop/2.png'
import earthscoop3 from '../../assets/images/earthscoop/3.png'
import earthscoop6 from '../../assets/images/earthscoop/6.png'
import { Link } from 'react-router-dom';
const Earthscoop7=() => {
    return (
        <Layout>
            <div className='inner-earthscoop'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-8'>
                            <div class="content">
                                <img className="img-fluid" src={earthscoop7} alt="Earthood" id='' />
                                <div className='title'><h3><span>LATEST CDM EB GUIDANCE FOR SITE VISIT IN COVIDISED</span></h3>
                                    <h6>- BY: DR. KAVIRAJ SINGH</h6>
                                </div>
                                <div className='pd-left'>
                                    <h5>Flexibility for not doing a site visit by DOE</h5>
                                    <p>“Latest CDM EB Guidance for site visit in COVIDised world & Decoding EB 106th meeting” Dr. Kaviraj Singh Flexibility for not doing a site visit by DOE The Executive Board (EB) of Clean Development Mechanism (CDM) released the latest guidance for the Designated Operational Entities (DOE) and Project Developers (PD) for conducting on-site visits for CDM Project Activities (PA) and Program of Activities (PoA).</p>
                                    <p>CDM EB on 24th June agreed to relax mandatory site visits by DOEs for an extended period of 24 June till 31 December 2020, due to the continuing travel restrictions on account of the COVID-19 pandemic. This is not an umbrella relaxation but comes with certain conditions, which are to be met by the PP and assessed by the DOE. This matter was considered electronically by the board members, and the following detailed guidance was released:</p>
                                    <ul>
                                        <li>A DOE may postpone site visits for onsite inspections required by the “CDM validation and verification standard for project activities (version 02.0) (VVS-PA)” or “CDM validation and verification standard for programme of activities (version 02.0) (VVS-PoA)”, as a result of the COVID-19 pandemic, taking into account the rules of relevant national and local authorities (local to the DOE offices as well as to locality of the site visits), World Health Organization (WHO) recommendations, policies of the DOE (if any) and other relevant travel restrictions and guidance (for example, a requirement to self-isolate upon return from specific countries)</li>
                                        <li> If the site visits cannot be postponed a proper justification should be provided by the DOE- why the site visits cannot be postponed, including the demonstration of a significant impact of delaying the site visits on the DOE, or project participants or coordinating/ managing entity (e.g. commitment/ timeline as per the validation or verification contract, CER delivery commitment by project participants) reliance on applicable force majeure provisions in the validation or verification contracts, if needed.</li>
                                        <li> If the site visit cannot be postponed, but are not conducted due to the COVID-19 pandemic, the DOE may use other standard auditing techniques for validation or verification, as referred to in sections 7.1.3 and 9.1.3 of the VVS-PA and sections 7.1.3 and 10.1.3 of the VVS-PoA. In the above regard, the Board agrees to, from 24 June to 31 December 2020, to deviate from the requirements in paragraphs 30 and 339 of the VVS-PA and paragraphs 183 and 321 of the VVS-PoA. Where the DOE relies on this temporary measure, it shall describe in the validation/ verification report the alternative means used and justify that they are credible and sufficient for the purpose of validation or verification.</li>

                                    </ul>
                                    <p>CDM EB had initially relaxed the mandatory site visit, due to the travel restrictions due to COVID-19 pandemic, in March 2020 for a duration of 3 months starting from 23 March 2020 going up till 23rd June 2020. However, the situation of virus spread has further worsened since then at the global level and therefore, the relaxation has been further extended till 31 December 2020. The provided flexibility is a welcome move for the all the stakeholders of CDM, and will help them to continue their contribution, in terms of CDM projects, towards fighting climate change.</p>
                                    <p>CDM EB had this flexibility in the existing procedures even before the outbreak of novel coronavirus but this wasn’t available to all kind of CDM PAs and PoAs, but can only be executed by DOE in specific cases.</p>
                                    <p>The CDM EB Validation and Verification (VVS) Manual for project activity Para 30, and VVS for PoA para 183 provide the flexibility to a DOE for applying alternative means of site visit, and not doing it physically while conducting the validation if;

                                    </p>
                                    <p><b>Bosch</b> has been one of the most aggressive players when it comes to carbon neutral business. The company aims for 100% neutrality this year itself! Bosch is making their 400+ locations carbon neutral in 2020. Going forward, they want to shape climate action and, also take a close look at the carbon footprint of the products, purchased goods, and logistics processes.

                                    </p>
                                    <ul>
                                        <li>Its estimated annual average of greenhouse gas (GHG) emission reductions or net anthropogenic GHG removals is less than 100,000 tCO2e eq: or</li>
                                        <li>More than three years have elapsed since the last on-site inspection conducted by DOE</li>
                                        <li>The PA/CPA has achieved more than 300,000t CO2 eq of GHG emission reductions since the last verification when an on-site inspection was conducted.</li>
                                    </ul>
                                    <p>Despite the above existing flexibilities, not many PAs/PoAs had opted for the alternative means of site visit options until recently and neither DOEs nor PPs seem comfortable opting for it. Expecting more stringent scrutiny for such projects at UN level couldn’t have been the reason for not availing this flexibility in past for the qualifying cases. Outbreak of Coronavirus leaves no choice but opting for a remote site visit by the DOE and modify their existing system and procedures to the new normal of CDM audits.
                                    </p>
                                    <h5>Summary of the Minutes of EB 106th Meeting</h5>
                                    <p>This was the first virtual EB meeting in the history of CDM which took place on 11th to 13th May, and 27th to 29th May and 12th June 2020, and all members participated electronically in decision making. The following are the key decisions taken in this meeting;</p>
                                    <h5>a) Deviations from monitoring of PA/PoA because of COVID-19</h5>
                                    <p>The board in its 106thmeeting also provided the much awaited flexibility to the PPs/CMEs for applying a deviation in the event of no monitoring of their PA/PoA because of COVID-19 pandemic. While doing so PP/CME should specify the deviation period, provide alternative measures for estimating the emissions and demonstrate that with the alternative measures the estimated emissions are not overestimated. Such information to be provided in the monitoring report and this request can be submitted along with request for issuance by the verifying DOE. The CDM EB existing procedures for deviation request to be referred are; “CDM project standard for project activities” (PS-PA/PoA) and the “CDM project cycle procedure for project activities" (PCP-PA/PoA) are to be referred while making such a submission to<b>UN.</b>
                                    </p>
                                    <h5>b) New stringent rule for the performance monitoring of DOEs</h5>
                                    <p>A new tool “Procedure: Performance monitoring of designated operational entities” for the performance monitoring of the UN accredited DOEs, has also been introduced in this EB meeting. The performance of DOEs in all submissions made by them viz. request for registration, issuances, post registration changes and renewal in crediting period etc. will be monitored and every rejection by UN will be given a predefined weightage to the submitting DOE. The performance of all DOEs will be summarized at the end of monitoring period and be made public. The tool has been implemented retroactively from 01stof May and first monitoring period will end on 31st August 2020. A three colour coding; green, yellow and red will be given to the monitored DOE. The tool will put DOEs under pressure to improve their quality and aim no rejection from UN on their submissions.</p>
                                    <h5>c) General matters discussed in meeting</h5>
                                    <ul>
                                        <li>The board elected El Hadji Mbaye Diagne and Olivier Kassi as Chair and Vice-Chair of the Board, respectively, until the first meeting of the Board in 2021. Amjad Abdulla and Piotr Dombrowicki, to serve as the chair and vice-chair, respectively, of the CDM-AP. Frank Wolke and José Domingos Gonzalez Miguez, to serve as the chair and vice-chair, respectively, of the MP.
                                        </li>
                                        <li>CDM EB has declared that 7830 CDM PA and 332 PoA have been registered until 12thJune 2020. The 332 PoAs have 2621 of CPAs included so far.</li>
                                        <li>2,033,134,853 and 27,545,993 have been issued from the PA and PoA, respectively until 12 thJune 2020.
                                        </li>
                                        <li>The board requested secretariat to improve the tool "TOOL30: Calculation of the fraction of non-renewable biomass" and also requested them to revise he definitions of “Biomass, Biomass Residue and Renewable Biomass” in CDM Glossary of terms. This tool and definitions are mainly used for cookstoves based PA and PoAs and the existing definitions can create some gaps in interpretations to the stakeholders.</li>
                                        <li>The meth panel was also advised to come up with more clarity on the regulatory provisions related to the change/or addition of technology/measures to a PA/PoA.</li>
                                    </ul>
                                    <h5>d) Clarification on Methodologies:</h5>
                                    <p>Few new clarifications on the frequently used methodologies and tools have also been made public like;</p>
                                    <ul>
                                        <li>AMS IE: SSC_766: Calculation of By savings using equation (5) of AMS-I.E. and determine the proportion of woody biomass fuel use through the number of meals in the baseline survey.</li>
                                        <li>AMS-I.E: SSC_773: Inclusion of solar electric cookers as a new technology/measure in a PoA</li>
                                        <li>AMS-I.C.: SSC_769: Bio-fuel in scope of Methodology AMS-I.C.</li>
                                        <li>AMS-II.G.: SSC_770: Clarification on non-renewable biomass under AMS-II.G.</li>
                                        <li>AMS-III.AV “SSC_774: Clarification on the determination of the total quantity of purified water using sales receipts for water kiosks</li>
                                    </ul>
                                    <h5>e) Clarification on Tools</h5>
                                    <ul>
                                        <li>CLA_TOOL_0033: Clarification on the calculation of fNRB value when the geographical boundary of a PoA is the whole country”, regarding “TOOL30: Calculation of the fraction of non-renewable biomass” (version 02.0).

                                        </li>
                                        <li>CLA_TOOL_0034: Clarification on the use of fNRB value developed by a third party and confirmed by the DNA”, regarding “TOOL30: Calculation of the fraction of non-renewable biomass” (version 02.0).</li>
                                        <li>“CLA_TOOL_0035: Clarification on the determination of total household wood fuel consumption through official statistics”, regarding “TOOL30: Calculation of the fraction of non-renewable biomass” (version 02.0).</li>
                                        <li>  “CLA_TOOL_0036: Clarification on the method to calculate Mean Annual Increment of forest areas and other wooded land areas”, regarding “TOOL30: Calculation of the fraction of non-renewable biomass” (version 02.0).</li>
                                        <li> “CLA_TOOL_0037: Clarification on the use of MAI values from national reports”, regarding “TOOL30: Calculation of the fraction of non-renewable biomass” (version 02.0).</li>
                                    </ul>
                                    <p>For more detailed information and expert opinion about the decision made in the EB meeting please contact team ESPL at <a href='mailto:info@earthood.in'>info@earthood.in</a></p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='sticky-bar positionsticky'>
                                <div className='post-title-wrap'>
                                    <Link to=''>POPULAR POSTS</Link>
                                </div>
                                <div className='post-wrapper alignleft mrt-20'>
                                    <div className='post-img mrr-20'>
                                        <img className="img-fluid" src={earthscoop2} alt="Earthood" id='' />
                                    </div>
                                    <div className='post-content'>
                                        <div className='post-titile'>
                                            <h4><Link to='/earthscoop2'> Key Stakeholder’s Perspective On Esg</Link></h4>
                                        </div>
                                        <div className='post-author'>
                                            <h6>- AVINASH KUMAR (EXECUTIVE DIRECTOR – ESG & NET ZERO)</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className='post-wrapper alignleft mrt-20'>
                                    <div className='post-img mrr-20'>
                                        <img className="img-fluid" src={earthscoop3} alt="Earthood" id='' />
                                    </div>
                                    <div className='post-content'>
                                        <div className='post-titile'>
                                            <h4><Link to='/earthscoop3'>UNITED NATIONS’ BRIDGE FROM KYOTO TO PARIS</Link></h4>
                                        </div>
                                        <div className='post-author'>
                                            <h6>- BY: DR. KAVIRAJ SINGH ( M D )</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className='post-wrapper alignleft mrt-20'>
                                    <div className='post-img mrr-20'>
                                        <img className="img-fluid" src={earthscoop6} alt="Earthood" id='' />
                                    </div>
                                    <div className='post-content'>
                                        <div className='post-titile'>
                                            <h4><Link to='/earthscoop6'>THE WORLD’S DRIVE TOWARDS CARBON NEUTRALITY</Link></h4>
                                        </div>
                                        <div className='post-author'>
                                            <h6>- BY: ARCHIT SRIVASTAVA</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Earthscoop7;

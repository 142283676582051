import React from 'react'
import { GrPrevious, GrNext } from 'react-icons/gr';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import slider1 from '../assets/images/projects/slider1.png'
import slider2 from '../assets/images/projects/slider2.png'
import slider3 from '../assets/images/projects/slider3.png'
import slider4 from '../assets/images/projects/slider4.png'
import slider5 from '../assets/images/projects/slider5.png'
import slider6 from '../assets/images/projects/slider6.png'
import slider7 from '../assets/images/projects/slider7.png'
import slider8 from '../assets/images/projects/slider8.png'
import slider9 from '../assets/images/projects/slider9.png'
import { useEffect } from 'react';
import { getIconicProjectList } from '../Redux/Project/action';
import { useState } from 'react';


const Projects = () => {
    const [projectList, setProjectList] = useState([])

    function SampleNextArrow(props) {
        const { onClick } = props;
        return (
            <div style={{ color: "green" }} onClick={onClick}><GrNext className='next_icon' /> </div>
        );
    }

    function SamplePrevArrow(props) {
        const { onClick } = props;
        return (
            <div style={{ color: "green" }} onClick={onClick}><GrPrevious className='prev_icon' /> </div>
        );
    }

    var settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 2,
        autoplay: true,
        autoplaySpeed: 5000,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    useEffect(() => {
        getIconicProjectList()((response) => {
            if (response && response.status) {
                setProjectList(response?.data)
            }
        });
    }, [])
    return (
        <div className='services projects' id='projects'>
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='pro-title'>
                            <h3>Iconic Projects
                            </h3>
                            {/* <a href=''><button className='project-btn'>View All Projects</button></a> */}
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='service-slider'>
                        <Slider {...settings}>
                            {projectList && projectList.map((item) =>
                                <div>
                                    <div className='slider-wrapper'>
                                        <div className='img-wrapper'>
                                            <img src={item.image} alt='slider1' />
                                        </div>
                                        <div className='m-height'>
                                            <h4>{item?.title}</h4>
                                            <p>{item?.short_description}</p>
                                        </div>
                                        <Link target='_blank' to={item.url}><button className='learn-btn'>Learn More</button></Link>
                                    </div>
                                </div>
                            )}
                            {/* <div>
                                <div className='slider-wrapper'>
                                    <div className='img-wrapper'>
                                        <img src={slider3} alt='slider1' />
                                    </div>
                                    <div className='m-height'>
                                        <h4>Great British Sustainable
                                            Farming Project</h4>
                                        <p>The project is under validation applying VM0042 methodology</p>
                                    </div>
                                    <Link target='_blank' to='https://registry.verra.org/app/projectDetail/VCS/2640'><button className='learn-btn'>Learn More</button></Link>
                                </div>
                            </div>
                            <div>
                                <div className='slider-wrapper'>
                                    <div className='img-wrapper'>
                                        <img src={slider2} alt='slider1' />
                                    </div>
                                    <div className='m-height'>
                                        <h4>One of the biggest POAs</h4>
                                        <p>We are proud to be the Verifier of one of the worlds biggest CDM PoA ever get verified.</p>
                                    </div>
                                    <Link target='_blank' to='https://cdm.unfccc.int/ProgrammeOfActivities/poa_db/B46TH0V2GLIZK1UPWJ3SMNA8QRX7FY/view?cp=1'><button className='learn-btn'>Learn More</button></Link>
                                </div>
                            </div>

                            <div>
                                <div className='slider-wrapper'>
                                    <div className='img-wrapper'>
                                        <img src={slider4} alt='slider1' />
                                    </div>
                                    <div className='m-height'>
                                        <h4>First GCC project ever got registered</h4>
                                        <p>Earthood was the auditor for the first two project registered with GCC.</p>
                                    </div>
                                    <Link target='_blank' to='https://projects.globalcarboncouncil.com/pages/approved_projects'><button className='learn-btn'>Learn More</button></Link>
                                </div>
                            </div>
                            <div>
                                <div className='slider-wrapper'>
                                    <div className='img-wrapper'>
                                        <img src={slider5} alt='slider1' />
                                    </div>
                                    <div className='m-height'>
                                        <h4>Housing decarbonization in England and Wales</h4>
                                        <p>We are validating the first decarbonization carbon offset projects in housing sector in England and Wales</p>
                                    </div>
                                    <Link target='_blank' to='https://registry.verra.org/app/projectDetail/VCS/2649v'><button className='learn-btn'>Learn More</button></Link>
                                </div>
                            </div>
                            <div>
                                <div className='slider-wrapper'>
                                    <div className='img-wrapper'>
                                        <img src={slider6} alt='slider1' />
                                    </div>
                                    <div className='m-height'>
                                        <h4>130090 Hectares of VCS forestry project</h4>
                                        <p>One of the biggest forestry project was registered by Earthood in Indonesia</p>
                                    </div>
                                    <Link target='_blank' to='https://registry.verra.org/app/projectDetail/VCS/2403'><button className='learn-btn'>Learn More</button></Link>
                                </div>
                            </div>
                            <div>
                                <div className='slider-wrapper'>
                                    <div className='img-wrapper'>
                                        <img src={slider7} alt='slider1' />
                                    </div>
                                    <div className='m-height'>
                                        <h4>SHINE – Distribution of LED Lightbulbs in India</h4>
                                        <p>PAN India LED distribution of project was verifier by Earthood</p>
                                    </div>
                                    <Link target='_blank' to='https://cdm.unfccc.int/ProgrammeOfActivities/poa_db/MN26BFZ0CHPIR47GQ1L3YES9XVKTUD/view'><button className='learn-btn'>Learn More</button></Link>
                                </div>
                            </div>
                            <div>
                                <div className='slider-wrapper'>
                                    <div className='img-wrapper'>
                                        <img src={slider8} alt='slider1' />
                                    </div>
                                    <div className='m-height'>
                                        <h4>DelAgua Public Health Program in Eastern Africa</h4>
                                        <p>One of the biggest cookstoves PoA</p>
                                    </div>
                                    <Link target='_blank' to='https://cdm.unfccc.int/ProgrammeOfActivities/poa_db/RVUJL2DMZ7HBKC1PEG4O8NA0WSI6TX/view'><button className='learn-btn'>Learn More</button></Link>
                                </div>
                            </div>
                            <div>
                                <div className='slider-wrapper'>
                                    <div className='img-wrapper'>
                                        <img src={slider9} alt='slider1' />
                                    </div>
                                    <div className='m-height'>
                                        <h4>Canada’s First Energy Efficiency Project</h4>
                                        <p>First project in Canada for energy efficiency in commercial buildings applying VM0018 was verifier by Earthood</p>
                                    </div>
                                    <Link target='_blank' to='https://registry.verra.org/app/projectDetail/VCS/929'><button className='learn-btn'>Learn More</button></Link>
                                </div>
                            </div> */}


                        </Slider>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Projects;

import { EARTHSCOPE_LIST, EARTHSCOPE_SINGLE } from "../../api";
import axiosInstance from "../../axiosInstance";

export const getEarthScopeList=(props) => onResponse => {
    try {
        axiosInstance.get(EARTHSCOPE_LIST)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};

export const getEarthScopeSingle=(slug) => onResponse => {
    try {
        axiosInstance.get(EARTHSCOPE_SINGLE+'?slug='+slug)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};

import React from 'react'
import Layout from './layout'

const Statements=() => {
    return (
        <>
            <Layout>
                <section className='statement-page sustainability'>
                    <div className='banner'>
                        <div className='container'>
                            <div className='row'>
                             
                                <div className='col-12'>
                                    <div className='uw-line text-center' >
                                        <h3><span>Reference to Statements</span> </h3>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className='main-content'>
                        <div className='container'>
                            <div className='list'>
                                <div className='row'>
                                    {/* <h1>Reference to Statements/ Use of marks</h1> */}
                                    <p>Validation and verification of environmental information statements can result in responsible parties making reference to the findings, conclusions, reports and opinions expressed by Earthood. Earthood has established these rules for governing references made by responsible parties to validation/verification statements and for governing the use of marks.</p>
                                    <ol>
                                        <li>Reference to validated/ verified statements:
                                            <p>Earthood allows references to its validated or verified information statements only when opinion provided by Earthood on any validation/ verification be used in its entirety. The responsible body shall ensure that any opinions or reports of factual findings by Earthood made public by the responsible parties are communicated in their entirety.</p>
                                        </li>
                                        <li>Use of marks: 
                                            <p>The responsible party is prohibited to use Earthood’s logo/ mark to imply that statements not subject to validation or verification have been validated or verified. It is not permitted to use Earthood’s mark on environmental information statements which contain information that has not been validated or verified by Earthood.</p>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
            </Layout>
        </>
    )
}

export default Statements

import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';

import { Link } from 'react-router-dom';
import guru from '../assets/images/webinar/avinashKumar.png'
import dan from '../assets/images/webinar/narendra.png'
import Layout from '../components/layout';
import { getWebinarData } from '../Redux/Webinar/action';
import { getDateTime } from '../api/functions';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';


const Webinar=() => {
    const [days, setDays]=useState(0);
    const [webinarData, setWebinarData]=useState({});
    const [hours, setHours]=useState(0);
    const [minutes, setMinutes]=useState(0);
    const [seconds, setSeconds]=useState(0);

    const deadline=new Date('2024-07-04T09:30:00Z');

    const getTime=() => {
        const now=new Date();
        const time=deadline-now;

        if (time>0) {
            setDays(Math.floor(time/(1000*60*60*24)));
            setHours(Math.floor((time/(1000*60*60))%24));
            setMinutes(Math.floor((time/(1000*60))%60));
            setSeconds(Math.floor((time/1000)%60));
        } else {
            // Time's up
            setDays(0);
            setHours(0);
            setMinutes(0);
            setSeconds(0);
        }
    };

    useEffect(() => {
        // Fetch webinar data when component mounts
        getWebinarData()((response) => {
            if (response&&response.status) {
                setWebinarData(response?.data?.results[0]);
            }
        });

        // Initialize the timer and update every second
        const interval=setInterval(getTime, 1000);
        // Cleanup the interval on component unmount
        return () => clearInterval(interval);
    }, []);
    console.log(webinarData);
    return (
        <Fragment>
            <Helmet>
                <title>Earthood</title>
                <meta name="keywords" content="Earthood" />
                <meta name="description" content="Secure carbon offset credits and achieve net zero goals with us" />
                <meta property="og:url" content="https://www.earthood.in/" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Earthood" />
                <meta property="og:description" content="Secure carbon offset credits and achieve net zero goals with us" />
                <meta property="og:image" content="https://webapi.earthood.in/assets/assets/media/logos/earthood-logo.png" />
            </Helmet>
            <Layout>
                <section className='webinar'>
                    <div style={{ backgroundImage: `url(${webinarData?.banner_image})` }} className='banner'>
                    <div className='overlay'></div>
                        <div className='container'>
                        <div className='webContent'>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <h1>Future-Proof Fashion: Decarbonization and Regulatory Insights</h1>
                                    <ul>
                                        {/* <li className='date'>22<sup>nd</sup> March 2023, Wednesday</li> */}
                                        <li className='date'>04th July 2024, Thursday</li>

                                        <li className='time'>3:00 PM IST</li>
                                    </ul>
                                    <div className='btn-wrap'>
                                        <Link  to="https://us06web.zoom.us/meeting/register/tZMofuuqqDMoHdxkopIL8_iYj1I9ny5y6NWN#/registration" className='banner-btn'>Reserve My Seat</Link>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className='summary'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-12 text-center'>
                                    <h2>Webinar Summary</h2>
                                    <p>Master Class for Fashion Industry: Discover Million-Dollar
                                        Opportunities on the Net Zero Pathway.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='discover'>
                        <div className='container d-center'>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <h5>Why attend?</h5>
                                    {/* <h2>YOU WOULD DISCOVER</h2> */}
                                    <ul>
                                        {/* {webinarData?.discover_points&&webinarData?.discover_points?.map((item) =>
                                            <><li>{item.discover_points}</li></>
                                        )} */}
                                        <li>Understand the Climate Change Challenges faced by Fashion and
                                            Textile Industry</li>
                                        <li>Learn Current regulations and Buyer expectation</li>
                                        <li>Impact of ignoring decarbonization on Fashion Industry</li>
                                        <li>Indepth Analysis of Decarbonizing Fashion</li>
                                    </ul>
                                </div>
                                <div className='col-lg-6'>
                                    <h5>Who should attend?</h5>
                                    <ul>

                                        <><li>CEOs, COOs, and executives from Textile companies, Apparel
                                            Industry, Leading Fashion Brands</li></>
                                        <li>Supply chain managers, logistics professionals, and procurement
                                            officers responsible for managing Fashion and Textile supply chains</li>

                                        <li>Environmental managers, sustainability officers, and professionals
                                            responsible for monitoring and managing carbon footprints,
                                            emissions reporting, and sustainability initiatives</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='hrline d-none d-md-block'>
                        <div className='container text-center' >
                            <div className='line'><span className='plus'>+</span></div>
                        </div>
                    </div>
                    <div className='bonus'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-12'>
                                    <h3>Exclusive Bonus for Attendees</h3>
                                    <p>{webinarData?.bonus_description}</p>
                                </div>
                            </div>
                            <div className='timer-sec'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <p>Masterclass is happening on:<span> 04th July 2024, Thursday 3:00 PM IST</span></p>
                                    </div>
                                </div>
                                <div className='counter'>
                                    <div className='row grid-4 w-70'>

                                        <p><span> {days}</span>DAYS</p>


                                        <p><span>{hours}</span>HRS</p>


                                        <p><span>{minutes} </span>MIN</p>

                                        <p><span>{seconds}</span>SEC</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='meet-guru'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-8'>
                                    <div className='title'>
                                        <p>Meet the Speakers:</p>
                                        <h2>{webinarData?.speaker_name}</h2>
                                        <ul>
                                            {webinarData?.qualification_points&&webinarData?.qualification_points.map((item) =>
                                                <>
                                                    <li>{item.qualifications_points}</li>
                                                </>
                                            )}

                                        </ul>
                                    </div>
                                </div>
                                <div className='col-lg-4'>
                                    <div className='img-wrapper'>
                                        <img src={guru} alt='meet-guru' className='img-fluid' />
                                    </div>
                                </div>
                            </div>
                            <div className='row' style={{ paddingTop: "80px" }}>
                                <div className='col-lg-4 d-none d-lg-block'>
                                    <div className='img-wrapper'>
                                        <img src={dan} alt='meet-guru' className='img-fluid' />
                                    </div>
                                </div>
                                <div className='col-lg-8'>
                                    <div className='title'>
                                        <h2>Mr. Narendra Makwana</h2>
                                        <h5 className='subtext'>CEO &amp; Co-Founder, Greenstitch.io</h5>
                                        <h5 className='subtext pb-5p'>Visiting Faculty, Sustainable Fashion, IIT Delhi</h5>
                                        <ul>

                                            <>
                                                <li>Narendra Makwana is an entrepreneur who specialises in sustainability
                                                    in textiles. He holds a Bachelor&#39;s degree in Textile Technology and Fiber
                                                    Science from the prestigious IIT Delhi and is certified by the World Bank
                                                    Group in climate science.</li>
                                                <li>He is the founder of GreenStitch Technologies Private Limited, a
                                                    company that assists textile and fashion companies in calculating carbon

                                                    emissions throughout their operations and supply chains, while also
                                                    providing insights to achieve net-zero emissions.</li>
                                                <li>Prior to founding his own company, He worked in governance and
                                                    policy, advising prominent legislators and bureaucrats in India on climate
                                                    change, sustainable development goals, and agriculture.</li>
                                                <li>In addition, He teaches carbon accounting and Life Cycle Assessments
                                                    (LCAs) in textiles at IIT Delhi.</li>
                                            </>


                                        </ul>
                                    </div>
                                </div>
                                <div className='col-lg-4 d-lg-none'>
                                    <div className='img-wrapper'>
                                        <img src={dan} alt='meet-guru' className='img-fluid' />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 text-center'>
                                    <div className='btn-wrap'>
                                        <Link   to="https://us06web.zoom.us/meeting/register/tZMofuuqqDMoHdxkopIL8_iYj1I9ny5y6NWN#/registration" className='banner-btn'>Reserve My Seat</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        </Fragment>

    )
}

export default Webinar;
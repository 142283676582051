import React, { useEffect } from 'react'
import Header from '../components/header/header';
import Banner from '../components/banner';
import Story from '../components/story';
import Milestone from '../components/milestone';
import Services from '../components/services';
import Projects from '../components/projects';
import Earthoodies from '../components/earthoodies';
import Testimonial from '../components/testimonial';
import Clients from '../components/clients';
import Footer from '../components/header/footer';
import Earthscoop from '../components/earthscoop';
import Accreditations from '../components/accreditations';
import { useLocation } from 'react-router-dom';
import NewAccreditation from '../components/newAccreditation';


const Index=() => {


    return (
        <div>
            <Header />
            <Banner />
            <Story />
            <Milestone />
            <NewAccreditation />
            <Accreditations />
            <Services />
            <Projects />
            <Earthoodies />
            <Testimonial />
            <Earthscoop />
            <Clients />
            <Footer />

        </div>
    )
}

export default Index;

import { COUNTRY_LIST, ICONIC_PROJECT_LIST, METHODOLOGY_LIST, PROGRAMME_LIST, PROJECT_LIST, REGION_LIST, SECTOR_LIST, SERVICE_TYPE_LIST } from "../../api";
import axiosInstance from "../../axiosInstance";

export const getProjectList = () => onResponse => {
    // '?region=' + region + '&' + country + '&' + methodology + '&' + service_type + '&' + programme + '&' + sector
    try {
        axiosInstance.get(PROJECT_LIST)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};

export const getIconicProjectList = () => onResponse => {
    try {
        axiosInstance.get(ICONIC_PROJECT_LIST)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};

export const getRegionList = () => onResponse => {
    try {
        axiosInstance.get(REGION_LIST)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};

export const getMethodologyList = () => onResponse => {
    try {
        axiosInstance.get(METHODOLOGY_LIST)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};

export const getProgramList = () => onResponse => {
    try {
        axiosInstance.get(PROGRAMME_LIST)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};

export const getServiceTypeList = () => onResponse => {
    try {
        axiosInstance.get(SERVICE_TYPE_LIST)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};

export const getSectorList = () => onResponse => {
    try {
        axiosInstance.get(SECTOR_LIST)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};

export const getCountryList = () => onResponse => {
    try {
        axiosInstance.get(COUNTRY_LIST)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};

import React, { Fragment } from 'react'
import Layout from '../../components/layout';
import earthscoop1 from '../../assets/images/earthscoop/1.png'
import earthscoop2 from '../../assets/images/earthscoop/2.png'
import earthscoop3 from '../../assets/images/earthscoop/3.png'
import earthscoop6 from '../../assets/images/earthscoop/6.png'
import { Link, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { getEarthScopeList, getEarthScopeSingle } from '../../Redux/Earthscope/action';
import { useState } from 'react';
import '../../assets/css/custom.css'
import { Helmet } from 'react-helmet';
import Error from '../../components/404 Page/error';





const Earthscoop1=() => {
    let { slug }=useParams();
    const [singleData, setSigleData]=useState({})
    const [slugExist, setSlugExist]=useState(null)
    const [earthScoopList, setEarthScoopList]=useState([])

    useEffect(() => {
        if (slug) {
            getEarthScopeSingle(slug)((response) => {
                if (response&&response.status) {
                    setSigleData(response?.data?.product)
                    setSlugExist(true)
                } else {
                    setSlugExist(false)

                }
            });
        }
        getEarthScopeList()((response) => {
            if (response&&response.status) {
                setEarthScoopList(response?.data)
            }
        });
    }, [slug])

    console.log(':>>>>>>>>>>>>>>> earthScoopList.meta_title', singleData.og_image, slugExist);
    if (slugExist||slugExist==null) {
        return (
            <Fragment>
                {console.log(singleData.og_image?.replace("http://", "https://"))}
                <Helmet>
                    <title>{singleData.meta_title&&singleData.meta_title}</title>
                    <meta name="keywords" content={singleData?.meta_keywords&&singleData?.meta_keywords} />
                    <meta name="description" content={singleData?.meta_description&&singleData?.meta_description} />
                    <meta property="og:url" content="https://www.earthood.in/" />
                    <meta property="og:site_name" content="Earthoods" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content={singleData.meta_title&&singleData.meta_title} />
                    <meta property="og:description" content={singleData?.meta_description&&singleData?.meta_description} />
                    <meta property="og:image" content={singleData?.og_image??""} />
                    <meta property="og:see_also" content="https://www.earthood.in" />
                    <meta property="og:image:width" content="1200" />
                    <meta property="og:image:height" content="630" />
                </Helmet>
                <Layout>
                    <div className='inner-earthscoop'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-8'>
                                    <div className="content">
                                        {/* <img className="img-fluid" src={singleData?.image} alt="Earthood" id='' />
                                    <div className='title'><h3><span>THE COST OF CLIMATE CHANGE WILL CHALLENGE COUNTRIES AND COMPANIES HARD IN 2023</span></h3>
                                        <h6>- BY: AVINASH KUMAR</h6>
                                    </div> */}


                                        {singleData?.detail&&(
                                            <div className='pd-left' dangerouslySetInnerHTML={{ __html: singleData.detail }} />
                                        )}
                                        {/* <div className='pd-left'>
                                        <p>2022 was a year marked by yet more climate-linked floods, hurricanes, and droughts
                                            and due to this, Governments and Companies were forced to look more closely at the
                                            financial risks associated with climate change and related liability exposure. Nowhere
                                            was this more apparent than at the U.N. climate conference – COP 27 in Egypt, where
                                            countries reached a landmark agreement to set up a fund to help poor countries cope
                                            with climate-fueled disaster costs, The Loss And Damage Fund, as we know it. This was
                                            in demand for over a decade.
                                        </p>
    
                                        <p>What agreed was an explicit example of slow progress in tackling climate change which
                                            finally made vulnerable countries determined to get the Loss and Damage Fund approved
                                            this time – after yet another year of extreme weather disasters including record heatwaves
                                            from the United States to China, glaciers collapsing in India and Europe, and unending
                                            drought pushing millions toward famine in East Africa. According to risk modelling firm
                                            RMS, 2022 year delivered three of the decade's costliest disasters - "dystopian" flooding
                                            that delivered $40 billion in damages to Pakistan, a series of deadly summer heatwaves
                                            that collectively caused more than $10 billion in losses for Europe, and Hurricane Ian
                                            tearing across Florida and South Carolina to the cost of $100 billion.</p>
                                        <h5>WHY COST MATTERS</h5>
                                        <p>As watchdog groups called out companies for failing to disclose how climate change might threaten them financially, investors faced increasing pressure both for going too far to address climate risks and for not going far enough. Moving one step further from risk anticipation by investors to climate-related litigation, as of today, there are 2,176 climate-related lawsuits in play across the world, including 654 filed in U.S. courtrooms, according to the Sabin Center for Climate Change Law at Columbia University.</p>
                                        <p>Substantial cost is associated with climate change if no action is taken to become climate resilient and this can be minimized by early and proactive actions in calculating exactly how much a country's activity may have contributed to climate change – and to specific disasters.</p>
                                        <h5>WHAT DOES IT MEAN FOR 2023?</h5>
                                        <p>With the new year, more public anxiety is expected as climate change continues to escalate – and companies and governments are worrying more than ever about their liabilities and risks. Companies and investors will face pressure to climate-proof their supply chains and operations.</p>
                                        <p>Courtrooms will see more climate cases filed, focused both on challenging national governments to increase their climate policy ambitions and on holding corporations accountable for their emissions or deceptive practices.</p>
                                        <p>At the end of the year, when countries meet again at the next U.N. climate summit, COP28, in Dubai, they will be under extra pressure to see that emissions are cut in half by 2030 and to net zero by 2050 - the only path to holding global warming to within 1.5 degrees Celsius.</p>
                                    </div> */}
                                    </div>
                                </div>
                                <div className='col-lg-4'>
                                    <div className='sticky-bar positionsticky'>
                                        <div className='post-title-wrap'>
                                            <Link to=''>POPULAR POSTS</Link>
                                        </div>
                                        {earthScoopList&&earthScoopList.map((item) =>

                                            <div className='post-wrapper alignleft mrt-20'>
                                                <div className='post-img mrr-20'>
                                                    <img className="img-fluid" src={item?.image} alt="Earthood" id='' />
                                                </div>
                                                <div className='post-content'>
                                                    <div className='post-titile'>
                                                        <h4><Link to={`/${item.slug}`}> {item?.short_description}</Link></h4>
                                                    </div>
                                                    <div className='post-author'>
                                                        <h6>- {item?.author}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Layout>
            </Fragment>

        )
    } else if (slugExist===false) {
        return (
            <Error />
        )
    }
}

export default Earthscoop1;


import React from 'react'
import Layout from '../../components/layout';
import earthscoop2 from '../../assets/images/earthscoop/2.png'
import earthscoop3 from '../../assets/images/earthscoop/3.png'
import earthscoop6 from '../../assets/images/earthscoop/6.png'
import { Link } from 'react-router-dom';
const Earthscoop3=() => {
    return (
        <Layout>
            <div className='inner-earthscoop'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-8'>
                            <div class="content">
                                <img className="img-fluid" src={earthscoop3} alt="Earthood" id='' />
                                <div className='title'><h3><span>UNITED NATIONS’ BRIDGE FROM KYOTO TO PARIS</span></h3>
                                    <h6>-  BY: DR. KAVIRAJ SINGH ( M D )</h6>
                                </div>
                                <div className='pd-left'>
                                    <p> The <b>CDM Executive Board (EB)</b> 108th meeting was held between 1st December 2020 to 14th December 2020 and because of COVID-19 pandemic this meeting was also organised virtually. The most awaited outcome of this meeting was some clarity on the continuity of CDM post 2020 but it seems that future of CDM still remains in dark. However, the CDM EB tried and provided some interim measures until any further guidance come from COP. The board also sent the recommendation to Conference of the Parties serving as the meeting of the <b>Parties to the Kyoto Protocol (CMP)</b> on the implications of postponement of the operation of CDM.</p>
                                    <h5>The guidance sought by CDM EB from CMP</h5>
                                    <p>
                                        The board requested CMP to provide clarity on the following points:
                                        <ul>
                                            <li>Which global warming potential (GWP) values from the Intergovernmental Panel on Climate Change assessment reports are to be applied post 2020?</li>
                                            <li>The values to be used in place of commitment period identifiers in the serial number of CERs for emission reductions occurring after the end of the second commitment period?</li>
                                            <li>The applicable modalities and procedures for afforestation and reforestation project activities and programmes of activities, including small scale activities, after the end of the second commitment period?</li>
                                            <li> The Board noted that the CMP would need to provide technical options regarding the CDM registry in order to enable issuance of CERs with respect to emission reductions accruing after the end of the second commitment period for voluntary cancellation purposes and requested the secretariat to prepare an assessment of such options for consideration by the Board at its 109th meeting.</li>

                                        </ul>

                                    </p>
                                    <h5>Request for Issuance:</h5>
                                    <p>The request for issuance for the CDM credits generated by CDM PoA/PA from 01st January 2021 can still be submitted to UNFCCC inline to existing CDM & CMP rules with the following exceptions.</p>
                                    <p>
                                        <ul>
                                            <li>UN shall not charge any issuance fee.</li>
                                            <li>PP/CME shall provide an acknowledgement that they accept the risk of these CERs may not be issued.</li>
                                            <li>The issuance of CERs shall be “provisional’ and only be finalised by the board after guidance from CMP.</li>
                                            <li>The Designated Operational Entities (DOE) shall raise the forward action request (FAR) in the validation report that PP and CME will change the GWP that will be adopted in the coming CMP and submit the revised documentation as and when needed.</li>

                                        </ul>
                                    </p>
                                    <h5>Other Key Takeaways from the EB Meeting</h5>
                                    <ul>
                                        <li>DOE may apply alternative measures of on-site inspection for validation and verification of CDM PA/POA until 30th June, 2021.</li>
                                        <li>The EB considered the concept note on “Consistent use of market penetration metrics for additionality, common practice and FOIK” and conclude the following:</li>
                                        <ul>
                                            <li>Propose a definition for the terminologies associated with penetration of the technology/measures and include in CDM glossary of terms.</li>
                                            <li> Propose a consistent approach to the data vintage and data sources used for the determination of penetration of technology/measure with a view to include them in the CDM project cycle procedures or the CDM project standards;</li>
                                            <li>Revise "TOOL23: Additionality of first-of-its-kind project activities" and "TOOL24: Common practice" to explore including an appropriate penetration threshold for technology/measure (e.g. 2.5 per cent).</li>
                                        </ul>
                                        <li>Methodologies and Tool Revisions:</li>
                                        <ul>
                                            <li>AMS IE: Switch from non-renewable biomass for thermal application by the user. The revision addresses the issue of stove stacking to develop best practice examples.</li>
                                            <li>AMS-II.G: Energy-efficiency measures in thermal applications of non-renewable biomass. The revision addresses the issue of stove-stacking to develop best practice examples.</li>
                                            <li>TOOL30: Calculation of the fraction of non-renewable biomass. The revision streamlines the tool and improves the data collection procedures.</li>

                                        </ul>
                                        <li>CDM EB has provided some important clarifications, on the following methodologies;</li>
                                        <ul>
                                            <li>AMS-III.AU. and AMS-III.BF: on applicability conditions for rice cultivation projects & Methane emission reduction by adjusted water management practice in rice cultivation.</li>
                                            <li> AMS-III.BF: on NUE Seeds under AMS-III.BF, regarding methodology AMS-III.BF "Reduction of N2 O emissions from use of Nitrogen Use Efficient (NUE) seeds that require less fertilizer application".</li>
                                            <li>AMS-II.G: on Para. 38 on the life span of stoves and the default replacement of devices after the end of their life span,</li>
                                            <li>AMS-III.AJ: on which electric grid shall be selected to determine the grid emission factor (EFel,y) for baseline calculation.</li>
                                            <li>Board also adopted a revised procedure for “Development, revision, clarification and update of standardized baseline”.</li>
                                        </ul>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='sticky-bar positionsticky'>
                                <div className='post-title-wrap'>
                                    <Link to=''>POPULAR POSTS</Link>
                                </div>
                                <div className='post-wrapper alignleft mrt-20'>
                                    <div className='post-img mrr-20'>
                                        <img className="img-fluid" src={earthscoop2} alt="Earthood" id='' />
                                    </div>
                                    <div className='post-content'>
                                        <div className='post-titile'>
                                            <h4><Link to='/earthscoop2'> Key Stakeholder’s Perspective On Esg</Link></h4>
                                        </div>
                                        <div className='post-author'>
                                            <h6>- AVINASH KUMAR (EXECUTIVE DIRECTOR – ESG & NET ZERO)</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className='post-wrapper alignleft mrt-20'>
                                    <div className='post-img mrr-20'>
                                        <img className="img-fluid" src={earthscoop3} alt="Earthood" id='' />
                                    </div>
                                    <div className='post-content'>
                                        <div className='post-titile'>
                                            <h4><Link to='/earthscoop3'>UNITED NATIONS’ BRIDGE FROM KYOTO TO PARIS</Link></h4>
                                        </div>
                                        <div className='post-author'>
                                            <h6>- BY: DR. KAVIRAJ SINGH ( M D )</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className='post-wrapper alignleft mrt-20'>
                                    <div className='post-img mrr-20'>
                                        <img className="img-fluid" src={earthscoop6} alt="Earthood" id='' />
                                    </div>
                                    <div className='post-content'>
                                        <div className='post-titile'>
                                            <h4><Link to='/earthscoop6'>THE WORLD’S DRIVE TOWARDS CARBON NEUTRALITY</Link></h4>
                                        </div>
                                        <div className='post-author'>
                                            <h6>- BY: ARCHIT SRIVASTAVA</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Earthscoop3;

import React from 'react'
import Slider from 'react-slick';
import img1 from '../assets/images/accreditation/19.png'
import img2 from '../assets/images/accreditation/8.png'
import img3 from '../assets/images/accreditation/20.png'
import { Link } from 'react-router-dom'

const NewAccreditation=() => {
    var settings={
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        initialSlide: 0,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div className='accreditations' id='accreditations'>
            <div className='container d-none d-lg-block'>
                <div className='row jst-center'>
                    <div className='col-12'>
                        <div className='title'>
                            <h3>Accreditations
                            </h3>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6 col-12' >
                        <div className='box'>
                            <Link target='_blank' to="https://cdm.unfccc.int/" >
                                <div className='img-wrapper'>
                                    <img src={img1} className='img-fluid' />
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6 col-12' >
                        <div className='box'>
                            <Link target='_blank' to="https://gestandard.org/" >
                                <div className='img-wrapper'>
                                    <img src={img2} className='img-fluid' />
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6 col-12' >
                        <div className='box'>
                            <Link target='_blank' to="https://anab.ansi.org/" >
                                <div className='img-wrapper'>
                                    <img src={img3} className='img-fluid' />
                                </div>
                            </Link>
                        </div>
                    </div>

                </div>
            </div>

            {/* responsive start */}
            <div className='container d-lg-none'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='title'>
                            <h3>Accreditations
                            </h3>
                        </div>
                    </div>
                    <Slider {...settings}>
                        <div>
                            <div className='slider-wrapper'>
                                <div className='box'>
                                    <Link target='_blank' to="https://cdm.unfccc.int/" >
                                        <div className='img-wrapper'>
                                            <img src={img1} className='img-fluid' />
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='slider-wrapper'>
                                <div className='box'>
                                    <Link target='_blank' to="https://gestandard.org/" >
                                        <div className='img-wrapper'>
                                            <img src={img2} className='img-fluid' />
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='slider-wrapper'>
                                <div className='box'>
                                    <Link target='_blank' to="https://anab.ansi.org/" >
                                        <div className='img-wrapper'>
                                            <img src={img3} className='img-fluid' />
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>



                    </Slider>
                </div>
            </div>
            {/* responsive  end*/}

        </div>
    )
}

export default NewAccreditation;

import React from 'react'
import Layout from '../../components/layout';
import earthscoop2 from '../../assets/images/earthscoop/2.png'
import earthscoop3 from '../../assets/images/earthscoop/3.png'
import earthscoop6 from '../../assets/images/earthscoop/6.png'
import { Link } from 'react-router-dom';

const Earthscoop2=() => {
    return (
        <Layout>
            <div className='inner-earthscoop'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-8'>
                            <div class="content">
                                <img className="img-fluid" src={earthscoop2} alt="Earthood" id='' />
                                <div className='title'><h3><span>KEY STAKEHOLDER’S PERSPECTIVE ON ESG</span></h3>
                                    <h6>- BY : AVINASH KUMAR (EXECUTIVE DIRECTOR – ESG & NET ZERO)</h6>
                                </div>
                                <div className='pd-left'>
                                    <p>In today's scenario of the corporate world, many organizations are confused about ESG importance and more than that about its Assurance. According to them, ESG reporting is one of the other obligations that needs to be brought for financial stakeholders. They don’t see the skyline beyond financial investors.……..is it limited to this extent?
                                    </p>

                                    <p>Let's understand; ESG is about company stakeholders, identity, and decision-making — the board, CEO, employees, shareholders, and other stakeholders. One X company might be in the production of chemicals and making a decent profit out of it. Nothing wrong with that. What if the same company is dumping some amount of this poison in the river next to its operation plant….can it entices some risks, if yes from whom. By Government or investors and and and………….or CONSUMER…….Big one……..isn’t it?</p>
                                    <p>A more diluted situation; Y company is world-class in energy efficiency, have zero water and waste strategy which is in practice as well but within the same company, employees are getting injured off and on due to inadequate systems or old machines, long working hours, hire and fire rule etc.</p>
                                    <p>How to rate X or Y? Which one is better, or which one is less awful in the eyes of consumers keeping the Government, investors, and other stakeholders aside for a moment?</p>
                                    <p>According to some research, Consumers indicate they’re more likely to purchase from companies that prioritize solutions to environmental (80%), social (76%), and governance (80%) causes. This positive outcome also holds true in the B2B world, where it’s important that companies working together operate on the same level of risk management.</p>
                                    <p>So, what exactly is ESG? To be clear, it is not CSR 2.0. It’s a response to rising pressure from consumers for businesses to commit to and demonstrate their part in driving change to be more sustainable, inclusive and socially responsible. And to do that in a way that is measurable. But the irony is that most of the companies envisage ESG under the fear of losing investors forgetting that most key stakeholder is Consumer and the same style is followed by most of the consultants. They sell ESG services stating it is inevitable if investors are to be allured. Surprisingly True!</p>
                                    <p>ESG Assurance is important for the public, investors, and the company alike. This ensures the reliability and correctness of strong environmental, social, and governance practices but often this is never told with the right perspective. Discussion happens around what is material and what not but the Nucleus of the framework remains you and me. Are we getting ample framework? The answer we all know but this unforeseen answer is not because something is incorrect with any guideline but because of the misalliance objective behind ESG.</p>
                                    <p>Consultants and Corporates need to help each other only then “Business as usual” will become worthwhile for everyone.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='sticky-bar positionsticky'>
                                <div className='post-title-wrap'>
                                    <Link to=''>POPULAR POSTS</Link>
                                </div>
                                <div className='post-wrapper alignleft mrt-20'>
                                    <div className='post-img mrr-20'>
                                        <img className="img-fluid" src={earthscoop2} alt="Earthood" id='' />
                                    </div>
                                    <div className='post-content'>
                                        <div className='post-titile'>
                                            <h4><Link to='/earthscoop2'> Key Stakeholder’s Perspective On Esg</Link></h4>
                                        </div>
                                        <div className='post-author'>
                                            <h6>- AVINASH KUMAR (EXECUTIVE DIRECTOR – ESG & NET ZERO)</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className='post-wrapper alignleft mrt-20'>
                                    <div className='post-img mrr-20'>
                                        <img className="img-fluid" src={earthscoop3} alt="Earthood" id='' />
                                    </div>
                                    <div className='post-content'>
                                        <div className='post-titile'>
                                            <h4><Link to='/earthscoop3'>UNITED NATIONS’ BRIDGE FROM KYOTO TO PARIS</Link></h4>
                                        </div>
                                        <div className='post-author'>
                                            <h6>- BY: DR. KAVIRAJ SINGH ( M D )</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className='post-wrapper alignleft mrt-20'>
                                    <div className='post-img mrr-20'>
                                        <img className="img-fluid" src={earthscoop6} alt="Earthood" id='' />
                                    </div>
                                    <div className='post-content'>
                                        <div className='post-titile'>
                                            <h4><Link to='/earthscoop6'>THE WORLD’S DRIVE TOWARDS CARBON NEUTRALITY</Link></h4>
                                        </div>
                                        <div className='post-author'>
                                            <h6>- BY: ARCHIT SRIVASTAVA</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Earthscoop2;

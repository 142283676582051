import React, { useEffect } from 'react'
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
const Header=(props) => {
    const location=useLocation();

    const { handleNavigateStory }=props;
    console.log(props);
    const navigate=useNavigate();
    useEffect(() => {
        // window.scrollIntoView()
        window.scrollTo(0, 0)
    }, [])


    useEffect(() => {
        const section=new URLSearchParams(location.search).get('section');
        if (section) {
            const activeSection=document.getElementById(section);
            console.log(activeSection, activeSection.offsetTop, 'activeSection.offsetTopactiveSection.offsetTopactiveSection.offsetTopactiveSection.offsetTop');
            if (activeSection) {
                let topOffset=activeSection.offsetTop;
                if (section==='earthscoop') {
                    topOffset=4652;
                }
                window.scrollTo({ top: topOffset, behavior: "smooth" });
            }
        }
    }, [location]);

    // our story navigation start

    // our story navigation end

    // Accreditation navigation start
    const handleNavigateAccreditations=() => {
        if (window.location.pathname=="/") {
            // navigate("/")
            let a=document.getElementById("navAccreditations")
            a.setAttribute("href", "#accreditations")
            const section=document.getElementById("accreditations");
            if (section) {
                const topOffset=section.offsetTop;
                window.scrollTo({ top: topOffset, behavior: "smooth" });
            }
        } else {
            navigate("/")

            setTimeout(() => {

                const section=document.getElementById("accreditations");
                if (section) {
                    const topOffset=section.offsetTop;
                    window.scrollTo({ top: topOffset, behavior: "smooth" });
                }
            }, 20);

        }
    }
    // Accreditation navigation end
    // Earthscoop navigation start
    const handleNavigateEarthscoop=() => {
        if (window.location.pathname=="/") {
            // navigate("/")
            let a=document.getElementById("navEarthscoop")
            a.setAttribute("href", "#earthscoop")
            const section=document.getElementById("earthscoop");
            if (section) {
                const topOffset=section.offsetTop;
                window.scrollTo({ top: topOffset, behavior: "smooth" });
            }
        } else {
            navigate("/")

            setTimeout(() => {

                const section=document.getElementById("earthscoop");
                if (section) {
                    const topOffset=section.offsetTop;
                    window.scrollTo({ top: topOffset, behavior: "smooth" });
                }
            }, 20);

        }
    }
    // Earthscoop navigation end

    return (
        <div className='header'>
            <Navbar expand="lg">
                <Container >
                    <Navbar.Brand href="/"><img src={logo} className="" alt="Earthood" id='header_image' /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                        <Nav
                            className=" my-2 my-lg-0"
                            navbarScroll={true}
                        // defaultActiveKey={'#our-story'}
                        // activeKey="#our-story"

                        >
                            {/* <Nav.Link id="navStory" onClick={handleNavigateStory}>OUR STORY  </Nav.Link> */}
                            <Link className='nav-link' to="/?section=our-story">OUR STORY</Link>

                            {/* <Nav.Link id="navAccreditations" onClick={handleNavigateAccreditations}>ACCREDITATIONS </Nav.Link> */}
                            {/* <Nav.Link href="#accreditations" id="navAccreditations" to="/?section=accreditations">ACCREDITATIONS </Nav.Link> */}
                            <Link className='nav-link' to="/?section=accreditations">ACCREDITATIONS</Link>

                            <NavDropdown title="SERVICES" className='drop-head' href="#services" id="navbarScrollingDropdown" renderMenuOnMount={true}>
                                <Link className='dropdown-item' to="/climate">Climate Change</Link>
                                <Link className='dropdown-item' to="/esg">ESG & Decarbonization</Link>
                                <Link className='dropdown-item' to="/sustainability">Sustainability</Link>
                                <Link className='dropdown-item' to="/agriculture">Agriculture</Link>
                            </NavDropdown>
                            <Link className='nav-link' to="/earthoodies">EARTHOODIES</Link>
                            {/* <Nav.Link href="#earthscoop" id="navEarthscoop" onClick={handleNavigateEarthscoop}>EARTHSCOOP</Nav.Link> */}
                            {/* <Nav.Link href="#earthscoop" id="navEarthscoop" to="/?section=earthscoop">EARTHSCOOP</Nav.Link> */}
                            <Link className='nav-link' to="/?section=earthscoop">EARTHSCOOP</Link>

                            <Link className='nav-link' to="/contact">CONTACT US</Link>

                        </Nav>

                    </Navbar.Collapse>
                </Container>
            </Navbar>

        </div>

    )
}

export default Header

import React from 'react'
import Layout from '../../components/layout';
import earthscoop13 from '../../assets/images/earthscoop/13.png';
import earthscoop2 from '../../assets/images/earthscoop/2.png'
import earthscoop3 from '../../assets/images/earthscoop/3.png'
import earthscoop6 from '../../assets/images/earthscoop/6.png'
import { Link } from 'react-router-dom';
const Earthscoop13=() => {
    return (
        <Layout>
            <div className='inner-earthscoop'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-8'>
                            <div class="content">
                                <img className="img-fluid" src={earthscoop13} alt="Earthood" id='' />
                                <div className='title'><h3><span>5 BIG MISTAKES IN FIRST-TIME ESG REPORTING</span></h3>
                                    <h6>- BY: AVINASH KUMAR</h6>
                                </div>
                                <div className='pd-left'>
                                    <p>In the last few months, Companies have been getting intensive about either improving their ESG (environmental, social, and governance) scores or publishing their reports. It is one of the most important reports a company prepares for responsible investors and key stakeholders, but it is not as simple or straightforward as it seem, especially if you are a first-timer! A lot of greenwashing can be tagged along or is already being tagged in the ESG domain. To avoid these, the intentions of clients and consultants need to be clearer and fairer. We are putting forth frequent mistakes made by first-time ESG reporters.</p>
                                    <h5>1. Inadequate prioritization of the ESG topics.</h5>
                                    <p>Trying to report on too many ESG topics is a fundamental mistake by many first-time reporters. This is where the materiality assessment comes into the picture. A materiality assessment enables a company to figure out, what to report on the most material ESG topics. Issues that are likely to affect the financial condition, or operating performance of businesses within a specific sector, need to be prioritized to begin with.</p>
                                    <p>A failure to prioritize the topics can mean that the report does not cover those topics that matter most, and as a result, the level of disclosure and transparency might not be fully captured.</p>
                                    <h5>2. Overconfidece about the accessibility of data for reporting</h5>
                                    <p>One of the reasons why first-timer build walls of text with fluffy words is that the data for measuring and monitoring their progress is difficult to get. It’s a blunder to assume that it will be easy to consolidate.</p>
                                    <p>We need to realize that sourcing data takes time and usually relies upon the cooperation of several stakeholders and functions across a company. It will be unwise to assume that the company’s hierarchy will produce the required and necessary data when the time comes, instantly. Quality data requires the company to be measuring the right things in the first place, and people monitoring it must have a background in E/S/G.</p>
                                    <h5>3. Not using the right framework for ESG</h5>
                                    <p>It sounds good that, finally, you have the required data but how do you make all this information available to your end-users and stakeholders? Well, that depends on your specific needs. There are different frameworks like GRI, SASB, CDP, TCFD, GRESB, and many others. The selection of choosing the right framework depends on the questions of materiality and relevance, which can be assessed by considering the intended audience (for example, investors, boards, insurers, creditors, employees, governments, and auditors) and what they will do with the information (to make financial decisions, compare performance between organizations, or ensure compliance). It is equally feasible to refer to more than one framework – just evaluate carefully which ones fit the needs of your organization the best.</p>
                                    <h5>4. Setting no goals or targets</h5>
                                    <p>After having the data and the clarity on a framework to be followed, you need to look back and think carefully if everything is set or if you are lacking somewhere. There is a good probability that you have got very good and relevant data from your operations, but there is no clarity in terms of your goals and targets. Finally, where do you want to make progress in the future? Remember, your stakeholders want to see accountability and commitment. Setting goals and targets and communicating them publicly can successfully create a better & bigger image of your organization, that it cares about ESG topics and is not doing this for some obligation or under the pressure of peers who are doing it. And it doesn’t stop here. Stating what you want to achieve is one thing, but you will win the game if you know how to achieve it.</p>
                                    <p>A careful exercise needs to be done around, which targets have been achieved and which are still in progress and which have been missed as well. To accomplish the mission, you have to be brave enough to admit possible mistakes as well.</p>
                                    <h5>5. Non-alignment with the audience</h5>
                                    <p>Every second, thousands of invisible machines are crawling the internet for the ESG information their master sent them out to find. You never know these could be your investors, banks or rating agencies, and maybe some financial market players to form a judgment of your company. So, let’s feed those hungry little beasts by making your data accessible: display charts, tables, and pictures, and put a nice narrative around it on your website with all your ESG information.</p>
                                    <p>Always remember that more than what you say, it is more important how you say it. So if you have great data, it's fantastic, but you need to make sure that your ESG reports look “sexy” by including strong pictures, illuminating diagrams, and reader-friendly navigation.</p>
                                    <p>In summary, ESG reporting builds trust with stakeholders and consumers. Preparing an ESG report for the first time is not always easy and requires time, commitment, transparency, investment, capacity building, and resources to build the foundation for ongoing reporting.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='sticky-bar positionsticky'>
                                <div className='post-title-wrap'>
                                    <Link to=''>POPULAR POSTS</Link>
                                </div>
                                <div className='post-wrapper alignleft mrt-20'>
                                    <div className='post-img mrr-20'>
                                        <img className="img-fluid" src={earthscoop2} alt="Earthood" id='' />
                                    </div>
                                    <div className='post-content'>
                                        <div className='post-titile'>
                                            <h4><Link to='/earthscoop2'> Key Stakeholder’s Perspective On Esg</Link></h4>
                                        </div>
                                        <div className='post-author'>
                                            <h6>- AVINASH KUMAR (EXECUTIVE DIRECTOR – ESG & NET ZERO)</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className='post-wrapper alignleft mrt-20'>
                                    <div className='post-img mrr-20'>
                                        <img className="img-fluid" src={earthscoop3} alt="Earthood" id='' />
                                    </div>
                                    <div className='post-content'>
                                        <div className='post-titile'>
                                            <h4><Link to='/earthscoop3'>UNITED NATIONS’ BRIDGE FROM KYOTO TO PARIS</Link></h4>
                                        </div>
                                        <div className='post-author'>
                                            <h6>- BY: DR. KAVIRAJ SINGH ( M D )</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className='post-wrapper alignleft mrt-20'>
                                    <div className='post-img mrr-20'>
                                        <img className="img-fluid" src={earthscoop6} alt="Earthood" id='' />
                                    </div>
                                    <div className='post-content'>
                                        <div className='post-titile'>
                                            <h4><Link to='/earthscoop6'>THE WORLD’S DRIVE TOWARDS CARBON NEUTRALITY</Link></h4>
                                        </div>
                                        <div className='post-author'>
                                            <h6>- BY: ARCHIT SRIVASTAVA</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Earthscoop13;

import React from 'react'
import banner_bg from '../assets/video/river.mp4'
import banner_gif from '../assets/images/banner-gif.png'
import o_img from '../assets/images/o-img.png'
import chat_box from '../assets/images/chat-box.png'


const Banner=() => {
    return (
        <div className='banner'>
            <video autoPlay loop muted className="bg-vid" width="600" height="300" > <source src={banner_bg} type="video/mp4" /> </video>
            <div className='bg-overlay'></div>
            <div id="light">
                <div id="lineh1"></div>
                <div class="vertline"></div>
                <div id="lineh2"></div>
                <div id="lineh3"></div>
                <div id="lineh4"></div>
                <div class="vertline2"></div>
                <div id="lineh5"></div>
                <div id="lineh6"></div>
                <div id="lineh7"></div>
                <div id="lineh8"></div>
                <div class="vertline3"></div>
                <div id="lineh9"></div>
                <div id="lineh10"></div>
                <div id="lineh11"></div>
                <div class="vertline4"></div>
                <div id="lineh12"></div>
            </div>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 dmb-center'>

                        <div className='banner-gif'>
                            <div className="box1 animated infinite rotate-full">
                                <img src={banner_gif} className="" alt="Earthood" id='header_image' />
                            </div>
                        </div>
                        <div className='banner-content'>
                            <h2>Changing w<span className='o-img'><img src={o_img} className="" alt="Earthood" id='header_image' /></span>rld’s
                                Climattude</h2>
                            <p>Secure carbon offset credits and achieve net zero goals with us</p>
                        </div>
                        <div className='chat-box'>
                            <a href=''><img src={chat_box} className="" alt="Earthood" id='header_image' /></a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Banner;

import React from 'react'
import Layout from '../../components/layout';
import banner_img from '../../assets/images/egs/banner-bg.png';
import dcarbon from '../../assets/images/egs/dcarbon.png';
import esg_logo from '../../assets/images/egs/esg-logo.png';
import sec5_bg from '../../assets/images/egs/sec5-bg.png';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import Slider from 'react-slick';

const Esg=() => {
    var settings={
        infinite: true,
        speed: 1000,
        autoplay: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoPlay: true,
        initialSlide: 0,
        responsive: [

            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            }

        ]
    };
    return (
        <Fragment>
            <Helmet>
                <title>Earthood</title>
                <meta name="keywords" content="Earthood" />
                <meta name="description" content="Secure carbon offset credits and achieve net zero goals with us" />
                <meta property="og:url" content="https://www.earthood.in/" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Earthood" />
                <meta property="og:description" content="Secure carbon offset credits and achieve net zero goals with us" />
                <meta property="og:image" content="https://webapi.earthood.in/assets/assets/media/logos/earthood-logo.png" />
            </Helmet>
            <Layout>
                <div className='esg'>
                    <section className='banner-sec'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <div className='banner-img'>
                                        <img src={banner_img} className="" alt="Earthood" id='' />
                                    </div>
                                </div>
                                <div className='col-lg-6 df' >
                                    <div className='banner-content'>
                                        <h2>ESG & DECARBONIZATION</h2>
                                        <p>Our thought-leadership helps the decarbonization journey through solutions that minimize
                                            the OPEX, as we believe in the fundamental principle that money saved is money earned. Net
                                            zero means no new emissions, and it's more challenging than carbon neutrality since it
                                            requires cutting indirect emissions from the entire value chain.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className='decarbonisation'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-12'>
                                    <h2><span>ESG & DECARBONIZATION</span> </h2>
                                </div>
                            </div>
                            <div className='row'>
                                <div class="content">
                                    <img className="left-side" src={dcarbon} alt="Earthood" id='' />
                                    <p>Climate change threatens our revenue and profits, impacting infrastructure, health, and
                                        productivity in every sector. The World Economic Forum identifies climate action failure,
                                        extreme weather, and biodiversity loss among the top three of the 10 global risks by severity
                                        over the next 10 years.
                                    </p>

                                    <p>Climate risk is an investment risk, but the transition also presents a historic investment
                                        opportunity. Businesses with more genuine environmental, social, and governance (ESG)
                                        profiles have and will continue to exceed their peers. Post the Paris climate change
                                        agreement in 2015, many countries started taking commitments centered on achieving “net
                                        zero”. This transition of the world towards a Net-Zero economy by the year 2050 can uplift
                                        the environment in a remarkable way".</p>

                                    <p>We need to change with change before change forces us to change.</p>

                                </div>
                            </div>

                        </div>
                    </section>
                    <section className='section3'>
                        <div className='container'>
                            <div className='row'>
                                <h2><span>WHY ENVIRONMENTAL, SOCIAL & GOVERNANCE
                                    (ESG) IS IMPORTANT</span> </h2>

                            </div>
                            <div className='row'>
                                <div class="content">
                                    <img className="right-side" src={esg_logo} alt="Earthood" id='' />

                                    <p><span className='subhead'>Regulatory and Mandatory Requirements:</span>
                                        ESG reporting is mandatory for the top 1000
                                        listed companies in India. Similarly, it is a compliance requirement in Europe, US, Middle East,
                                        Africa, and Australia.</p>

                                    <p ><span className='subhead'>ESG linked to the Top and Bottom line Growth:</span>
                                        Companies with robust ESG strategies are
                                        also more probable to attract socially responsible investors, which can increase their access
                                        to capital and enhance their financial performance over the long term.</p>
                                    <p ><span className='subhead'>ESG Improves Credibility & Reputations:</span>
                                        ESG reports are commonly used by investors and
                                        regulators to assess important factors like carbon emissions, natural resource usage, and
                                        human rights. This can lead to: Increased loyalty, Wider brand awareness and Bigger market
                                        share</p>
                                    <p className='framework'><span className='subhead'>Helps in Reducing Risks:</span>
                                        By focusing on ESG issues, companies can identify and mitigate
                                        risks associated with issues such as: –
                                        <ul className='txt-white'>
                                            <li>Climate Change</li>
                                            <li>Labour practices</li>
                                            <li>Regulatory fines</li>
                                            <li>Supply chain management</li>
                                        </ul>
                                    </p>
                                    <p><span className='subhead'>Enhanced Innovation:</span>
                                        ESG promotes developing more sustainable products and processes
                                        which can open up new markets and differentiate themselves from the competitor</p>
                                    <p><span className='subhead'>Meeting Customer Expectations:</span>
                                        Customers increasingly demand that companies act
                                        responsibly and sustainably. ESG promotes and helps in meeting these expectations by
                                        building stronger customer loyalty and supply chain.</p>
                                    <p><span className='subhead'>ESG Can Uplift Employee Productivity:</span>
                                        ESG help businesses to engage and retain quality
                                        employees, boost employee motivation by giving a sense of purpose, and increase overall
                                        productivity. Employee satisfaction is entirely linked to shareholder returns.</p>
                                    <p><span className='subhead'>Beneficial Impact On The Environment And Societ:</span>
                                        Overall, companies can positively
                                        impact society and the environment if they have a strong ESG strategy. Companies can
                                        contribute to a better equitable, and sustainable future by prioritising sustainability, ethics,
                                        and transparency
                                    </p>

                                </div>
                            </div>
                        </div>
                    </section>
                    <section className='framework'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-12'>
                                    <h4><span>Popular ESG Frameworks where Earthood helps Organizations
                                        are:</span></h4>
                                    <div className='features' >
                                        <ul>
                                            <li>Global Reporting Initiative (GRI)</li>
                                            <li>Business Reporting and Sustainability Report (BRSR)</li>
                                            <li>Carbon Disclosure Project (CDP)</li>
                                            <li>Climate Disclosure Standards Board (CDSB)</li>
                                            <li>Science Based Targets Initiative (SBTI)</li>
                                            <li>Sustainability Accounting Standards Board (SASB)</li>
                                            <li>Task Force on Climate-related Financial Disclosures (TCFD)</li>
                                            <li>UN Principles for Responsible Investment (PRI)</li>
                                            <li>Sustainable Finance Disclosure Regulation (SFDR)</li>
                                        </ul>
                                    </div>

                                </div>
                            </div>
                            <hr className='hrline' />
                        </div>
                    </section>
                    <section className='sec5'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-12 u-line'>
                                    <h4><span>DECARBONIZATION AND NET ZERO</span></h4>
                                    <div className='content-wrapper'>
                                        <p>Decarbonization targets involve reducing greenhouse gas emissions and absorbing carbon
                                            from the atmosphere. This requires transforming all sectors of the economy, including power
                                            generation, industry, transport, buildings, and agriculture. The Net-Zero Standard by SBTi
                                            covers a company's entire value chain emissions. More than 4,500 businesses and financial
                                            institutions are working with SBTi to reduce emissions in line with climate science and 80
                                            Indian companies have already committed to reducing greenhouse gas emissions.</p>

                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className='img-wrapper'>
                            <img src={sec5_bg} className="" alt="Earthood" id='' />
                        </div>
                    </section>
                    <section className='sec5'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-12 u-line'>
                                    <h4><span>WHY DECARBONIZATION IS IMPORTANT?</span></h4>
                                    <div className='content-wrapper'>
                                        <p><span className='subhead'>Need of an Hour:</span> Decarbonisation has become a global imperative and a priority for
                                            governments, companies and society at large, because it plays a very important role in
                                            limiting global warming</p>
                                        <p><span className='subhead'>Achieving Net Zero Targets:</span> As per Paris Agreement 2015, all the countries have taken the
                                            commitment to become Net-Zero between 2050 to 2070. India has committed to achieving
                                            net-zero emissions by 2070. Unless industries and companies become Net Zero, no country
                                            can. </p>
                                        <p><span className='subhead'>Limit the Earth's temperature within 1.5°C to 2.0°C by the end of this century: </span>The
                                            United Nations calls for limiting global temperature increase to 1.5°C above pre-industrial
                                            levels to avert the worst impacts of climate change. This requires reducing emissions by 45%
                                            by 2030 and reaching net-zero by 2050.</p>
                                        <p><span className='subhead'>Carbon Border Adjustment Mechanism (CBAM):</span> Europe Union has implemented CBAM; a
                                            type of carbon tariff on imported goods from Non-EU countries in six (6) sectors of Iron &
                                            Steel, Cement, Aluminum, Fertilisers, Electricity and Hydrogen. This has been implemented to
                                            reduce carbon emissions from imported goods and prevent competitive disadvantage
                                            against countries with weaker environmental regulations. With CBAM coming into force from
                                            October 1, 2023, these sector companies mandatorily need to declare their GHG emissions
                                            and get them verified.</p>
                                        <p><span className='subhead'>Creating a sustainable economy:</span> Climate change caused by carbon emissions impacts all
                                            of us, and the economy will need to adapt to survive. In the new economy, businesses will be
                                            expected to use decarbonisation to achieve carbon neutrality, and abandon any business
                                            practices that don’t fall in line with sustainability targets.</p>
                                        <p className='txt-blue'>Companies that are proactive and progressing on a roadmap to become carbon neutral and netzero will win the battle not only for their bottom-line but for the betterment of the entire world.</p>

                                    </div>
                                </div>
                            </div>
                            <hr className='hrline' />
                        </div>
                    </section>
                    <section className='framework sec7'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-12 '>
                                    <h4><span>Earthood Services in ESG & Decarbonization</span></h4>
                                    <p>We provide dedicated technical services on all components of ESG and Decarbonization
                                        framework, including:</p>
                                    <div className='features' >
                                        <ul>
                                            <li>Developing business-specific ESG Framework</li>
                                            <li>Sustainability & ESG reporting as per leading reporting framework</li>
                                            <li>India specific Business reporting and sustainability report (BRSR) advisory</li>
                                            <li>GHG accounting and Inventory development as per ISO 14064-1, 2</li>
                                            <li>GHG Audit and verification as per ISO 14064-3</li>
                                            <li>Resource optimization & reduction strategy</li>
                                            <li>Carbon Neutrality Advisory and certification as per PAS 2060 standard</li>
                                            <li>Comprehensive Net-Zero Advisory as per SBTi standard.</li>
                                            <li>Physical Climate Risk Assessment (Flood, Cyclone, Drought, Precipitation, Extreme
                                                Winds, Heat Waves, Cold Waves) based on the Recommendations of the Task Force on
                                                Climate-related Financial Disclosures (TCFD)</li>
                                        </ul>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </section>
                             {/* testimonial */}
                             <section className='testim'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-12'>
                                    <Slider {...settings}>
                                        <div>
                                            <div className='slider-wrapper'>
                                              
                                                <p className='pafter'>"We must thanks to M/s Earthood , handhold us in the starting of our sustainability
joinery starting from developing roadmap , carbon inventory assessment and
feasibility for renewable energy by their well experienced team , also they supported
us in various training and upskilling on the subject.
 We again thank to team earthood,"</p>
   <span className='autor'>- Luminous; Schneider electric company </span>

                                            </div>
                                        </div>
                                        <div>
                                            <div className='slider-wrapper'>
                                              <div className='pafter'>
                                                <p className=''>"We engaged M/s Earthood for GHG verification and Carbon Neutrality certification.
                                                 In our objective  to become Carbon Neutral in own operations since inception, our endeavour was to 
                                                 estimate the carbon footprint in an independent manner and then to engage a reputed third party to 
                                                 verify both the carbon footprint as well as the offset process.  Earthood was very professional and 
                                                 thorough in their verification work. Their technical team checked all the GHG-related work meticulously 
                                                 and the findings raised were helpful to improve. "</p>
                                                <p> We will be happy to work with ESPL in the future again and wish you all success in climate change and GHG work.
                                                 </p>
                                                 </div>
                                                 <span className='autor'>- True North  </span>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='slider-wrapper'>
                                              <div className='pafter'>
                                                <p className=''>"We would like to thank M/s earthood team for Life Cycle Assessment for our organization. 
                                                We really impressed by timely support that your team has been provided to us. This has greatly helped us
                                                 to complete Life Cycle Analysis of Vergin cotton Vs Recycled cotton. We are indeed pleased with the fact 
                                                 that you have kept the quality standard high in providing complete report to us ,The level of details you 
                                                 have demonstrated on each point are very clear and impressive. Which shows that earthood team highly 
                                                 technical and quite professional in LCA analysis."</p>
                                                
                                                 </div>
                                                 <span className='autor'>- Vardhman Textile Ltd  </span>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='slider-wrapper'>
                                              <div className='pafter'>
                                                <p className=''>"We had an enriching experience with Earthood team while working on our Global GHG accounting for the first time."</p>
                                                <p> The team enabled all the stakeholders to work against a rigid timeline and provided the actionable strategies as recommendations of the exercise.
                                                 </p>
                                                 </div>
                                                 <span className='autor'>- Head- Sustainable operations, Leading international architectural design consultants with HQ in Singapore  </span>
                                            </div>
                                        </div>
                                      

                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* testimonial end*/}
                </div>

            </Layout>
        </Fragment>

    )
}

export default Esg;

import { NEWS_LETTER } from "../../api";
import axiosInstance from "../../axiosInstance";

export const newsLetter=(props) => onResponse => {
    try {
        axiosInstance.post(NEWS_LETTER, props.formData)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
import React from 'react'
import Layout from '../components/layout';
import Slider from 'react-slick';
import banner_logo from '../assets/images/sustainability/banner-logo.png'
import sec2_img from '../assets/images/sustainability/sec2-img.png'
import sec3_img from '../assets/images/sustainability/sec3-img.png'
import sec4_img from '../assets/images/sustainability/sec4-img.png'
import sec5_img from '../assets/images/sustainability/sec5-img.png'
import sec6_img from '../assets/images/sustainability/sec6-img.png'
import sec7_img from '../assets/images/sustainability/sec7-img.png'
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';

const Sustainability = () => {
    var settings = {
        infinite: true,
        speed: 1000,
        autoplay: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoPlay: true,
        initialSlide: 0,
        responsive: [

            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            }

        ]
    };
    return (
        <Fragment>
            <Helmet>
                <title>Earthood</title>
                <meta name="keywords" content="Earthood" />
                <meta name="description" content="Secure carbon offset credits and achieve net zero goals with us" />
                <meta property="og:url" content="https://www.earthood.in/" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Earthood" />
                <meta property="og:description" content="Secure carbon offset credits and achieve net zero goals with us" />
                <meta property="og:image" content="https://webapi.earthood.in/assets/assets/media/logos/earthood-logo.png" />
            </Helmet>
            <Layout>
                <div className='sustainability'>
                    {/* banner */}
                    <div className='banner'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-5'>
                                    <div className='img-wrapper'>
                                        <img src={banner_logo} alt='slider1' />
                                    </div>
                                </div>
                                <div className='col-md-7 dv-center'>
                                    <div className='uw-line'>
                                        <h3><span>SUSTAINABILITY</span> </h3>
                                        <p className='text-white'>Our Green Building Services provide consulting for obtaining various
                                            certifications for high-performance structures that meet certain standards for
                                            reducing natural resource consumption.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* banner end */}
                    {/* section 2 */}
                    <section className='sec2'>
                        <div className='container'>
                            <div className='row mb-reverse'>
                                <div className='col-md-6'>
                                    <div className='img-wrapper'>
                                        <img src={sec2_img} alt='slider1' />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='content-wrapper uline'>
                                        <h3><span>ENERGY AUDIT</span></h3>
                                        <p className='vline'>Earthood helps its client to carry out the energy audit which is the first step
                                            toward systemic efforts for conservation of energy. It involves collection and
                                            analysis of energy related data in a methodological manner. The audit of energy
                                            consumption is a specialized process. It takes thorough knowledge and expertise
                                            in multiple fields to estimate and monitor performance of individual equipment.</p>
                                    </div>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='features'>
                                        <div className='uline small-head'>
                                            <h3><span>Energy Audit covers various utilities as mentioned below but not limited to:</span></h3>
                                        </div>
                                        <ul>
                                            <li> HVAC System, Central Air Conditioning Systems</li>
                                            <li>Lighting System</li>
                                            <li>DG Operations</li>
                                            <li>Boilers</li>
                                            <li>Refrigeration Plant Equipments – Chiller</li>
                                            <li> Air Compressors</li>
                                            <li> Chilled Water Circulation Systems</li>
                                            <li>Brine Chillers</li>
                                            <li>Hot Water Generators</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <hr className='hrline' />
                        </div>
                    </section>
                    {/* section 2 end */}
                    {/* section 3 */}
                    <section className='sec3'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className='content-wrapper rline'>
                                        <h3><span>WATER AUDIT</span></h3>
                                        <p className='vrline'>Water audit is used to determine how much water is being lost or
                                            unaccounted for in a water distribution system. In order to make this determination,
                                            information about the amount of water produced is compared to the amount of water
                                            distributed and billed.</p>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='img-wrapper'>
                                        <img src={sec3_img} alt='slider1' />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='features'>
                                        <div className='uline small-head'>
                                            <h3><span>Audit is conducted by Earthood team in accordance with the Central Ground Water
                                                Authority (CGWA) guidelines and covers below mentioned services

                                            </span></h3>
                                        </div>
                                        <ul>
                                            <li>Assessment of Present Water Usage</li>
                                            <li>Assessment of Water Sources</li>
                                            <li>Assessment of Fresh Water Usage</li>
                                            <li>Assessment of Rainwater Harvesting Systems Implemented</li>
                                            <li>Verification of Current Water Treatment Practices</li>
                                            <li> Raw water and Fresh Water Treatment Systems</li>
                                            <li>Wastewater Treatment</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <hr className='hrline' />
                        </div>
                    </section>
                    {/* section 3 end */}
                    {/* section 4 */}

                    <section className='sec2'>
                        <div className='container'>
                            <div className='row mb-reverse'>
                                <div className='col-md-6'>
                                    <div className='img-wrapper'>
                                        <img src={sec4_img} alt='slider1' />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='content-wrapper uline'>
                                        <h3><span>GREEN BUILDING CERTIFICATIONS</span></h3>
                                        <p className='vline'>Our Green Building Services offers the consulting for securing
                                            various certifications for the buildings which are high performance structures and
                                            meet certain standards for reducing natural resource consumption. The building or
                                            structures which are efficient in terms of water, energy, material, waste, restoration
                                            and enhance the protection of health and indoor environmental quality, can be
                                            categorized as ‘Green Buildings’.</p>
                                    </div>

                                </div>
                            </div>

                            <hr className='hrline mt-50' />
                        </div>
                    </section>
                    <section className='sec3'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className='content-wrapper rline pd-right'>
                                        <h3><span>DUE DILIGENCE</span></h3>
                                        <span className='vrline'>
                                            <h5>WHY IS DUE-DILIGENCE REQUIRED?</h5>
                                            <p>Foreign companies looking to do business in India or any other
                                                third country should perform a due diligence check to identify or prepare against
                                                any possible risk exposure. Due diligence is usually conducted in cases of mergers
                                                and acquisitions, partnerships, joint ventures, and IPO.</p>
                                            <p>Every company in the host country need to comply with local and country level legal and
                                                regulatory compliance requirements, and due to this, tax regime can be difficult to navigate.
                                                Therefore, due diligence is required to ensure that the local company is in compliance with
                                                all the necessary laws and rules, and has been providing accurate information to the
                                                foreign company inquiring about its business. Collating, presenting, and understanding
                                                this evidence, however, can be complex and time-consuming.</p>
                                        </span>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='img-wrapper'>
                                        <img src={sec5_img} alt='slider1' />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='features'>
                                        <div className='uline small-head'>
                                            <h3><span>EARTHOOD DUE-DILIGENCE SERVICES</span></h3>
                                            <p>Our experience allows us to anticipate issues, enabling them to be proactively
                                                addressed before transaction timelines are impacted. Earthood distinguishes
                                                strengths and opportunities that can enhance the sale process and value of a
                                                divestment. Our services follow international standards and guidelines like
                                                IFC Performance Standards (IFC PS), ISO 14015, World Bank EHS Guidelines, ASTM
                                                standards, GRI Standards, statutory Legal requirements of India. Our due diligence
                                                offerings include:</p>
                                        </div>
                                        <ul>
                                            <li> Environment, Health, Safety Due-Diligence (EHSDD)</li>
                                            <li> Environmental Due-Diligence (EDD)</li>
                                            <li> Environment, Social, Governance Due-Diligence (ESGDD)</li>
                                            <li>Environmental and Social Due-Diligence (ESDD)</li>
                                            <li>Safety Compliance Audit or OHS Legal Audit</li>
                                            <li>Environmental Audits</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <hr className='hrline mt-50' />
                        </div>
                    </section>
                    {/* section 4 end*/}
                    {/* section 5 */}
                    <section className='sec2 '>
                        <div className='container'>
                            <div className='row mb-reverse'>
                                <div className='col-md-6'>
                                    <div className='img-wrapper'>
                                        <img src={sec6_img} alt='slider1' />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='content-wrapper uline'>
                                        <h3><span>ELECTRICAL SAFETY & THERMOGRAPHY AUDIT</span></h3>
                                        <p className='vline'>Concerns continue to grow on the grounds of electrical failures
                                            and the risks attached to fire due to a short circuit or malfunctioning of the
                                            electrical panels etc., which pose a threat to the sophisticated machinery and human
                                            life at the same time.</p>
                                    </div>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='content-wrapper uline small-head'>
                                    <h3><span>ELECTRICAL SAFETY AUDIT</span></h3>
                                    <p >Safety in the workplace is job number one for employers and employees alike. It is
                                        especially important for those who install and service electrical systems. Electrical
                                        hazards continue to threaten safety of people and property in the form of shocks, burns,
                                        injury, fire and explosion. With electricity having become an indispensable part of our
                                        life, electrical risks are to be managed effectively. Nothing can replace a worker or
                                        loved one that has died or suffered the irreparable consequences of an electrical
                                        accident.</p>
                                    {/* <p>Electrical safety refers to any safety precautions taken against electricity.
                                        Implementing and following a well designed Electrical Safety Program will protect
                                        employees and employers against:</p> */}
                                </div>
                                <div className='content-wrapper uline small-head'>
                                    <h3><span>THERMOGRAPHY AUDIT</span></h3>
                                    <p >Thermography is a non destructive test method which is used as predictive maintenance
                                        fault finding technique in electrical equipments and helps in identifying potential
                                        electrical safety hazards and fire accidents.</p>
                                    <p>The technique inspects electrical equipments by obtaining heat distribution pictures
                                        and is based on the fact that most components in a system show an increase in temperature
                                        when malfunctioning.</p>
                                </div>
                            </div>

                            <hr className='hrline' />
                        </div>
                    </section>
                    <section className='sec3'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className='content-wrapper rline pd-right'>
                                        <h3><span>EPR ADVISORY SERVICES</span></h3>
                                        <span className='vrline'>
                                            <h5>WHAT IS EPR ?</h5>
                                            <p>EPR stands for extended producers’ responsibility i.e brand owners, importers,
                                                producers or manufacturers are responsible for the treatment and disposal of their
                                                post-consumer products. They are required to maintain a proper EPR Action plan to
                                                collect back the plastic waste generated due to their products.</p>

                                        </span>
                                    </div>
                                    <div className='features'>
                                        <div className='uline small-head'>
                                            <h3><span>EARTHOOD ADVISORY IN EPR</span></h3>
                                            <p>Earthood provides following support in EPR</p>
                                        </div>
                                        <ul className='fz-13'>
                                            <li> EPR compliance support for Electronic waste following E waste management rules</li>
                                            <li> EPR compliance support for Plastic waste following Plastic waste management rules</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='img-wrapper'>
                                        <img src={sec7_img} alt='slider1' />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>
                    {/* section 5 end*/}
                    {/* testimonial */}
                    <section className='testim'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-12'>
                                    <Slider {...settings}>
                                        <div>
                                            <div className='slider-wrapper'>
                                                <div className='text-center pb-20'>
                                                    <h5>INVALUABLE ADVISE</h5>
                                                    <span className='autor'>- MR. SUDHANSHU VASHISHTHA<br /> (MD, RAVIM INFRA)  </span>
                                                </div>
                                                <p className='pafter'>"Earthood is helping us with a large number of increasingly complex projects. We are able
                                                    to offer our clients innovative Green Buildings design solutions with a reinstated confidence that compliance can
                                                    be achieved without compromise."</p>

                                            </div>
                                        </div>
                                        <div>
                                            <div className='slider-wrapper'>
                                                <div className='text-center pb-20'>
                                                    <h5>12% REDUCTION IN CONSUMPTION</h5>
                                                    <span className='autor'>- SHISHIR SANGAL <br />(DIRECTOR, SIDDHESHWARI INDUSTRIES P.LTD)  </span>
                                                </div>
                                                <p className='pafter'>"In just 12 months, Earthood helped us reduce energy consumption by almost 12% purely by improving
                                                    operational efficiency and minor tweaks which we never paid heed to. I heartily recommend their service. "</p>

                                            </div>
                                        </div>
                                        {/* <div>
                                        <div className='slider-wrapper'>
                                            <div className='text-center pb-20'>
                                                <h5>12% REDUCTION IN CONSUMPTION</h5>
                                                <span className='autor'>- SHISHIR SANGAL (DIRECTOR, SIDDHESHWARI INDUSTRIES P.LTD)  </span>
                                            </div>
                                            <p className='pafter'>"Earthood was instrumental in the project's progress,
                                                specially when it was a first of it's kind! we needed an
                                                auditor who could understand our idea and deliver on
                                                time. The audit team worked seamlessly and their
                                                findings helped the project to take a better shape.
                                                Thank you kavi for your value added technical
                                                approach to make this one a success. looking forward
                                                to more"</p>

                                        </div>
                                    </div> */}


                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* testimonial end*/}
                </div>
            </Layout>
        </Fragment>

    )
}

export default Sustainability;

import React from 'react'
import { Link } from 'react-router-dom'
import banner_logo from '../assets/images/policy/banner-logo.png'
import Layout from '../components/layout'
import policy1 from '../assets/pdf/policy/impartiality_policy.pdf'
import policy2 from '../assets/pdf/policy/quality_policy.pdf'
import policy3 from '../assets/pdf/policy/terms_of_reference_policy.pdf'
import policy4 from '../assets/pdf/policy/organisational_structure_policy.pdf'
import policy5 from '../assets/pdf/policy/verification_process_policy.pdf'
import policy6 from '../assets/pdf/policy/validation_process_policy.pdf'
import policy7 from '../assets/pdf/policy/contract_review_procedure_policy.pdf'
import policy8 from '../assets/pdf/policy/complaint_procedure_policy.pdf'
import policy9 from '../assets/pdf/policy/appeal_procedure_policy.pdf'
import policy10 from '../assets/pdf/policy/terms_of_reference.pdf'
import policy11 from '../assets/pdf/policy/appeal_form.docx'
import download from '../assets/images/policy/document.svg'
import policy12 from '../assets/pdf/policy/VM_roles.pdf'
import policy13 from '../assets/pdf/policy/dispuit-policy.pdf'
import { Fragment } from 'react'
import { Helmet } from 'react-helmet'

const Policy=() => {
    return (
        <Fragment>
            <Helmet>
                <title>Earthood</title>
                <meta name="keywords" content="Earthood" />
                <meta name="description" content="Secure carbon offset credits and achieve net zero goals with us" />
                <meta property="og:url" content="https://www.earthood.in/" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Earthood" />
                <meta property="og:description" content="Secure carbon offset credits and achieve net zero goals with us" />
                <meta property="og:image" content="https://webapi.earthood.in/assets/assets/media/logos/earthood-logo.png" />
            </Helmet>
            <Layout>
                <div className='policy sustainability'>
                    <div className='banner'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-5'>
                                    <div className='img-wrapper'>
                                        <img src={banner_logo} alt='slider1' />
                                    </div>
                                </div>
                                <div className='col-md-7 dv-center'>
                                    <div className='uw-line'>
                                        <h3><span>POLICIES & PROCEDURE</span> </h3>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className='main-content'>
                        <div className='container'>
                            <div className='list'>
                                <div className='row'>
                                    <div className='col-lg-4 col-md-4 col-sm-6 col-12 mt-20'>

                                        <a target="_blank" href={policy1}> <div className='img-wrapper'><img src={download} /></div>Impartiality policy</a>
                                    </div>
                                    <div className='col-lg-4 col-md-4 col-sm-6 col-12 mt-20'>
                                        <a target="_blank" href={policy2}> <div className='img-wrapper'><img src={download} /></div>Quality Policy</a>
                                    </div>
                                    <div className='col-lg-4 col-md-4 col-sm-6 col-12 mt-20'>
                                        <a target="_blank" href={policy3}> <div className='img-wrapper'><img src={download} /></div>Terms of Reference</a>
                                    </div>
                                    <div className='col-lg-4 col-md-4 col-sm-6 col-12 mt-20'>
                                        <a target="_blank" href={policy4}> <div className='img-wrapper'><img src={download} /></div>Organisational Structure</a>
                                    </div>
                                    <div className='col-lg-4 col-md-4 col-sm-6 col-12 mt-20'>
                                        <a target="_blank" href={policy5}> <div className='img-wrapper'><img src={download} /></div>Verification Process</a>
                                    </div>
                                    <div className='col-lg-4 col-md-4 col-sm-6 col-12 mt-20'>
                                        <a target="_blank" href={policy6}> <div className='img-wrapper'><img src={download} /></div>Validation Process</a>
                                    </div>
                                    <div className='col-lg-4 col-md-4 col-sm-6 col-12 mt-20'>
                                        <a target="_blank" href={policy7}> <div className='img-wrapper'><img src={download} /></div>Contract Review Procedure</a>
                                    </div>
                                    <div className='col-lg-4 col-md-4 col-sm-6 col-12 mt-20'>
                                        <a target="_blank" href={policy8}> <div className='img-wrapper'><img src={download} /></div>Complaint Procedure</a>
                                    </div>
                                    <div className='col-lg-4 col-md-4 col-sm-6 col-12 mt-20'>
                                        <a target="_blank" href={policy9}> <div className='img-wrapper'><img src={download} /></div>Appeal Procedure</a>
                                    </div>
                                    <div className='col-lg-4 col-md-4 col-sm-6 col-12 mt-20'>
                                        <a target="_blank" href={policy10}> <div className='img-wrapper'><img src={download} /></div>Terms of reference (Roles and Responsibilities)</a>
                                    </div>
                                    <div className='col-lg-4 col-md-4 col-sm-6 col-12 mt-20'>
                                        <a target="_blank" href={policy11}> <div className='img-wrapper'><img src={download} /></div>Complaint, Dispute, Appeal Form</a>
                                    </div>
                                    <div className='col-lg-4 col-md-4 col-sm-6 col-12 mt-20'>
                                        <a target="_blank" href={policy13}> <div className='img-wrapper'><img src={download} /></div>Dispute policy and procedure</a>
                                    </div>
                                    {/* <div className='col-lg-4 col-md-4 col-sm-6 col-12 mt-20'>
                                        <a target="_blank" href={policy12}> <div className='img-wrapper'><img src={download} /></div>Voluntary Mechanism</a>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </Layout>
        </Fragment>

    )
}

export default Policy

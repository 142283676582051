import React from 'react'
import Layout from '../components/layout';
import Slider from 'react-slick';
// import banner_logo from '../assets/images/agriculture/banner-logo.png'
// import sec2_img from '../assets/images/agriculture/sec2-img.png'
// import sec3_img from '../assets/images/agriculture/sec3-img.png'
// import sec4_img from '../assets/images/agriculture/sec4-img.png'
// import sec5_img from '../assets/images/agriculture/sec5-img.png'
// import sec6_img from '../assets/images/agriculture/sec6-img.png'
// import sec7_img from '../assets/images/agriculture/sec7-img.png'
import banner_logo_v1 from '../assets/images/agriculture/banner-logo-v1.png';
import banner_agriculture from '../assets/images/agriculture/banner-agriculture.png';
import sec2_v1_img from '../assets/images/agriculture/sec2-img-v1.png';
import sec3_v1_img from '../assets/images/agriculture/sec3-img-v1.png';
import sec4_v1_img from '../assets/images/agriculture/sec4-img-v1.png';
import sec5_v1_img from '../assets/images/agriculture/sec5-img-v1.png';
import sec6_v1_img from '../assets/images/agriculture/sec6-img-v1.png';
import sec7_v1_img from '../assets/images/agriculture/sec7-img-v1.png';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';

const Agriculture = () => {
    var settings = {
        infinite: true,
        speed: 1000,
        autoplay: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoPlay: true,
        initialSlide: 0,
        responsive: [

            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            }

        ]
    };
    return (
        <Fragment>
            <Helmet>
                <title>Earthood</title>
                <meta name="keywords" content="Earthood" />
                <meta name="description" content="Secure carbon offset credits and achieve net zero goals with us" />
                <meta property="og:url" content="https://www.earthood.in/" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Earthood" />
                <meta property="og:description" content="Secure carbon offset credits and achieve net zero goals with us" />
                <meta property="og:image" content="https://webapi.earthood.in/assets/assets/media/logos/earthood-logo.png" />
            </Helmet>
            <Layout>
                <div className='sustainability agriculture'>
                    {/* banner */}
                    <div className='banner'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-5'>
                                    <div className='img-wrapper'>
                                        <img src={banner_logo_v1} alt='slider1' />
                                    </div>
                                </div>
                                <div className='col-md-7 dv-center'>
                                    <div className='uw-line'>
                                        <h3><span>AGRICULTURE</span> </h3>
                                        <p className='text-white'>Helping farmers in doubling their income through forward and
                                            backward linkages. We connect the farmers to their customers by engaging in community
                                            mobilization, cluster identification, preparation and implementation of business plans
                                            and management of resources of the farmers’ organization.
                                        </p>
                                        <p className='text-white'>As an empanelled CBBO by SFAC under Government of India,
                                            Earthood Agriculture Services have assisted in the promotion and functioning of FPOs
                                            while providing quality knowledge base for all issues related to FPO promotion.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className='banner'>
                    <div className='container'>
                        <div className='banner-img'>
                            <div className='row'>
                                <div className='col-md-5'>
                                    <div className='img-wrapper'>
                                        <img className='' src={banner_logo_v1} alt='slider1' />
                                    </div>
                                </div>
                                <div className='col-md-7 dv-center'>
                                    <div className='uw-line'>
                                        <h3><span>AGRICULTURE</span> </h3>
                                        <p className='text-white'>Helping farmers in doubling their income through forward and
                                            backward linkages. We connect the farmers to their customers by engaging in community
                                            mobilization, cluster identification, preparation and implementation of business plans
                                            and management of resources of the farmers’ organization.
                                        </p>
                                        <p className='text-white'>As an empanelled CBBO by SFAC under Government of India,
                                            Earthood Agriculture Services have assisted in the promotion and functioning of FPOs
                                            while providing quality knowledge base for all issues related to FPO promotion.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                    {/* banner end */}
                    {/* section 2 */}
                    <section className='sec2 pt-60'>
                        <div className='container'>
                            <div className='row mb-reverse'>
                                <div className='col-md-6'>
                                    <div className='img-wrapper'>
                                        <img src={sec2_v1_img} alt='slider1' />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='content-wrapper uline'>
                                        <h3><span>ASSISTANCE TO SFAC</span></h3>
                                        <p className='vline'>SFAC, Small Farmers Agribusiness Consortium is one of the implementing agencies working to promote and execute the Centre’s scheme of Formation & Promotion of 10,000 FPOs. National Level Project Management Advisory and Fund Sanctioning Committee (N-PMAFSC) has allocated numerous FPOs to SFAC for the same in a phased manner. As an empanelled CBBO, Earthood assists the SFAC for implementation of programs, cluster identification, doing baseline surveys, developing rating tools, value chain studying and community mobilization.</p>
                                    </div>

                                </div>
                            </div>
                            <hr className='hrline' />
                        </div>
                    </section>
                    {/* section 2 end */}
                    {/* section 3 */}
                    <section className='sec3'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className='content-wrapper rline'>
                                        <h3><span>FORMATION AND OPERATION OF FPOs</span></h3>
                                        <p className='vrline'>As a CBBO, Earthood works to organize and execute awareness
                                            campaigns wherein it mobilizes farmers, understands land holding patterns and
                                            identifies produce cluster areas. Earthood also assists in FPO registrations
                                            and makes agreements with farmers, define rules, performs registrations with ROC
                                            and completes due diligence. Preparation of business plans, estimating OPEX & CAPEX,
                                            liabilities, etc., arranging cash flows for operations and estimating revenues and
                                            managing expenditures are a part of the service bouquet.</p>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='img-wrapper'>
                                        <img src={sec3_v1_img} alt='slider1' />
                                    </div>
                                </div>
                            </div>
                            <hr className='hrline' />
                        </div>
                    </section>
                    {/* section 3 end */}
                    {/* section 4 */}

                    <section className='sec2'>
                        <div className='container'>
                            <div className='row mb-reverse' >
                                <div className='col-md-6'>
                                    <div className='img-wrapper'>
                                        <img src={sec4_v1_img} alt='slider1' />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='content-wrapper uline'>
                                        <h3><span>TRAINING & CAPACITY DEVELOPMENT OF FPOs</span></h3>
                                        <p className='vline'>Earthood considers it its duty to enhance the outreach of FPOs
                                            by conducting frequent training and capacity development programmes for the members.
                                            Our team has undertaken activities including identification of training needs,
                                            developing modules and organizing trainings. Our agricultural experts have also
                                            promoted team building and have conducted many exposure visits with farmers and
                                            have shown exceptional understanding in product branding for their clients.</p>
                                    </div>

                                </div>
                            </div>

                            <hr className='hrline' />
                        </div>
                    </section>
                    <section className='sec3'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className='content-wrapper rline pd-right'>
                                        <h3><span>MARKET LINKAGES
                                            FOR FPOs</span></h3>
                                        <span className='vrline'>
                                            <p>As a CBBO, Earthood has illustrated great success in linking the producers, buyers and exporters
                                                and enhancing global connectivity by bridging the gap between various stakeholders. We assist in
                                                scaling the production and develop supply chains for farmers. Our tech savvy team has created many
                                                connections on online platforms and is on toes in accessing the most advanced technologies.</p>
                                        </span>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='img-wrapper'>
                                        <img src={sec5_v1_img} alt='slider1' />
                                    </div>
                                </div>
                            </div>
                            <hr className='hrline' />
                        </div>
                    </section>
                    <section className='sec2'>
                        <div className='container'>
                            <div className='row  mb-reverse'>
                                <div className='col-md-6'>
                                    <div className='img-wrapper'>
                                        <img src={sec6_v1_img} alt='slider1' />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='content-wrapper uline'>
                                        <h3><span>KNOWLEDGE TRANSFER
                                            FOR FPOs</span></h3>
                                        <p className='vline'>Earthood has a highly qualified and diverse team of agricultural experts with more than two
                                            decades of experience in this field. Earthood acts as a crop management advisor and helps in development of own seed
                                            banks, processing, storage and packaging units. We also provide incubation / hand holding services and have always
                                            held strong interactions with various stakeholders.</p>
                                    </div>

                                </div>
                            </div>

                            <hr className='hrline' />
                        </div>
                    </section>
                    <section className='sec3'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className='content-wrapper rline pd-right'>
                                        <h3><span>FINANCIAL MANAGEMENT
                                            FOR FPOs</span></h3>
                                        <span className='vrline'>
                                            <p>Donning the hat of a Financial Manager for the FPOs, Earthood helps its clients in getting Equity Grants and
                                                Credits, maintains accounts and secures legal compliances and performs regular monitoring of team performance
                                                and funds.</p>
                                        </span>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='img-wrapper'>
                                        <img src={sec7_v1_img} alt='slider1' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* section 4 end*/}

                    {/* testimonial */}
                    <section className='testim'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-12'>
                                    <Slider {...settings}>
                                        <div>
                                            <div className='slider-wrapper'>
                                                <div className='text-center pb-20'>
                                                    <h5>DIRECT ACCESS TO QUALITY BUYERS BY
                                                        EFFECTIVE MARKET LINKAGES</h5>
                                                    <span className='autor'>- MR. ARAVIND K. <br /> (CEO, LAWAD SABJI PRODUCER CO. LTD) </span>
                                                </div>
                                                <p className='pafter'>"You have opened a whole new world for us. We never thought that farmers can get
                                                    direct access to such a profile of clients ever, but with your efforts on market linkages, we are at a
                                                    far more comfortable position dealing with quality buyers of our produce. Dhanyavaad bhaisaahab!"</p>

                                            </div>
                                        </div>
                                        <div>
                                            <div className='slider-wrapper'>
                                                <div className='text-center pb-20'>
                                                    <h5>DEVELOPMENT THROUGH UPLIFTMENT</h5>
                                                    <span className='autor'>- CH. ILAM SINGH POSWAL<br /> (PRESIDENT, KISAN WELFARE CLUB OF KARNAL) </span>
                                                </div>
                                                <p className='pafter'>"We realised the importance of training and coaching when it comes to continual
                                                    improvement, only by the help and able guidance of Earthood. We always consult our CBBO team whenever
                                                    we have any doubts to clarify or wish to learn more about a subject. We feel educated, uplifted and
                                                    stronger with Earthood’s team around us. Aashirwad aur shubhkaamnaayein! "</p>

                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* testimonial end*/}
                </div>
            </Layout>
        </Fragment>

    )
}

export default Agriculture;

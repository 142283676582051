export const BACKEND_URL='https://earthoodapi.aventusinformatics.com/api/'

//contact routes
export const CONTACT_ENQUIRY_FORM="contact-us/ContactUs/submit-contact-us";

//newsletter routes
export const NEWS_LETTER="home/News-letter/submit-news-letter"

// earthoodies
export const EARTHOODIES_POWERHOUSE_LIST="earthoodies/Powerhouse/get-all-powerhouse"
export const EARTHOODIES_TEAM_LIST="earthoodies/Earthoodies/get-all-earthoodies"
export const EARTHHOODIES_CORE_LIST="earthoodies/Core/get-all-core"

// earthscope
export const EARTHSCOPE_LIST="earthscope/Earthscope/get-all-earthscope"
export const EARTHSCOPE_SINGLE="earthscope/Earthscope/get-detail-earthscope"

// project
export const PROJECT_LIST="project/project/get-all-project"
export const ICONIC_PROJECT_LIST="project/iconic-project/get-all-iconic-project"

export const REGION_LIST="project/region/get-all-region"
export const METHODOLOGY_LIST="project/methodology/get-all-methodology"
export const PROGRAMME_LIST="project/programme/get-all-programme"
export const SERVICE_TYPE_LIST="project/service-type/get-all-service-type"
export const SECTOR_LIST="project/sector/get-all-sector"
export const COUNTRY_LIST="project/country/get-all-country"

// home
// ---milestone----
export const MILESTONE_LIST="home/Milestone/get-all-client-milestone"
// ---testimonials----
export const TESTIMONIAL_LIST="home/Client-testimonials/get-all-client-testimonials"
// ---clients----
export const CLIENT_LIST="home/Client/get-all-clients"

// webinar
export const WEBINAR_URL="webinar/Webinar/get-all-webinar"





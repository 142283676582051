import React from 'react'
import Layout from '../../components/layout';
import earthscoop6_img1 from '../../assets/images/earthscoop/6.png';
import earthscoop6_img2 from '../../assets/images/earthscoop/6.1.png';
import earthscoop6_img3 from '../../assets/images/earthscoop/6.2.png';
import earthscoop6_img4 from '../../assets/images/earthscoop/6.3.png';
import earthscoop2 from '../../assets/images/earthscoop/2.png'
import earthscoop3 from '../../assets/images/earthscoop/3.png'
import earthscoop6 from '../../assets/images/earthscoop/6.png'
import { Link } from 'react-router-dom';
const Earthscoop6=() => {
    return (
        <Layout>
            <div className='inner-earthscoop'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-8'>
                            <div class="content">
                                <img className="img-fluid" src={earthscoop6_img1} alt="Earthood" id='' />
                                <div className='title'><h3><span>THE WORLD’S DRIVE TOWARDS CARBON NEUTRALITY</span></h3>
                                    <h6>- BY: ARCHIT SRIVASTAVA</h6>
                                </div>
                                <div className='pd-left'>
                                    <p><b>“Carbon Neutrality”</b> a term that has been heard more often than not, in the recent times. Does this encapsulate something more than just being a buzz word? Is the world really acting towards achieving carbon neutrality? How are the states and the organizations doing on the front of ‘Climate Action’ in today’s world? Coronavirus has hit the global economy in a big manner, the profitability has gone for a toss. Amidst all of this, the pandemic, the global slowdown and multiple other geo-political factors (UNFCCC not having the mandate to continue the functioning of its CDM projects after December 31, 2020), where does the bill fit in, for Carbon Neutrality and accelerated Climate Action?</p>
                                    <h5>Well, all is not so gloomy, apparently.</h5>
                                    <p>Corporates across the world, from different sectors have started pledging and acting on becoming carbon neutral. We have seen a flurry of commitments from Oil and Gas majors to have carbon neutral operations in a phased manner by 2030, 2040 and some by 2050. After the likes of Repsol, Exxon Mobil, Total, BP, ENI, Kosmos, and Shell, Lundin also joins the league and targets to become Carbon Neutral soon. Focusing on the sustainable and planet-friendly model for energy generation, Reliance Industries (India) which predominantly remains a user of crude oil and natural gas, said that it will embrace new technologies to convert the carbon emission into useful products and chemicals. In the IT Sector, Google committed to being carbon neutral in 2007 and have met this goal every year since then. Adobe and Logitech have announced their pledges to make their operations carbon neutral and some will even strive to become carbon negative. Microsoft had announced their ambition to become carbon neutral back in 2012 and has been investing in carbon offset projects since then. Apple aims to have carbon neutral operations by 2030.</p>
                                </div>
                            </div>
                            <div class="content">
                                <img className="img-fluid" src={earthscoop6_img2} alt="Earthood" id='' />

                                <div className='pd-left'>
                                    <h5>All pledges so far… where is the action? Read on, as we discuss more.</h5>
                                    <p>Not taking away anything from the commitments of the corporates which drive the world’s economy in a major way, there has been raised ambition and few strategic decisions from these players which are noteworthy-

                                    </p>
                                    <p>Last year, <b>Shell</b> made its first foray into Australia's highly competitive power sector with a $441 million takeover offer for ERM Power. Royal Dutch Shell's Australia unit said on August 3 2020, it will buy environmental services firm Select Carbon as it seeks to cut back its emissions and expand its low-carbon and renewable power business. The Anglo-Dutch company has made a number of large investments in renewables and electric vehicle technologies and plans to boost spending on its power division to $2 to $3 billion per year by 2025 as the world rapidly shifts towards cleaner energy.</p>
                                    <p><b>Logitech</b> will be the first consumer electronics company to provide detailed carbon impact labelling on product packaging across the entire portfolio. In doing so, it intends to empower and collaborate with consumers, informing the purchasing choices they make. It also wishes to galvanize an industry-wide shift to dramatically lower the impact of carbon on the environment. Logitech is voluntarily communicating product carbon footprint information and will provide online access to the methodology and protocol applied, meeting carbon footprint quantification and communications or labelling standards outlined by ISO 14067 and ISO 14026. In December 2019, Logitech announced their entire gaming portfolio to be certified Carbon Neutral.</p>
                                    <p><b>Sky</b> has made a landmark commitment toward its ambition to be net zero carbon by 2030, with all its Sky Originals from 2019 on, now certified Carbon Neutral in the UK. Sky is the first broadcaster in the albert Consortium to achieve this status, marking its determination to be the most sustainable media and entertainment company in Europe.</p>
                                    <p>Global consulting firm <b>Oliver Wyman</b> on 5 June 2020, announced it is certified as a Carbon Neutral company as of June 1, 2020, for reducing greenhouse gas emissions associated with the operations of its business to zero in the 2019 calendar year. Oliver Wyman conducted a comprehensive review of its emissions from business operation and travel to calculate its 2019 greenhouse gas (GHG) emissions for Scopes 1, 2, and select Scope 3 (e.g. business travel) using the rigorous Carbon Neutral Protocol, the leading framework for carbon neutrality.</p>
                                    <p>On 4 June 2020, <b>Vertical Bridge REIT, LLC,</b> the largest private owner and operator of communications infrastructure in the United States, announced it has realized its goal to become 100% carbon neutral. The announcement marks an unprecedented step forward for the communications infrastructure sector, with Vertical Bridge emerging as the first telecommunications tower company in the world to reach net zero emissions.</p>
                                    <p>To transform a 150-company, multi-sector group into carbon neutrality by 2040 is a massive commitment, but the Mahindra Group is delivering on this and several other climate change and green initiatives. Also, the Group’s green revenue portfolio is worth half a billion dollars and includes electric vehicles, automotive recycling, solar energy, waste-to-energy and biogas, green buildings, and micro-irrigation.

                                    </p>
                                    <p><b>Bosch</b> has been one of the most aggressive players when it comes to carbon neutral business. The company aims for 100% neutrality this year itself! Bosch is making their 400+ locations carbon neutral in 2020. Going forward, they want to shape climate action and, also take a close look at the carbon footprint of the products, purchased goods, and logistics processes.</p>

                                </div>
                            </div>
                            <div class="content">
                                <img className="img-fluid" src={earthscoop6_img3} alt="Earthood" id='' />
                                <div className='pd-left'>
                                    <p>There is action. There is ambition. And then, there is more to it. Why are these companies who were heavyweights in carbon emissions till now are driven towards carbon neutrality? What is pressurising them to take such actions? Carbon Neutrality for Oil and Gas Companies? Is it possible? Is it really achievable?</p>
                                </div>
                            </div>
                            <div class="content">
                                <img className="img-fluid" src={earthscoop6_img4} alt="Earthood" id='' />
                                <div className='pd-left'>
                                    <h5>
                                        What are these corporates getting out of being CARBON NEUTRAL?</h5>
                                    <p>Dr. Kaviraj Singh, Founder and MD of Earthood Services Private Limited, on much demand from his last webinar in July, is hosting a FREE MASTERCLASS on CARBON NEUTRALITY again, with fresh & updated content on August 26, 2020. Being a veteran in the field of carbon auditing, with a relevant experience of about 2 decades, he will share his thoughts on the latest trends and nuances of becoming carbon neutral and how to achieve it with ease. More details and registrations at<a href=' :https://www.earthood.in/live' > :https://www.earthood.in/live</a>
                                    </p>
                                    <p>Join us to learn the most well-kept secrets of Carbon Neutrality!</p>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='sticky-bar positionsticky'>
                                <div className='post-title-wrap'>
                                    <Link to=''>POPULAR POSTS</Link>
                                </div>
                                <div className='post-wrapper alignleft mrt-20'>
                                    <div className='post-img mrr-20'>
                                        <img className="img-fluid" src={earthscoop2} alt="Earthood" id='' />
                                    </div>
                                    <div className='post-content'>
                                        <div className='post-titile'>
                                            <h4><Link to='/earthscoop2'> Key Stakeholder’s Perspective On Esg</Link></h4>
                                        </div>
                                        <div className='post-author'>
                                            <h6>- AVINASH KUMAR (EXECUTIVE DIRECTOR – ESG & NET ZERO)</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className='post-wrapper alignleft mrt-20'>
                                    <div className='post-img mrr-20'>
                                        <img className="img-fluid" src={earthscoop3} alt="Earthood" id='' />
                                    </div>
                                    <div className='post-content'>
                                        <div className='post-titile'>
                                            <h4><Link to='/earthscoop3'>UNITED NATIONS’ BRIDGE FROM KYOTO TO PARIS</Link></h4>
                                        </div>
                                        <div className='post-author'>
                                            <h6>- BY: DR. KAVIRAJ SINGH ( M D )</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className='post-wrapper alignleft mrt-20'>
                                    <div className='post-img mrr-20'>
                                        <img className="img-fluid" src={earthscoop6} alt="Earthood" id='' />
                                    </div>
                                    <div className='post-content'>
                                        <div className='post-titile'>
                                            <h4><Link to='/earthscoop6'>THE WORLD’S DRIVE TOWARDS CARBON NEUTRALITY</Link></h4>
                                        </div>
                                        <div className='post-author'>
                                            <h6>- BY: ARCHIT SRIVASTAVA</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Earthscoop6;

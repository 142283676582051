import React, { useEffect, useState } from 'react'
import linkedin from '../assets/images/team/linkedin.svg'
import Layout from '../components/layout'
import { getEarthoodiesCoreList, getEarthoodiesPowerList, getEarthoodiesTeamList } from '../Redux/Earthhoodies/action'
import { ProfileCard } from '../components/profileCard'
import { Link } from 'react-router-dom'
import { Fragment } from 'react'
import { Helmet } from 'react-helmet'

const Team = () => {
    const [state, setState] = useState([{
        power_house_list: [],
        core_list: [],
        team_list: [],
    }])
    const [teamList, setTeamList] = useState([])
    const [coreList, setCoreList] = useState([])
    const [powerHouseList, setPowerHouseList] = useState([])

    useEffect(() => {
        console.log("$$$$$$$$$$$$$$$$$$$$$");
        getEarthoodiesPowerList()((response) => {
            if (response && response.status) {
                setPowerHouseList(response?.data)
            }
        });
        getEarthoodiesTeamList()((response) => {
            if (response && response.status) {
                setTeamList(response?.data)
            }
        });
        getEarthoodiesCoreList()((response) => {
            if (response && response.status) {
                setCoreList(response?.data)
            }
        });
    }, [])
    console.log(
        teamList,
        coreList,
        powerHouseList,

    );
    return (
        <Fragment>
            <Helmet>
                <title>Earthood</title>
                <meta name="keywords" content="Earthood" />
                <meta name="description" content="Secure carbon offset credits and achieve net zero goals with us" />
                <meta property="og:url" content="https://www.earthood.in/" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Earthood" />
                <meta property="og:description" content="Secure carbon offset credits and achieve net zero goals with us" />
                <meta property="og:image" content="https://webapi.earthood.in/assets/assets/media/logos/earthood-logo.png" />
            </Helmet>
            <Layout>
                <div className='team'>
                    <div className='banner'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-sm-6'></div>
                                <div className='col-sm-6'>
                                    <h2><span>EARTHOODIES</span></h2>
                                    <p>"A group dedicated to inspiring global action on climate change through education, advocacy, and innovative solutions"
                                    </p>
                                    <p> Manish Negi</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className='powerhouse'>
                        <div className='container'>
                            <div className='row'>
                                <div className='title'>
                                    <h2><span>THE POWERHOUSE</span></h2>
                                </div>
                            </div>
                            <div className='members mt-20'>
                                <div className='row'>
                                    {powerHouseList && powerHouseList.map((item) =>
                                        <div className='col-lg-3 col-md-4 col-sm-6 col-12 res-mt20'>
                                            <div className='content-wrapper'>
                                                <div className='img-wrapper'>
                                                    <img src={item?.images} className="  img-fluid" alt='earthoodie_img' />
                                                    {item.url &&
                                                        <div className='overlay'>
                                                            <div className='linkedin-img'>
                                                                <Link to={item.url} target='_blank'> <img src={linkedin} className="  img-fluid" alt='earthoodie_img' /></Link>
                                                            </div>
                                                        </div>
                                                    }

                                                </div>
                                                <div className='content'>
                                                    <p className='title'>{item?.title}</p>

                                                    {item?.earthoodies_detail && (
                                                       <p> <div dangerouslySetInnerHTML={{ __html: item.earthoodies_detail }} /></p>
                                                    )}
                                                </div>

                                            </div>
                                        </div>
                                    )}

                                </div>
                            </div>
                        </div>
                        <div className='container mt-50'>
                            <div className='row'>
                                <div className='title'>
                                    <h2><span>THE CORE</span></h2>
                                </div>
                            </div>
                            <div className='members designation '>
                                <div className='row'>
                                    {coreList && coreList.map((item) =>
                                        <div className='col-lg-3 col-md-4 col-sm-6 col-12 mt-20'>
                                            <div className='content-wrapper'>
                                                <div className='img-wrapper'>
                                                    <img src={item?.images} className="  img-fluid" alt='earthoodie_img' />
                                                    {item.url &&
                                                        <div className='overlay'>
                                                            <div className='linkedin-img'>
                                                                <Link to={item.url} target='_blank'> <img src={linkedin} className="  img-fluid" alt='earthoodie_img' /></Link>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <div className='content'>
                                                    <p className='title'>{item.title}</p>
                                                    {item?.earthoodies_detail && (
                                                        <div className='small-text' dangerouslySetInnerHTML={{ __html: item.earthoodies_detail }} />
                                                    )}
                                                    {/* <p className='subtitle'>VP-Strategy & Growth</p> */}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>

                            </div>
                        </div>
                    </section>
                    <section className='powerhouse theteam'>
                        <div className='container'>
                            <div className='row'>
                                <div className='title'>
                                    <h2><span>THE TEAM</span></h2>
                                </div>
                            </div>
                            <div className='members designation'>
                                <div className='row'>
                                    {teamList && teamList.map((item) =>
                                        <div className='col-lg-3 col-md-4 col-sm-6 col-12  mt-20'>
                                            <div className='content-wrapper'>
                                                <div className='img-wrapper'>
                                                    <img src={item?.images} className="  img-fluid" alt='earthoodie_img' />
                                                    {item.url &&
                                                        <div className='overlay'>
                                                            <div className='linkedin-img'>
                                                                <Link to={item.url} target='_blank'> <img src={linkedin} className="  img-fluid" alt='earthoodie_img' /></Link>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <div className='content'>
                                                    <p className='title'>{item.title}</p>
                                                    {item?.earthoodies_detail && (
                                                       <p> <div className='small-text' dangerouslySetInnerHTML={{ __html: item.earthoodies_detail }} /></p>
                                                    )}
                                                    {/* <p className='subtitle'>Project Manager</p> */}
                                                </div>

                                            </div>
                                        </div>

                                    )}


                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </Layout>
        </Fragment>

    )
}

export default Team;

import React from 'react'
import { Button, Form } from 'react-bootstrap';
import Layout from '../../components/layout';
import send_logo from '../../assets/images/contact/send.png'
import india from '../../assets/images/contact/india.png'
import china from '../../assets/images/contact/china.png'
import uk from '../../assets/images/contact/uk.png'
import brazil from '../../assets/images/contact/brazil.png'
import korea from '../../assets/images/contact/korea.png'
import turkey from '../../assets/images/contact/turkey.png'
import mail from '../../assets/images/contact/email.svg'
import * as Yup from 'yup';
import { contactDetails } from '../../Redux/Contactus/actions';
import { useFormik } from 'formik';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';

const Contact = () => {
    const showToastMessage = (message) => {
        toast.success(message, {
            position: toast.POSITION.TOP_RIGHT
        });
    };
    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Required'),
        email: Yup.string().matches(/(\<|^)[\w\d._%+-]+@(?:[\w\d-]+\.)+(\w{2,})(\>|$)/, 'Enter a valid email')
            .required('Required'),
        // email: Yup.string()
        //     .required('email required'),
        message: Yup.string()
            .required('Required'),
        company: Yup.string()
            .required('Required'),
        phone_number: Yup.string().matches(/^[6-9]\d{9}$/, 'Enter a valid mobile number')
            .required('Required'),
        // phone_number: Yup.string()
        //     .matches(/^\d{10}$/, 'Phone number is not valid')
        //     .required('Phone number is required'),
    });
    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            message: "",
            company: "",
            phone_number: "",
        },
        validationSchema,
        onSubmit: (values) => {
            let formData = new FormData();
            formData.append("name", values.name);
            formData.append("email", values.email);
            formData.append("message", values.message);
            formData.append("company", values.company);
            formData.append("phone_number", values.phone_number);
            contactDetails({ formData })((response) => {
                if (response && response.status) {
                    showToastMessage('Successfully Submitted!')
                    formik.handleReset()
                } else {
                    console.log("##########", response?.response?.data?.errors?.name?.[0]);
                    formik.setErrors({
                        name: response?.response?.data?.errors?.name?.[0],
                        email: response?.response?.data?.errors?.email?.[0],
                        message: response?.response?.data?.errors?.message?.[0],
                        company: response?.response?.data?.errors?.company?.[0],
                        phone_number: response?.response?.data?.errors?.phone_number?.[0],
                    })

                }
            });
        },
    });
    console.log(formik.errors);
    return (
        <Fragment>
            <Helmet>
                <title>Earthood</title>
                <meta name="keywords" content="Earthood" />
                <meta name="description" content="Secure carbon offset credits and achieve net zero goals with us" />
                <meta property="og:url" content="https://www.earthood.in/" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Earthood" />
                <meta property="og:description" content="Secure carbon offset credits and achieve net zero goals with us" />
                <meta property="og:image" content="https://webapi.earthood.in/assets/assets/media/logos/earthood-logo.png" />
            </Helmet>
            <Layout>
                <div className='contact-us'>
                    <div className='banner'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-sm-6'></div>
                                <div className='col-sm-6'>
                                    <h2><span>CONTACT US</span></h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className='form-sec'>
                        <div className='container'>
                            <div className='form-content'>
                                <div className='row df-center' >
                                    <div className='col-lg-8'>
                                        <div className='form-wrapper'>
                                            <h6>Send us a Message</h6>
                                            <Form onSubmit={formik.handleSubmit}>
                                                <div className='row'>
                                                    <div className='col-lg-6'>
                                                        <Form.Group className="mb-3 ptb-10" controlId="formBasicEmail">
                                                            <Form.Control
                                                                className={formik.errors.name && 'error-border'}
                                                                name="name"
                                                                value={formik.values?.name}
                                                                onChange={formik.handleChange}
                                                                isValid={formik.touched.name && !formik.errors.name}
                                                                isInvalid={!!formik.errors.name}
                                                                type="text"
                                                                placeholder="Your Name"
                                                            />
                                                            {/* {formik.errors.name &&
                                                            <p className='error-content'>{formik.errors.name}</p>
                                                        } */}
                                                            <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <Form.Group className="mb-3 ptb-10" controlId="formBasicEmail">
                                                            <Form.Control
                                                                className={formik.errors.email && 'error-border'}
                                                                name="email"
                                                                value={formik.values?.email}
                                                                onChange={formik.handleChange}
                                                                type="email"
                                                                placeholder="Email Address"
                                                                isValid={formik.touched.email && !formik.errors.email}
                                                                isInvalid={!!formik.errors.email}
                                                            />
                                                            {/* {formik.errors.email &&
                                                            <p className='error-content'>{formik.errors.email}</p>
                                                        } */}
                                                            <Form.Control.Feedback type="invalid">{formik.errors.email}</Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <Form.Group className="mb-3 ptb-10" controlId="formBasicEmail">
                                                            <Form.Control
                                                                className={formik.errors.phone_number && 'error-border'}
                                                                name="phone_number"
                                                                value={formik.values?.phone_number}
                                                                onChange={formik.handleChange}
                                                                isValid={formik.touched.phone_number && !formik.errors.phone_number}
                                                                isInvalid={!!formik.errors.phone_number}
                                                                type="text"
                                                                placeholder="Phone"
                                                            />
                                                            {/* {formik.errors.phone_number &&
                                                            <p className='error-content'>{formik.errors.phone_number}</p>
                                                        } */}
                                                            <Form.Control.Feedback type="invalid">{formik.errors.phone_number}</Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <Form.Group className="mb-3 ptb-10" controlId="formBasicEmail">
                                                            <Form.Control
                                                                className={formik.errors.company && 'error-border'}
                                                                name="company"
                                                                value={formik.values?.company}
                                                                onChange={formik.handleChange}
                                                                isValid={formik.touched.company && !formik.errors.company}
                                                                isInvalid={!!formik.errors.company}
                                                                type="text"
                                                                placeholder="Company"
                                                            />
                                                            {/* {formik.errors.company &&
                                                            <p className='error-content'>{formik.errors.company}</p>
                                                        } */}
                                                            <Form.Control.Feedback type="invalid">{formik.errors.company}</Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                    <div className='col-12'>
                                                        <div className='btn-wrap'>
                                                            <Form.Group className="mb-3 ptb-10" controlId="exampleForm.ControlTextarea1">
                                                                <Form.Label>Message</Form.Label>
                                                                <Form.Control
                                                                    className={formik.errors.message && 'error-border'}
                                                                    name="message"
                                                                    value={formik.values?.message}
                                                                    onChange={formik.handleChange}
                                                                    isValid={formik.touched.message && !formik.errors.message}
                                                                    isInvalid={!!formik.errors.message}
                                                                    as="textarea"
                                                                    rows={5}
                                                                />
                                                                {/* {formik.errors.message &&
                                                                <p className='error-content'>{formik.errors.message}</p>
                                                            } */}
                                                                <Form.Control.Feedback type="invalid">{formik.errors.phone_number}</Form.Control.Feedback>
                                                            </Form.Group>

                                                            <Button variant="primary" type='submit'>
                                                                <img src={send_logo} className="" alt="Earthood" id='header_image' />
                                                            </Button>
                                                        </div>
                                                    </div>

                                                </div>


                                            </Form>
                                        </div>

                                    </div>
                                    <div className='col-lg-4'>
                                        <div className='address'>
                                            <h6>Contact information</h6>
                                            <ul>
                                                <li className='location'>Gurugram, Harayana</li>
                                                <li className='phone'><Link to='tel:+91 1244204599'> +91 1244204599</Link></li>
                                                <li className='location'>London, UK</li>
                                                <li className='phone'><Link to='tel:+4402034337677'> +44 02034337677</Link></li>
                                                <li className='email'><Link to='mailto:info@earthood.in'> info@earthood.in</Link></li>
                                                <li className='email'><Link to='mailto:esg@earthood.in'>esg@earthood.in</Link></li>
                                                {/* <li className='email'><Link to='mailto:partnerships@earthood.in'>partnerships@earthood.in</Link></li> */}
                                                <li className='email'><Link to='mailto:careers@earthood.in'>careers@earthood.in</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className='map'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='title'>
                                        <h3><span>OUR GLOBAL PRESENCE</span></h3>
                                    </div>
                                </div>
                            </div>
                            <div className='gpartners'>
                                <div className='row grid-5'>
                                    <div className='box'>
                                        <div className='img-wrap'>
                                            <img src={india} className="img-fluid" />
                                        </div>
                                        <div className='title'>
                                            <h6>INDIA(HO)</h6>
                                            <p>1203-1205, 12th floor,</p>
                                            <p>Tower B, Emaar Digital</p>
                                            <p> Greens, Sector 61, Golf </p>
                                            <p>Course Ext Road,</p>
                                            <p> Gurugram, Haryana 122011</p>

                                        </div>
                                    </div>
                                    <div className='box'>
                                        <div className='img-wrap'>
                                            <img src={brazil} className="img-fluid" />
                                        </div>
                                        <div className='title'>
                                            <h6>BRAZIL</h6>
                                            <p>Calçada das Margaridas</p>
                                            <p>163 / 02, Alphaville Barueri,</p>
                                            <p> Sao Paulo SP CEP  </p>
                                            <p>06453-038</p>
                                        </div>
                                    </div>
                                    <div className='box'>
                                        <div className='img-wrap'>
                                            <img src={china} className="img-fluid" />
                                        </div>
                                        <div className='title'>
                                            <h6>CHINA</h6>
                                            <p> 8-G163, Jinqiu Road, </p>
                                            <p>Baoshan District, Shanghai </p>
                                            <p>P.R.China </p>
                                        </div>
                                    </div>
                                    <div className='box'>
                                        <div className='img-wrap'>
                                            <img src={uk} className="img-fluid" />
                                        </div>
                                        <div className='title'>
                                            <h6>UK</h6>
                                            <p>CoTribe, 33 Hanworth Rd,</p>
                                            <p>Sunbury-on-Thames, </p>
                                            <p>London TW16 5DA</p>
                                        </div>
                                    </div>
                                    <div className='box'>
                                        <div className='img-wrap'>
                                            <img src={korea} className="img-fluid" />
                                        </div>
                                        <div className='title'>
                                            <h6>SOUTH KOREA</h6>
                                            <p>A-608, 1, </p>
                                            <p>Seonsuchongongwon-Ro, </p>
                                            <p>17 Beon-Gil, Namdong-Gu, </p>
                                            <p>Incheon, Republic of Korea</p>
                                        </div>
                                    </div>
                                    <div className='box'>
                                        <div className='img-wrap'>
                                            <img src={turkey} className="img-fluid" />
                                        </div>
                                        <div className='title'>
                                            <h6>TURKEY</h6>
                                            <p>AYAZAĞA MAH. MİMAR SİNAN SK.</p>
                                            <p> SEBA OFFICE NO: 21 D İÇ</p>
                                            <p>KAPI NO: 45 SARIYER/ </p>
                                            <p>İSTANBUL</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='map-wrapper'>
                                    <div class="india d-none d-lg-block">
                                        <div className='popup'><span><img src={india} /></span>India</div>
                                    </div>
                                    <div class="china d-none d-lg-block">
                                        <div className='popup'><span><img src={china} /></span>China</div>
                                    </div>
                                    <div class="korea d-none d-lg-block">
                                        <div className='popup'><span><img src={korea} /></span>South Korea</div>
                                    </div>
                                    <div class="uk d-none d-lg-block">
                                        <div className='popup'><span><img src={uk} /></span>UK</div>
                                    </div>
                                    <div class="brazil d-none d-lg-block">
                                        <div className='popup'><span><img src={brazil} /></span>Brazil</div>
                                    </div>
                                    <div class="turkey d-none d-lg-block">
                                        <div className='popup'><span><img src={turkey} /></span>Turkey</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p className='mail-details'>Drop us an email at <span><img src={mail} className="img-fluid" /></span><a href="mailto:info@earthood.in">info@earthood.in</a></p>

                    </section>
                </div>
            </Layout>
        </Fragment>

    )
}

export default Contact;

import React from 'react'
import Layout from '../../components/layout';
import earthscoop12 from '../../assets/images/earthscoop/12.1.jpg';
import earthscoop2 from '../../assets/images/earthscoop/2.png'
import earthscoop3 from '../../assets/images/earthscoop/3.png'
import earthscoop6 from '../../assets/images/earthscoop/6.png'
import { Link } from 'react-router-dom';
const Earthscoop12=() => {
    return (
        <Layout>
            <div className='inner-earthscoop'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-8'>
                            <div class="content">
                                <img className="img-fluid" src={earthscoop12} alt="Earthood" id='' />
                                <div className='title'><h3><span>WILL CORONAVIRUS PANDEMIC SAVE THE WORLD FROM AIR POLLUTION AND CLIMATE CHANGE?</span></h3>
                                    <h6>-  BY: DR. KAVIRAJ SINGH ( M D )</h6>
                                </div>
                                <div className='pd-left'>
                                    <p>There are some noticeable changes going on amid the recent Coronavirus outbreak. In the past 2 months, there is a significant dropdown in the pollution rates in major economies across the world. The Global Scenario According to an ENDS report, Data collected from the European Space Agency (ESA)'s Sentinel-5P satellite, nitrogen dioxide (NO2) emitted from vehicles, power plants and industrial facilities dropped significantly following the strict quarantine measures in China that were put in place in January 2020. Countries like Italy, Spain, and US are observing a gradual drop in pollution levels.</p>
                                    <p>Pollution levels in China before and after lockdown Pollution levels in Seoul before and after lockdown Pollution levels in Milan before and after lockdown</p>
                                    <p>Also the pollution fog cover has removed from major cities like New York, Seattle, Atlanta, and Los Angeles. India is witnessing a significant drop in carbon emissions during the 21 day lockdown period. Global carbon emissions are dropping down!</p>
                                    <p>For the first time in history, a pandemic has brought down major global economies putting industries, transport and businesses to a standstill. Economic activity has slowed down and stock markets have tumbled close by the falling carbon emissions. It's the correctly inverse of the drive towards a decarbonized, sustainable economy that many have been supporting for years.</p>
                                    <p>This isn't the first run through an epidemic that has left its mark on atmospheric carbon dioxide levels. Since the beginning, the spread of illnesses has been connected to bring down atmospheric pollution – much before the Industrial Age.</p>
                                    <p>Julia Pongratz, professor for physical geography and land-use frameworks at the Department of Geography at the University of Munich, Germany, found that epidemics, for example, the Black Death in Europe in the fourteenth century, and the epidemics of ailments, for example, smallpox carried to South America with the appearance of the Spanish conquistadors in the sixteenth century, both left unobtrusive checks on atmospheric CO2 and NO2 levels.</p>
                                    <p>The World Health Organization portrays NO2 as "a lethal gas which causes critical inflammation of the airways" at concentrations over 200 micrograms for every cubic meter. Pollution particles may likewise be a vector for pathogens, just as worsening existing medical issues. The WHO is presently researching whether airborne pollution particles might be a vector that spreads Covid-19 and makes it increasingly virulent.</p>
                                    <p>There are other, less immediate ways that Coronavirus could have a more drawn out term sway on maintainability, as well. One is pushing the climate emergency off individuals' psyches, as the additionally squeezing worry of promptly sparing lives comes first.</p>
                                    <p>The other is basically making climate discussion tougher as mass events are delayed. Greta Thunberg has encouraged for digital activism to replace mass physical protests due to the Coronavirus outbreak, while the greatest climate occasion of the year, COP26, is presently still planned to be held in November. COP26 is required to draw 30,000 delegates from around the globe. The conference coordinators are as yet moving in the direction of facilitating the occasion in Glasgow, a COP26 spokesperson says, in spite of the fact that they are in visit contact with the UN and the present COP president in Chile, among different accomplices.</p>
                                    <p>The OECD predicts that the global economy will continue to grow in 2020, albeit growth predictions have fallen considerably as a result of the Coronavirus outbreak. However, even with this recuperation, Researchers like Glen Peters of the Center for International Climate and Environment Research in Oslo have noticed that in general, 2020 may observe a drop in global emissions of 0.3% – less articulated than the accident of 2008-09, yet additionally with an open door for less rebound if efforts to stimulate the economy are engaged towards sectors, like Clean Energy.</p>
                                    <p>The Indian Scenario Nitrogen dioxide (NO2) is produced from car engines, power plants and other industrial plants and is thought to worsen respiratory ailments, for example, asthma.</p>
                                    <p>While not a greenhouse gas itself, the pollutant starts from industrial sectors that are responsible for an enormous portion of India's carbon emissions and that drive global warming. Let’s take an example of the National capital – Delhi

                                        Around 10.9 million vehicles ply on the road daily in the National Capital of Delhi. Central Pollution Control Board (CPCB), the administrative body in India, announced that the Air Quality Index (AQI) has truly improved in only the recent days after the crown lockdown. On nineteenth March 2020 every day of declaration for "Janta Curfew" (complete lockdown) in India, the AQI of station introduced at Indira Gandhi Airport Delhi detailed AQI of163 while on 26th March 2020 the AQI of a similar station was accounted for 55. Thus, the air contamination observing station in Dwarka Sector 8, Delhi recorded AQI as 240 on nineteenth March 2020 which significantly went down to 93 on 26th March 2020.</p>
                                    <p>While, at present, the AQI in Gurgaon, that is, viewed as one of the most contaminating urban communities in the NCR region is 71. The general AQI of Delhi has altogether improved and keeps on improving while NCR is in lockdown until 21st March 2020 (Fig 7). It is obviously delineated in figure 7 that AQI of Delhi-NCR beginning to change towards better after the lockdown began on 22nd March 2020 Improved air nature of Delhi is only a model yet the pattern is pretty much same every other city if India and over the globe.</p>
                                    <p>Governments and financial leaders are, as of now, considering recovery packages for an economy so gravely hit by the infection. Shockingly, these decisions will be the most significant decision around climate change. In the event that investments to launch the paralyzed economy are coordinated into high carbon assets and industries, we will bolt out our present potential to twist the curve of emissions this decade. Then again, with loan costs at record-breaking low, political and financial leaders currently have an unprecedented chronicled chance to quicken the energy transition putting us onto a protected way towards a 50% reduction of emissions by 2030.</p>
                                    <p>In the midst of tensions that the coronavirus pandemic could wreck key climate talks this year, remembering the Convention for Biodiversity and the UN Climate Change Conference (COP26), 2020 is being hailed as a "critical year" to encourage decision-makers to make a move.</p>

                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='sticky-bar positionsticky'>
                                <div className='post-title-wrap'>
                                    <Link to=''>POPULAR POSTS</Link>
                                </div>
                                <div className='post-wrapper alignleft mrt-20'>
                                    <div className='post-img mrr-20'>
                                        <img className="img-fluid" src={earthscoop2} alt="Earthood" id='' />
                                    </div>
                                    <div className='post-content'>
                                        <div className='post-titile'>
                                            <h4><Link to='/earthscoop2'> Key Stakeholder’s Perspective On Esg</Link></h4>
                                        </div>
                                        <div className='post-author'>
                                            <h6>- AVINASH KUMAR (EXECUTIVE DIRECTOR – ESG & NET ZERO)</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className='post-wrapper alignleft mrt-20'>
                                    <div className='post-img mrr-20'>
                                        <img className="img-fluid" src={earthscoop3} alt="Earthood" id='' />
                                    </div>
                                    <div className='post-content'>
                                        <div className='post-titile'>
                                            <h4><Link to='/earthscoop3'>UNITED NATIONS’ BRIDGE FROM KYOTO TO PARIS</Link></h4>
                                        </div>
                                        <div className='post-author'>
                                            <h6>- BY: DR. KAVIRAJ SINGH ( M D )</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className='post-wrapper alignleft mrt-20'>
                                    <div className='post-img mrr-20'>
                                        <img className="img-fluid" src={earthscoop6} alt="Earthood" id='' />
                                    </div>
                                    <div className='post-content'>
                                        <div className='post-titile'>
                                            <h4><Link to='/earthscoop6'>THE WORLD’S DRIVE TOWARDS CARBON NEUTRALITY</Link></h4>
                                        </div>
                                        <div className='post-author'>
                                            <h6>- BY: ARCHIT SRIVASTAVA</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Earthscoop12;

import React from 'react'
import Layout from '../../components/layout';
import earthscoop8_img1 from '../../assets/images/earthscoop/8.1.jpg';
import earthscoop8_img2 from '../../assets/images/earthscoop/8.2.jpg';
import earthscoop8_img3 from '../../assets/images/earthscoop/8.3.jpg';
import earthscoop2 from '../../assets/images/earthscoop/2.png'
import earthscoop3 from '../../assets/images/earthscoop/3.png'
import earthscoop6 from '../../assets/images/earthscoop/6.png'
import { Link } from 'react-router-dom';
const Earthscoop8=() => {
    return (
        <Layout>
            <div className='inner-earthscoop'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-8'>
                            <div class="content">
                                <img className="img-fluid" src={earthscoop8_img1} alt="Earthood" id='' />
                                <div className='title'><h3><span>THE FUTURE OF CARBON MARKETS POST ECONOMIC SLOWDOWN</span></h3>
                                    <h6>- BY: ARCHIT SRIVASTAVA</h6>
                                </div>
                                <div className='pd-left'>
                                    <p>The big question is: WHAT NEXT? COVID-19 has been much discussed about already. Conferences and meetings to provide impetus to raised climate ambition like Innovate4Climate in May at Barcelona and COP26 at Glasgow in November have already announced a long postponement, early this year. The impact COVID had on the emissions, air pollution, economy and much more has been established and proven.
                                    </p>
                                    <p>We debate and arrive at a more comforting argument that the Carbon Markets are ‘insured’ by the effects of the pandemic and the global economic slowdown. Read on and learn how, as we analyse the compliance based and voluntary carbon markets with the latest developments!

                                    </p>
                                    <h5>Carbon Market Behaviour in 2020</h5>
                                    <p>Compliance Markets: EUETS and KETS - Two largest compliance-based markets globally are EUETS and KETS occupying the top spots respectively, based on volume and prices. Here is what happened in these markets since the pandemic hit the world:</p>
                                    <p>Discussing the Korean markets first, in January 2020, KAU19 prices fluctuated between EUR 24.5 and EUR 29 but stabilized towards the second half of the month. Allowances in KETS maintained their prices, despite frequent fluctuations, averaging at EUR 29 per tonne in February. From mid-March, transactions at EUR 29.5 per tonne and above continued, accompanied by an increase in trading volume, which was more than double as compared to the previous month. In April, KAU19 prices repeatedly fluctuated between EUR 28 and EUR 29 as EUR 29.5 acted as a resistance level for a while. Again, trade volumes in April increased by 2.2 times compared to March. Allowances remained resistant and strong in April maintaining prices at above EUR 29.5 but started to decline from the fourth week as a stream of buying and selling orders continued, closing the month at EUR 29. In April 2020, prices for KAU20 recorded an all-time high of EUR 31.5 per tonne.</p>
                                    <p>Figure-1 as illustrated below, summarizes the overall impact from January to May 2020 in KETS after due analysis of trading data from Global KRX Market, South Korea – prices held steady; trading volumes kept increasing monthly. Amazed yet?</p>
                                </div>
                            </div>
                            <div class="content">
                                <img className="img-fluid" src={earthscoop8_img2} alt="Earthood" id='' />

                                <div className='pd-left'>
                                    <p>Meanwhile for EUETS, "It is not going to ruin the market in the same way, the ETS is not going to go down a negative spiral like in 2009." said Coralie Laurencin, a senior director at IHS Markit, an information provider that closely tracks commodity markets. Her optimism is driven by Market Stability Reserve (MSR), a mechanism introduced to reform the ETS at the start of 2019. By gradually removing the oversupply of carbon allowances that built up over the past decade, it already helped lift prices from around EUR 15 per tonne in mid-2018 to as high as EUR 30 per tonne in 2019. That is something, isn’t it?</p>
                                    <p>The plunge in EUA prices in March was partly driven by lower economic activity and power demand, but analysts say so-called non-compliance investors, who trade in the market without an obligation, also sold off allowances in droves, amplifying the effect. EU Allowance prices traded in a range of roughly EUR 22 – EUR 26 between January 1 and March 15 but fell sharply to below EUR 15 in late March as most European governments curbed movement of people. EUA futures contracts for December delivery, traditionally the most liquid contract, fell as low as EUR 14.34 per tonne in March, down 45% from a February high of EUR 25.86 per tonne. An illustration is show as below in Figure 2:</p>
                                </div>
                            </div>
                            <div class="content">
                                <div className='pd-left'>
                                    <h5>Voluntary Markets: VCS</h5>
                                    <p>From VERRA, we understand that about 100 million VCUs were issued in 2019, which was more than twice as many ever issued in a single year, indicating that climate action is increasing and that voluntary carbon offsets, including those from the VCS, are part of the action. Agriculture, Forestry and Land Use related issuances amounted to more than 70% of total, indicating that there is a new paradigm shift happening in terms of demands with CORSIA on the brink, as illustrated in Figure 3 below. VERRA also states that the registry has not yet seen any impact of the pandemic on the trading of VCUs as issuances have continued a slight upward trend from last year - 42,822,117 VCUs in Q1 2020 versus 41,013,130 in Q4 of 2019.</p>
                                    <p>A total of 17.9 million credits were retired in Q4 2019, and already in the first 3 months till March 2020, about 14.4 million credits have already been retired.</p>
                                </div>
                            </div>
                            <div class="content">
                                <img className="img-fluid" src={earthscoop8_img3} alt="Earthood" id='' />

                                <div className='pd-left'>
                                    <p>Looking Ahead</p>
                                    <p>Going forward, The European Commission is preparing to present plans for a higher 2030 target to lower emissions later this year, which will increase its current 40% goal to 50% - 55%. The expected drop in emissions because of lower economic activity during 2019 will make it easier to argue for a more stringent emissions cut. Constant developments on the European Green Deal during the times of coronavirus have shown that the countries have shown raised ambition for a resilient future. On March 4, the proposal for a European Climate Law to ensure a climate neutral European Union by 2050 was discussed. On 10th and 11th March, adoption of the European Industrial Strategy, a plan for a future-ready economy and the proposal of a Circular Economy Action Plan focusing on sustainable resource use was discussed. On 20th May, EU Biodiversity strategy for 2030 was presented. The ultimate objective being to make all of EU as 100% climate neutral by 2050.</p>
                                    <p>We are also noticing a series of events where Oil & Gas Companies are pledging to go carbon neutral and have taken aggressive targets by 2030, 2040 and some even for 2050. After the likes of Repsol, Total, BP, ENI, Kosmos, Exxon Mobil and Shell, Lundin also joins the league and targets to become Carbon Neutral soon. Food for thought: for any oil and gas company, is it possible? 100% Carbon Neutrality? Nevertheless, irrespective of the extent of applicability, this has led to an increased demand from the voluntary markets.</p>
                                    <p>Nature Based Solutions (NBS), Blue Carbon, mangrove projects are the talk of the town which has attracted interest from specially the likes of Airlines which are committed to reduce their carbon footprint under CORSIA. CORSIA deals with carbon emissions from air travels, which accounts for approximately 2% of the world GHG emissions. CORSIA, will take effect from 2021 and the measure will be voluntary for the first 6 years. CORSIA, will therefore promote a carbon-neutral growth of the aviation sector from 2021. Under the measure, an airline can buy carbon credits from various programs like CDM, VCS and GS to offset any increase in their emissions than the baseline year i.e. 2020.</p>
                                    <p>South Korea raising their ambitions had said to reduce their emissions by 30% below BAU till 2020: (Copenhagen Accord Target) and by 37% below BAU (536 MtCO2e) till 2030: , which represents a 22% reduction below 2012 GHG levels (NDC) and about 38 million international credits may be used towards achieving this goal (2030 GHG mitigation roadmap). Phase 3 of the KETS starting from 2021, is also expected to see a substantial increase in the auctions going up from 3% to about 10% (estimated) as the inclusion thresholds stand as for a company at >125,000 tCO2/year, and for a facility at >25,000 tCO2/year. In Phase 3, offsets will continue to be allowed in limited fashion. Further rules and conditions are yet to be released. Revenue reported to be recorded by the ETS is about 200 million USD. GHG Target Management Systems (TMS) and government interventions keep the scheme under check and hence mitigates the risk of prices heading into a slump, specially after South Korea managed the containment of coronavirus spread in the region, exemplarily.</p>
                                    <p>While emissions are temporarily dropping, they are likely to bounce back as soon as economic activities pick up again. Drawing lessons from the past, our governments should do what they didn’t do after the 2008 financial crash: they should make sure that stimulus packages support the green transition instead of propping up the biggest polluters and keeping dependence on fossil fuels locked in. Climate Change is apparently an even bigger issue than COVID-19 and the Economic Slowdown. Carbon markets are here to stay!</p>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='sticky-bar positionsticky'>
                                <div className='post-title-wrap'>
                                    <Link to=''>POPULAR POSTS</Link>
                                </div>
                                <div className='post-wrapper alignleft mrt-20'>
                                    <div className='post-img mrr-20'>
                                        <img className="img-fluid" src={earthscoop2} alt="Earthood" id='' />
                                    </div>
                                    <div className='post-content'>
                                        <div className='post-titile'>
                                            <h4><Link to='/earthscoop2'> Key Stakeholder’s Perspective On Esg</Link></h4>
                                        </div>
                                        <div className='post-author'>
                                            <h6>- AVINASH KUMAR (EXECUTIVE DIRECTOR – ESG & NET ZERO)</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className='post-wrapper alignleft mrt-20'>
                                    <div className='post-img mrr-20'>
                                        <img className="img-fluid" src={earthscoop3} alt="Earthood" id='' />
                                    </div>
                                    <div className='post-content'>
                                        <div className='post-titile'>
                                            <h4><Link to='/earthscoop3'>UNITED NATIONS’ BRIDGE FROM KYOTO TO PARIS</Link></h4>
                                        </div>
                                        <div className='post-author'>
                                            <h6>- BY: DR. KAVIRAJ SINGH ( M D )</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className='post-wrapper alignleft mrt-20'>
                                    <div className='post-img mrr-20'>
                                        <img className="img-fluid" src={earthscoop6} alt="Earthood" id='' />
                                    </div>
                                    <div className='post-content'>
                                        <div className='post-titile'>
                                            <h4><Link to='/earthscoop6'>THE WORLD’S DRIVE TOWARDS CARBON NEUTRALITY</Link></h4>
                                        </div>
                                        <div className='post-author'>
                                            <h6>- BY: ARCHIT SRIVASTAVA</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Earthscoop8;

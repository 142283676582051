import React from 'react'
import Layout from '../../components/layout';
import earthscoop9 from '../../assets/images/earthscoop/9.jpg'
import earthscoop9_img2 from '../../assets/images/earthscoop/9.1.jpg'
import earthscoop2 from '../../assets/images/earthscoop/2.png'
import earthscoop3 from '../../assets/images/earthscoop/3.png'
import earthscoop6 from '../../assets/images/earthscoop/6.png'
import { Link } from 'react-router-dom';

const Earthscoop9=() => {
    return (
        <Layout>
            <div className='inner-earthscoop'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-8'>
                            <div class="content">
                                <img className="img-fluid" src={earthscoop9} alt="Earthood" id='' />
                                <div className='title'><h3><span>A WAKE-UP CALL</span></h3>
                                    <h6>-  BY AVINASH KUMAR</h6>
                                </div>
                                <div className='pd-left'>
                                    <p>In April 2022, The Intergovernmental Panel on Climate Change, an intergovernmental body of the United Nations released its third installment of its Sixth Assessment Report (AR6). The report, as prepared by the IPCC Working Group III (WG-III) focuses on the mitigation of climate change or steps required to control global warming. </p>
                                    <p>Summarizing the key findings from the report, it is found that climate related events like extreme heat, heavy rainfall, fire conditions, and droughts are becoming more frequent and severe. Carbon dioxide is, and will continue to be the dominant cause of global warming under all greenhouse gas emissions scenarios, and all these factors are contributing to taking the world closer to 1.5 degrees Celsius warming. Here is a comparison of the possible scenarios with change in temperature:</p>
                                </div>
                            </div>
                            <div class="content">
                                <img className="img-fluid" src={earthscoop9_img2} alt="Earthood" id='' />

                                <div className='pd-left'>
                                    <p>One of the major inferences of this assessment report is that many net-zero targets are ambiguously defined, and the policies needed to achieve them are not yet in place. This gives the interpretation that targets defined by developed countries and developing economies to become net-zero by mid-century are either not properly aligned with SBTi or if they are, no substantial progress has been achieved so far.v</p>
                                    <p>Achieving the Net-zero target of CO2 in the industrial sector is challenging, but possible. Industries that are the frontrunners in this crucial race will have to understand and define the roadmap for decarbonization. Energy efficiency will continue to be important and then comes the role of reduced materials demand, material efficiency, and circular economy solutions which can lower the need for primary production. Without optimization and reduction strategies, there is no point in switching to the next step of opting for new technologies and processes which include using low to zero GHG energy carriers and feedstocks (e.g., electricity, hydrogen, biofuels, and carbon dioxide capture and utilization (CCU) to provide carbon feedstocks).</p>
                                    <p>Following the right roadmap, the option of technological changes is of immense importance, and herein carbon capture and storage (CCS) will be required to mitigate the CO2 emissions. Technological and process-related options require significant scaling up of electricity, hydrogen, recycling, CO2, and other infrastructure, as well as phase-out or conversion of existing industrial plants. While improvements in the GHG intensities of major basic materials have nearly stagnated over the last 30 years, analysis of historical technology shifts and newly available technologies indicate these intensities can be significantly reduced by mid-century..Following the right roadmap, the option of technological changes is of immense importance, and herein carbon capture and storage (CCS) will be required to mitigate the CO2 emissions. Technological and process-related options require significant scaling up of electricity, hydrogen, recycling, CO2, and other infrastructure, as well as phase-out or conversion of existing industrial plants. While improvements in the GHG intensities of major basic materials have nearly stagnated over the last 30 years, analysis of historical technology shifts and newly available technologies indicate these intensities can be significantly reduced by mid-century..</p>
                                    <p>The transition to net-zero emissions will require a substantial change in the corporate thought leadership. Right from estimating precise GHG emissions to offsetting residual emissions, key steps of optimization, reductions, and technology upgradation need a thoughtful, focused, sector-specific & tailored approach. If global greenhouse gas emissions are halved by 2030 and “net zero” is achieved by 2050, global warming can be stopped. Need of an hour requires the world leaders, the private sector, and individuals to act together with urgency and do everything it takes to protect our planet and our future in this decade and beyond.v</p>
                                    <p>Solving our greatest environmental challenges will require profound changes in business policies and practices. But it turns out that your personal actions can also help.</p>
                                    <h5>Five Tips for YOUR Contribution</h5>
                                    <p>There are solutions you can adopt in your daily life to reduce your personal impact on the environment. Here are 5 ways in which you can limit your ecological footprint:</p>
                                    <ul>
                                        <li>Opt for a green supplier: by opting for a 100% renewable energy supplier, you participate in a greener world while saving on your electricity bills.</li>
                                        <li>Adopt recycling: by giving a second life to your waste, you are actively participating in the ecological transition. Think about recycling all sorts of   items, including: glass, paper, cardboard, aluminium, and plastic;</li>
                                        <li> Reduce your digital footprint: by adopting the right habits, you can limit the carbon footprint linked to the use of your electronic equipment;</li>
                                        <li> Choose ecological transport: by opting for public transport or an electric bicycle or an electric car, you can limit the environmental impact of your   travels;</li>
                                        <li>Offset your carbon footprint: by purchasing carbon offsets or paying a premium to the supplier for offsetting your emissions, you are helping to limit greenhouse gas emissions into the atmosphere.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='sticky-bar positionsticky'>
                                <div className='post-title-wrap'>
                                    <Link to=''>POPULAR POSTS</Link>
                                </div>
                                <div className='post-wrapper alignleft mrt-20'>
                                    <div className='post-img mrr-20'>
                                        <img className="img-fluid" src={earthscoop2} alt="Earthood" id='' />
                                    </div>
                                    <div className='post-content'>
                                        <div className='post-titile'>
                                            <h4><Link to='/earthscoop2'> Key Stakeholder’s Perspective On Esg</Link></h4>
                                        </div>
                                        <div className='post-author'>
                                            <h6>- AVINASH KUMAR (EXECUTIVE DIRECTOR – ESG & NET ZERO)</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className='post-wrapper alignleft mrt-20'>
                                    <div className='post-img mrr-20'>
                                        <img className="img-fluid" src={earthscoop3} alt="Earthood" id='' />
                                    </div>
                                    <div className='post-content'>
                                        <div className='post-titile'>
                                            <h4><Link to='/earthscoop3'>UNITED NATIONS’ BRIDGE FROM KYOTO TO PARIS</Link></h4>
                                        </div>
                                        <div className='post-author'>
                                            <h6>- BY: DR. KAVIRAJ SINGH ( M D )</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className='post-wrapper alignleft mrt-20'>
                                    <div className='post-img mrr-20'>
                                        <img className="img-fluid" src={earthscoop6} alt="Earthood" id='' />
                                    </div>
                                    <div className='post-content'>
                                        <div className='post-titile'>
                                            <h4><Link to='/earthscoop6'>THE WORLD’S DRIVE TOWARDS CARBON NEUTRALITY</Link></h4>
                                        </div>
                                        <div className='post-author'>
                                            <h6>- BY: ARCHIT SRIVASTAVA</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Earthscoop9;

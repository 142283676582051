import React from 'react'
import { useNavigate } from 'react-router-dom';
import Footer from './header/footer';
import Header from './header/header';

const Layout=({ children }) => {
    const navigate=useNavigate();

    const handleNavigateStory=() => {
        if (window.location.pathname==="/") {
            // navigate("/")
            alert('safd')
            let a=document.getElementById("navStory")
            a.setAttribute("href", "#our-story")
            const section=document.getElementById("our-story");
            if (section) {
                const topOffset=section.offsetTop;
                window.scrollTo({ top: topOffset, behavior: "smooth" });
            }
        } else {
            navigate("/")

            setTimeout(() => {

                const section=document.getElementById("our-story");
                if (section) {
                    const topOffset=section.offsetTop;
                    window.scrollTo({ top: topOffset, behavior: "smooth" });
                }
            }, 20);

        }
    }


    return (
        <React.Fragment>
            <Header
                handleNavigateStory={handleNavigateStory}
            />
            <main style={{ marginTop: "57px" }}>
                {children}
            </main>
            <Footer handleNavigateStory={handleNavigateStory} />
        </React.Fragment>
    )
}

export default Layout;
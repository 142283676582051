import React from 'react'
import Layout from '../../components/layout';
import earthscoop3_img1 from '../../assets/images/earthscoop/fig1.png';
import earthscoop3_img2 from '../../assets/images/earthscoop/4.png';
import earthscoop2 from '../../assets/images/earthscoop/2.png'
import earthscoop3 from '../../assets/images/earthscoop/3.png'
import earthscoop6 from '../../assets/images/earthscoop/6.png'
import { Link } from 'react-router-dom';
const Earthscoop4=() => {
    return (
        <Layout>
            <div className='inner-earthscoop'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-8'>
                            <div class="content">
                                <img className="img-fluid" src={earthscoop3_img1} alt="Earthood" id='' />
                                <div className='title'><h3><span>THE POSITIVE SIDE OF COVID-19: REDUCTION IN CO2 EMISSIONS</span></h3>
                                    <h6>- BY: DR. KAVIRAJ SINGH ( M D )</h6>
                                </div>
                                <div className='pd-left'>
                                    <p>The world emits about 36 billion tons of CO2 per annum and there has been a steady increase in these emissions during the last few decades. Global energy-related CO2 emissions grew by 1.7% in 2018 to reach a historic high at 33.1 Gt CO2. It was the highest rate of growth since 2013, and 70% higher than the average increase since 2010. Currently, China is the biggest CO2 emitter in the world with a contribution of 25%, followed by USA (15%), EU 10%, India (7%) and Russia (5%). Historically, USA has 25% of the accumulated emission the entire world has generated till date since the beginning of Industrial Era. The concentration of CO2 in the atmosphere which had been hovering around 200-250 ppm since millions of years in the past, has a steep and sudden increase to 440 ppm during last 50 years till 2019. All these anthropogenic emissions have led to a rise in the Earth’s average temperature by 1.1 oC till 2018 and this continues to increase further.
                                    </p>
                                    <p>India’s total GHG emissions in 2014 were 3,202 million metric tons of carbon dioxide equivalent (MtCO2e). In India, 68.7% percent of GHG emissions come from the energy sector, followed by agriculture (19.6%), industrial processes (6.0%), land-use change and forestry (3.8%), and waste (1.9%) (Figure 2).
                                    </p>
                                    <p>Electricity/heat generation and transport sectors account for two-thirds of the global GHG emissions and the world emitted about 8040 million tons of CO2 from the transportation sector in 2017. Out of this, almost 75% of the emissions are attributable to road transport and increasing at a growth rate of about 2% per annum since 2000. Transportation emissions contribute to about a sixth of the total GHG emissions, in Asia. India and China have increased their emissions associated with road transport by almost 4 times between 2000 to 2019. India has increased its total CO2 emissions by four folds since 1990. The total CO2 emissions in 1990 were 529 m tCO2 which have increased to 2161 mtCO2 in 2017.
                                    </p>
                                    <p>The airline operations in 2019, which helped almost 4.5 billion passengers globally, amounted to ~915 million tons of CO2. The global aviation sector generates little more than 2% of the total anthropogenic emissions of our planet and in the transportation sector, airlines contributes about 12% of those CO2 emissions. It’s not just the CO2 emissions, generated by airplanes, which contributes to global warming, but aviation also adds to the formation of water vapor, nitrous oxide, nitrogen dioxide, particulates and other compounds like Sulphur Oxide and Carbon Monoxides which also contribute to climate change.</p>
                                </div>
                            </div>
                            <div class="content">
                                <img className="img-fluid" src={earthscoop3_img2} alt="Earthood" id='' />

                                <div className='pd-left'>
                                    <p>In India, transportation sector consumes about 70% of the total Diesel sales made every year. The share of diesel consumed by cars, utility vehicles and 3-wheelers is highest at 28.48%. Out of this, private cars and UVs account for 13.15%, commercial cars and UVs 8.94% and 3-wheelers about 6.4% of the total consumption. Trucks (HCV/LCV) account for only 28.25% of the diesel consumption. Buses consume about 9.55% and railways about 3.24%. In contrast to diesel, out of the total petrol sold in the country, 99.6% is consumed by the transportation sector alone. 61.42% of petrol is consumed for by two-wheelers while cars use 34.33% followed by 3-wheelers at 2.34%. It is also important to note that it is not just the CO2 coming out of fossil fuel burning in transportation sector contributing in global warming but the sector also emits non-CO2 pollutants which are also climate change drivers. These include Methane, Volatile Organic Compounds (VOCs), Nitrogen Oxides (NOx), Sulphur Dioxide (SO2), Carbon Monoxide (CO), F-gases, black carbon, and non-absorbing aerosols.</p>
                                    <p>The government of India in order to limit the COVID-19 community spread has announced a complete lockdown in the whole country. PM Narendra Modi declared a three-week nationwide lockdown starting from the 25th March 2020 which was extended and is currently ongoing with the situation to be reviewed in the first week of May. The lockdown clearly meant to cease all forms of movement and transportation including public and private, both. The impact of COVID-19 on the transportation sector has already been witnessed in the first two weeks of March even before the lockdown was announced. One of the leading oil company in India had reported that the India's fuel demand has dropped by a steep 10-11 per cent in the first two weeks of March as a result of cancellation of flights and reduction in logistical and industrial activity because of precautionary measures people started against COVID-19.</p>
                                    <p>Experts are predicting that the Global oil demand is expected to fall by a record 9.3 mb/d year in 2020, with the world witnessing crude oil being traded in negative figures for the first time ever. The impact of containment measures in 187 countries and territories has brought mobility almost to a halt which is expected to bring the demand in April 2020 to 29 mb/d which is down to a level last seen in 1995.
                                    </p>
                                    <p>India has emitted about 2161.6 million tons of CO2 in the year 2017 out of which 291.4 m tons of CO2 came from transportation sector mainly includes road transportation, contributing about 265.7 m tones. Therefore, it is estimated that India emits about 22 million tons of CO2 into the atmosphere per month and this does not include the emissions from aviation sector and other mode of transportation like rail, metros etc. Expert believes that India has certainly avoided more than 22 million tons of CO2 already, that would have been emitted into Earth’s environment in a ‘no lockdown’ situation. This conclusion can very well be corroborated with the reports of clear skies and wild animals are roaming around on the roads in many parts of the country.
                                    </p>
                                    <p>The COVID-19 lockdown measures which are currently in place in almost all major economies of the world have already slowed down the rate of GHG emissions into the atmosphere. It is not just the emissions from transportation, but the virus has impacted almost all major sectors like process industries, hospitality and aviation etc. which have been the major source of CO2 emissions and the present situation seems to stay for a while. Experts would certainly be busy in coming months to assess the impact of sudden change on GHG emissions because of the current unusual circumstances.</p>
                                    <p>This pandemic seems to be our own weird contribution towards the goals of ‘Paris Agreement’ and the nature is acting its own way in the fight against climate change. The government of India is also a party to the Paris Agreement which is the first international agreement to extend mitigation obligations to all countries, both developed and developing. The Agreement was ratified in record pace and came into force on the 4 November 2016 for the GHG mitigation actions from 2020 onward. There are 197 signatories to the Paris Agreement of which 188 countries contributing more than 87% of the global emissions have ratified the agreement. However, United State of America has withdrawn its participation from the agreement. India has become a member of Paris Agreement and agreed to reduce the rate of increase its emissions/GDP by 33-35% below the levels of 2005.</p>
                                    <p>Exemplary COVID-19 precautionary and reactive measures are the glimpses which indicate if all the countries genuinely unite to fight against climate change, they can bring a big delta in the distorted Earth Science. A global paradigm shift is now required more than ever for the industries and sectors which are heavy guzzlers of energy. Thereby we learn to co-exist only by appreciating nature and certainly not by destroying it.</p>
                                </div>
                            </div>
                        </div>


                        <div className='col-lg-4'>
                            <div className='sticky-bar positionsticky'>
                                <div className='post-title-wrap'>
                                    <Link to=''>POPULAR POSTS</Link>
                                </div>
                                <div className='post-wrapper alignleft mrt-20'>
                                    <div className='post-img mrr-20'>
                                        <img className="img-fluid" src={earthscoop2} alt="Earthood" id='' />
                                    </div>
                                    <div className='post-content'>
                                        <div className='post-titile'>
                                            <h4><Link to='/earthscoop2'> Key Stakeholder’s Perspective On Esg</Link></h4>
                                        </div>
                                        <div className='post-author'>
                                            <h6>- AVINASH KUMAR (EXECUTIVE DIRECTOR – ESG & NET ZERO)</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className='post-wrapper alignleft mrt-20'>
                                    <div className='post-img mrr-20'>
                                        <img className="img-fluid" src={earthscoop3} alt="Earthood" id='' />
                                    </div>
                                    <div className='post-content'>
                                        <div className='post-titile'>
                                            <h4><Link to='/earthscoop3'>UNITED NATIONS’ BRIDGE FROM KYOTO TO PARIS</Link></h4>
                                        </div>
                                        <div className='post-author'>
                                            <h6>- BY: DR. KAVIRAJ SINGH ( M D )</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className='post-wrapper alignleft mrt-20'>
                                    <div className='post-img mrr-20'>
                                        <img className="img-fluid" src={earthscoop6} alt="Earthood" id='' />
                                    </div>
                                    <div className='post-content'>
                                        <div className='post-titile'>
                                            <h4><Link to='/earthscoop6'>THE WORLD’S DRIVE TOWARDS CARBON NEUTRALITY</Link></h4>
                                        </div>
                                        <div className='post-author'>
                                            <h6>- BY: ARCHIT SRIVASTAVA</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Earthscoop4;

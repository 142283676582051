import React from 'react'
import { GrPrevious, GrNext } from 'react-icons/gr';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import slider1 from '../assets/images/services/slider1.png'
import slider2 from '../assets/images/services/slider2.png'
import slider3 from '../assets/images/services/slider3.png'
import slider4 from '../assets/images/services/slider4.png'

const Services=() => {
    function SampleNextArrow(props) {
        const { onClick }=props;
        return (
            <div style={{ color: "green" }} onClick={onClick}><GrNext className='next_icon' /> </div>
        );
    }

    function SamplePrevArrow(props) {
        const { onClick }=props;
        return (
            <div style={{ color: "green" }} onClick={onClick}><GrPrevious className='prev_icon' /> </div>
        );
    }
    var settings={
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 2,
        autoplay: true,
        autoplaySpeed: 5000,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div className='services' id='services'>
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='title'>
                            <h3>Our Services
                            </h3>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='service-slider'>
                        <Slider {...settings}>
                            <div>
                                <div className='slider-wrapper'>
                                    <div className='img-wrapper'>
                                        <img src={slider1} alt='slider1' />
                                    </div>
                                    <h4>Climate Change</h4>
                                    <p>Earthood was founded by a carbon market veteran Dr. Kaviraj Singh with his friend Mr. Ashok Gautam. Kavi and Ashok have been involved</p>
                                    <Link to='/climate'><button className='learn-btn'>Learn More</button></Link>
                                </div>
                            </div>
                            <div>
                                <div className='slider-wrapper'>
                                    <div className='img-wrapper'>
                                        <img src={slider2} alt='slider1' />
                                    </div>
                                    <h4>ESG & Decarbonization</h4>
                                    <p>Bringing ace thought-leadership to your decarbonization journey. Money saved is money earned is the basic fundamental on which our experts work. Our solutions assist you in maintaining your OPEX to minimal levels.</p>
                                    <Link to='/esg'><button className='learn-btn'>Learn More</button></Link>
                                </div>
                            </div>
                            <div>
                                <div className='slider-wrapper'>
                                    <div className='img-wrapper'>
                                        <img src={slider3} alt='slider1' />
                                    </div>
                                    <h4>Sustainability</h4>
                                    <p>Our Green Building Services offer consulting for securing various certifications for the buildings which are high performance structures and meet certain standards for reducing natural resource consumption.</p>
                                    <Link to='/sustainability'><button className='learn-btn'>Learn More</button></Link>
                                </div>
                            </div>
                            <div>
                                <div className='slider-wrapper'>
                                    <div className='img-wrapper'>
                                        <img src={slider4} alt='slider1' />
                                    </div>
                                    <h4>Agriculture</h4>
                                    <p>Helping farmers in doubling their income through forward and backward linkages. We connect the farmers to their customers by engaging in community mobilization, cluster identification, preparation and implementation of business plans and management of resources of the farmers’ organization.</p>
                                    <Link to='/agriculture'><button className='learn-btn'>Learn More</button></Link>
                                </div>
                            </div>

                        </Slider>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Services;

import { EARTHHOODIES_CORE_LIST, EARTHOODIES_POWERHOUSE_LIST, EARTHOODIES_TEAM_LIST } from "../../api";
import axiosInstance from "../../axiosInstance";

export const getEarthoodiesPowerList=(props) => onResponse => {
    try {
        axiosInstance.get(EARTHOODIES_POWERHOUSE_LIST)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getEarthoodiesTeamList=(props) => onResponse => {
    try {
        axiosInstance.get(EARTHOODIES_TEAM_LIST)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getEarthoodiesCoreList=(props) => onResponse => {
    try {
        axiosInstance.get(EARTHHOODIES_CORE_LIST)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
import React, { useState } from 'react';
import { BsFillTelephoneFill } from 'react-icons/bs';
import { FaWhatsapp } from 'react-icons/fa';
import { IoLogoWhatsapp } from 'react-icons/io';
import { GrClose, GrMail } from 'react-icons/gr';
// import intercom from '../../assets/images/intercom.svg'
import '../assets/css/components-styles/share.css'
import { Link } from 'react-router-dom';

const Share=() => {

    const [isActive, setIsActive]=useState(false);

    return (
        <React.Fragment>

            <Link className={`intercom ${isActive? '':'active'}`} to="https://api.whatsapp.com/send?phone=+91 8826459006&amp;text=Hi Team,%0AI would like to get in touch with an Earthoodie"
                    target="_blank"
                    title='Whatsapp'>
                {/* <img className="img-fluid lazy" src={intercom} alt="intercom-icon" /> */}
                <FaWhatsapp className='lazy' />
            </Link>

        </React.Fragment>
    )
}

export default Share;
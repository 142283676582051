import React from 'react'
import Slider from 'react-slick';
import { GrPrevious, GrNext } from 'react-icons/gr';
import { getMileStoneList } from '../Redux/Home/action';
import { useEffect } from 'react';
import { useState } from 'react';

const Milestone = () => {
    const [milestoneList, setMilestoneList] = useState([])

    function SampleNextArrow(props) {
        const { onClick } = props;
        return (
            <div style={{ color: "green" }} onClick={onClick}><GrNext className='next_icon' /> </div>
        );
    }

    function SamplePrevArrow(props) {
        const { onClick } = props;
        return (
            <div style={{ color: "green" }} onClick={onClick}><GrPrevious className='prev_icon' /> </div>
        );
    }
    var settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    useEffect(() => {
        getMileStoneList()((response) => {
            if (response && response.status) {
                setMilestoneList(response?.data)
            }
        });
    }, [])
    return (
        <section className='milestone-wrapper' id='milestone-wrapper'>
            <div className='milestone'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='title'>
                                <h5>We do it well.</h5>
                                <h3>Our Milestones</h3>
                            </div>
                        </div>

                        <div className='milestone-slider'>
                            <Slider {...settings}>
                                {milestoneList && milestoneList.map((item) =>
                                    <div>
                                        <div className='slider-wrapper'>
                                            <h2><span>{item.year}</span> </h2>
                                            <h6>{item.title}</h6>
                                            <p dangerouslySetInnerHTML={{__html: item.short_description}}></p>
                                            <p className='subtitle'>Team Size : {item?.team_size} {item?.accreditions && `Accreditations: ${item?.accreditions}`}</p>
                                        </div>
                                    </div>
                                )}
                                {/* <div>
                                    <div className='slider-wrapper'>
                                        <h2><span>2016</span></h2>
                                        <h6>Going Global</h6>
                                        <p>First Branch office opened up in Brazil and two new business lines introduced.</p>
                                        <p className='subtitle'>Team Size: 15 Accreditations: 03</p>
                                    </div>
                                </div>
                                <div>
                                    <div className='slider-wrapper'>
                                        <h2><span>2017</span></h2>
                                        <h6>Tap East</h6>
                                        <p>New Branch opened in South Korea for Korean ETS Markets.</p>
                                        <p className='subtitle'>Team Size: 25 Accreditations: 05</p>
                                    </div>
                                </div>
                                <div>
                                    <div className='slider-wrapper'>
                                        <h2><span>2019</span></h2>
                                        <h6>Increasing Footprints</h6>
                                        <p>China Branch opened with a fully functional & ready to audit team.</p>
                                        <p className='subtitle'>Team Size: 45 Accreditations: 07</p>
                                    </div>
                                </div>
                                <div>
                                    <div className='slider-wrapper'>
                                        <h2><span>2020</span></h2>
                                        <h6>Amongst Many, AGAIN!</h6>
                                        <p>Biggest Carbon Verifier in the world. Most number of Auditors. World’s #1 as per UN records.</p>
                                        <p className='subtitle'>Team Size : 50 Accreditations: 08</p>
                                    </div>
                                </div>
                                <div>
                                    <div className='slider-wrapper'>
                                        <h2><span>2021</span></h2>
                                        <h6>One For The Paris Agreement</h6>
                                        <p>A new office was established in London & Agriculture service was introduced.</p>
                                        <p className='subtitle'>Team Size : 60 Accreditations: 12</p>
                                    </div>
                                </div>
                                <div>
                                    <div className='slider-wrapper'>
                                        <h2><span>2022</span></h2>
                                        <h6>New Accreditations </h6>
                                        <p>GAB and ICR accreditations</p>
                                        <p className='subtitle'>Team Size : 60 Accreditations: 12</p>
                                    </div>
                                </div>

                                <div>
                                    <div className='slider-wrapper'>
                                        <h2><span>2023</span></h2>
                                        <h6>Global Expansion</h6>
                                        <p>Registered office set up in Turkey in line with global expansion plans.</p>
                                        <p className='subtitle'>Team Size : 60 Accreditations: 12</p>
                                    </div>
                                </div> */}

                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Milestone;

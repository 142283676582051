import React, { useEffect, useState } from 'react'
import { Accordion, Form } from 'react-bootstrap';
import Layout from '../components/layout';
import pro1 from '../assets/images/accreditation/1.png'
import pro2 from '../assets/images/accreditation/10.png'
import {
    getProjectList, getRegionList,
    getMethodologyList,
    getProgramList,
    getServiceTypeList,
    getSectorList,
    getCountryList
} from '../Redux/Project/action';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
const Project = () => {
    const [projectList, setProjectList] = useState([])
    const [filterDtataList, setFilterDataList] = useState([])

    const [regionList, setRegionList] = useState([])
    const [regionIds, setRegionIds] = useState([])

    const [methodologyList, setMethodologyList] = useState([])
    const [methodologyIds, setMethodologyIds] = useState([])

    const [programList, setProgramList] = useState([])
    const [programIds, setProgramIds] = useState([])

    const [serviceTypeList, setServiceTypeList] = useState([])
    const [serviceTypeIds, setServiceTypeIds] = useState([])

    const [sectorList, setSectorList] = useState([])
    const [sectorIds, setSectorIds] = useState([])

    const [countryList, setCountryList] = useState([])
    const [countryIds, setCountryIds] = useState([])

    const handleFilter = (region) => {
        getProjectList(region)((response) => {
            if (response && response.status) {
                setProjectList(response?.data)
            }
        });
    }
    useEffect(() => {
        getProjectList()((response) => {
            if (response && response.status) {
                setProjectList(response?.data)
                setFilterDataList(response?.data)

            }
        });
        getRegionList()((response) => {
            if (response && response.status) {
                setRegionList(response?.data)
            }
        });
        getMethodologyList()((response) => {
            if (response && response.status) {
                setMethodologyList(response?.data)
            }
        });
        getProgramList()((response) => {
            if (response && response.status) {
                setProgramList(response?.data)
            }
        });
        getServiceTypeList()((response) => {
            if (response && response.status) {
                setServiceTypeList(response?.data)
            }
        });
        getSectorList()((response) => {
            if (response && response.status) {
                setSectorList(response?.data)
            }
        });
        getCountryList()((response) => {
            if (response && response.status) {
                setCountryList(response?.data)
            }
        });
    }, [])
    const handleChange = (e, setData, data) => {
        const value = e.target.value;
        const index = data.indexOf(value);
        let new_ids = data;

        if (index === -1) {
            // If the value does not exist in the array, push it
            new_ids = [...data, value];
        } else {
            // If the value already exists in the array, remove it
            new_ids = data.filter((i) => i !== value);
        }
        console.log(new_ids);
        setData(new_ids); // Update the state with the new data array
    };
    useEffect(() => {

        // let a = projectList.filter((i))
        // console.log(
        //     'regionIds', regionIds,
        //     'countryIds', countryIds,
        //     'methodologyIds', methodologyIds,
        //     'serviceTypeIds', serviceTypeIds,
        //     'programIds', programIds,
        //     'sectorIds', sectorIds
        // );

        const filteredData = filterDtataList.filter((item) => {
            const regionFilter = regionIds.length === 0 || regionIds.includes(item.region_id);
            const countryFilter = countryIds.length === 0 || countryIds.includes(item.country_id);
            const methodologyFilter = methodologyIds.length === 0 || methodologyIds.includes(item.methodology_id);
            const serviceTypeFilter = serviceTypeIds.length === 0 || serviceTypeIds.includes(item.service_type_id);
            const programFilter = programIds.length === 0 || programIds.includes(item.programme_id);
            const sectorFilter = sectorIds.length === 0 || sectorIds.includes(item.sector_id);

            return regionFilter && countryFilter && methodologyFilter && serviceTypeFilter && programFilter && sectorFilter;
        });
        console.log(filteredData);
        setProjectList(filteredData)

    }, [regionIds, countryIds, methodologyIds, serviceTypeIds, programIds, sectorIds, filterDtataList])
    return (
        <React.Fragment>
            <Fragment>
                <Helmet>
                    <title>Earthood</title>
                    <meta name="keywords" content="Earthood" />
                    <meta name="description" content="Secure carbon offset credits and achieve net zero goals with us" />
                    <meta property="og:url" content="https://www.earthood.in/" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content="Earthood" />
                    <meta property="og:description" content="Secure carbon offset credits and achieve net zero goals with us" />
                    <meta property="og:image" content="https://webapi.earthood.in/assets/assets/media/logos/earthood-logo.png" />
                </Helmet>
                <Layout>
                    <section className='project'>
                        <div className='banner'>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-sm-6'></div>
                                    <div className='col-sm-6'>
                                        <h2><span>MAKING AN IMPACT. EVERY NOOK & CORNER</span></h2>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='container'>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='title'>
                                        <h3><span>OUR PROJECTS AROUND THE GLOBE</span></h3>
                                    </div>
                                </div>
                                <div className='content-wrapper'>
                                    <div className='sidebar'>
                                        <Accordion defaultActiveKey={['1', '2', '3', '4', '5', '6']} alwaysOpen>
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header>REGION</Accordion.Header>
                                                <Accordion.Body>
                                                    {regionList && regionList.map((item) =>
                                                        <Form.Check onChange={(e) => { handleChange(e, setRegionIds, regionIds) }} value={item.id} aria-label={item.title} label={item.title} />
                                                    )}

                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header>COUNTRY</Accordion.Header>
                                                <Accordion.Body>
                                                    {countryList && countryList.map((item) =>
                                                        <Form.Check onChange={(e) => { handleChange(e, setCountryIds, countryIds) }} value={item.id} aria-label={item.title} label={item.title} />
                                                    )}
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="3">
                                                <Accordion.Header>METHODOLOGY</Accordion.Header>
                                                <Accordion.Body>
                                                    {methodologyList && methodologyList.map((item) =>
                                                        <Form.Check onChange={(e) => { handleChange(e, setMethodologyIds, methodologyIds) }} value={item.id} aria-label={item.title} label={item.title} />
                                                    )}
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="4">
                                                <Accordion.Header>PROGRAMME</Accordion.Header>
                                                <Accordion.Body>
                                                    {programList && programList.map((item) =>
                                                        <Form.Check onChange={(e) => { handleChange(e, setProgramIds, programIds) }} value={item.id} aria-label={item.title} label={item.title} />
                                                    )}
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="5">
                                                <Accordion.Header>SERVICE TYPE</Accordion.Header>
                                                <Accordion.Body>
                                                    {serviceTypeList && serviceTypeList.map((item) =>
                                                        <Form.Check onChange={(e) => { handleChange(e, setServiceTypeIds, serviceTypeIds) }} value={item.id} aria-label={item.title} label={item.title} />
                                                    )}
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="6">
                                                <Accordion.Header>SECTOR</Accordion.Header>
                                                <Accordion.Body>
                                                    {sectorList && sectorList.map((item) =>
                                                        <Form.Check onChange={(e) => { handleChange(e, setSectorIds, sectorIds) }} value={item.id} aria-label={item.title} label={item.title} />
                                                    )}
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>

                                    <div className='pro-list'>
                                        <div className='row'>
                                            {projectList && projectList.map((item) =>
                                                <div className='pro-list-sub-conatainer col-lg-4 col-md-6 col-12' >
                                                    <div className='pro-details'>
                                                        <div className='img-sec'>
                                                            <div className='img-wrapper'>
                                                                <img src={item?.image} alt='pro-img' className='img-fluid' />
                                                            </div>
                                                        </div>
                                                        <div className='content'>
                                                            <h4>{item?.programme}</h4>
                                                            <h6>{item?.short_description}</h6>
                                                            <p className='disabled'>Sector</p>
                                                            <h6>{item?.sector}</h6>
                                                            <p className='disabled'>Service type: {item?.service_type}</p>
                                                            <p className='disabled'>Methodology: {item?.methodology}</p>
                                                            <p className='disabled'>Region: {item?.region}/ {item?.country}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {/* <div className='col-lg-4 col-md-6 col-12'>
                                            <div className='pro-details'>
                                                <div className='img-sec'>
                                                    <div className='img-wrapper'>
                                                        <img src={pro2} alt='pro-img' className='img-fluid' />
                                                    </div>
                                                </div>
                                                <div className='content'>
                                                    <h4>Gold Standard</h4>
                                                    <h6>IOT Mabagas Limited power plant, Pudhuchatram</h6>
                                                    <p className='disabled'>Sector</p>
                                                    <h6>Energy industries</h6>
                                                    <p className='disabled'>Service type: Verification</p>
                                                    <p className='disabled'>Methodology: AMS ID Version 17</p>
                                                    <p className='disabled'>Region: Indian Sub-Continent/ India
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6 col-12'>
                                            <div className='pro-details'>
                                                <div className='img-sec'>
                                                    <div className='img-wrapper'>
                                                        <img src={pro1} alt='pro-img' className='img-fluid' />
                                                    </div>
                                                </div>
                                                <div className='content'>
                                                    <h4>Gold Standard</h4>
                                                    <h6>IOT Mabagas Limited power plant, Pudhuchatram</h6>
                                                    <p className='disabled'>Sector</p>
                                                    <h6>Energy industries</h6>
                                                    <p className='disabled'>Service type: Verification</p>
                                                    <p className='disabled'>Methodology: AMS ID Version 17</p>
                                                    <p className='disabled'>Region: Indian Sub-Continent/ India
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6 col-12'>
                                            <div className='pro-details'>
                                                <div className='img-sec'>
                                                    <div className='img-wrapper'>
                                                        <img src={pro1} alt='pro-img' className='img-fluid' />
                                                    </div>
                                                </div>
                                                <div className='content'>
                                                    <h4>Gold Standard</h4>
                                                    <h6>IOT Mabagas Limited power plant, Pudhuchatram</h6>
                                                    <p className='disabled'>Sector</p>
                                                    <h6>Energy industries</h6>
                                                    <p className='disabled'>Service type: Verification</p>
                                                    <p className='disabled'>Methodology: AMS ID Version 17</p>
                                                    <p className='disabled'>Region: Indian Sub-Continent/ India
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6 col-12'>
                                            <div className='pro-details'>
                                                <div className='img-sec'>
                                                    <div className='img-wrapper'>
                                                        <img src={pro1} alt='pro-img' className='img-fluid' />
                                                    </div>
                                                </div>
                                                <div className='content'>
                                                    <h4>Gold Standard</h4>
                                                    <h6>IOT Mabagas Limited power plant, Pudhuchatram</h6>
                                                    <p className='disabled'>Sector</p>
                                                    <h6>Energy industries</h6>
                                                    <p className='disabled'>Service type: Verification</p>
                                                    <p className='disabled'>Methodology: AMS ID Version 17</p>
                                                    <p className='disabled'>Region: Indian Sub-Continent/ India
                                                    </p>
                                                </div>
                                            </div>
                                        </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </Layout>
            </Fragment>

        </React.Fragment>

    )
}

export default Project;

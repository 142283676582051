import React from 'react'
import Slider from 'react-slick';
import Layout from '../components/layout';
import slider1 from '../assets/images/casestudy/slider1.png'
import slider2 from '../assets/images/casestudy/slider2.png'
import slider3 from '../assets/images/casestudy/slider3.png'
import banner_logo from '../assets/images/casestudy/banner-logo.png'
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
const Casestudy = () => {
  var settings = {
    infinite: true,
    speed: 1000,
    autoplay: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoPlay: true,
    initialSlide: 0,
    responsive: [

      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          initialSlide: 2
        }
      }

    ]
  };
  return (
    <Fragment>
      <Helmet>
        <title>Earthood</title>
        <meta name="keywords" content="Earthood" />
        <meta name="description" content="Secure carbon offset credits and achieve net zero goals with us" />
        <meta property="og:url" content="https://www.earthood.in/" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Earthood" />
        <meta property="og:description" content="Secure carbon offset credits and achieve net zero goals with us" />
        <meta property="og:image" content="https://webapi.earthood.in/assets/assets/media/logos/earthood-logo.png" />
      </Helmet>
      <Layout>
        <div className='casestudy sustainability'>
          <div className='banner'>
            <div className='container'>
              <div className='row'>
                <div className='col-md-5'>
                  <div className='img-wrapper'>
                    <img src={banner_logo} alt='slider1' />
                  </div>
                </div>
                <div className='col-md-7 dv-center'>
                  <div className='uw-line'>
                    <h3><span>CASE STUDIES</span> </h3>

                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* testimonial */}
          <section className='testim'>
            <div className='container'>

              <div className='row'>
                <div className='col-12'>
                  <Slider {...settings}>
                    <div>
                      <div className='slider-wrapper'>
                        <div className='img-wrapper'>
                          <img src={slider1} alt="slider-img" className='img-fluid' />
                        </div>
                        <div className='text-center '>
                          <h5>Climate Secure Services</h5>
                        </div>
                        <p className='pafter'>"Being the consultants for a large and wide-spread safe water access and clean
                          cooking project in 2 countries of Africa, Climate Secure had faced delays on the auditing front before
                          they reached out to Earthood. The young and energetic team took up the assignment and delivered the
                          final verification report in record time – just 5 weeks from the date of sharing the monitoring
                          report. The Project Owner has now booked Earthood for all their verifications for several monitoring
                          reports for the entire year in advance, just to make sure that the audit teams’ speedy deliveries
                          are ensured for longer.
                          "</p>

                      </div>
                    </div>
                    <div>
                      <div className='slider-wrapper'>
                        <div className='img-wrapper'>
                          <img src={slider2} alt="slider-img" className='img-fluid' />
                        </div>
                        <div className='text-center '>
                          <h5>AERA Group</h5>
                        </div>
                        <p className='pafter'>"Managing a large portfolio of projects tends to get complicated at times.
                          Same was the case with AERA Group who had a bunch of projects and a band of auditors to be working
                          with. They weren’t always able to communicate or get a response from the audit team for each of
                          their requirements in a timely manner. Earthood stepped in and ensured a maximum of 24 hour turn
                          around time for each of their queries, emails and calls. Decisions were taken quickly resulting
                          in proper clarity of the progress of each project and weekly action tracking. The auditing world
                          was no longer a black box for the clients.
                          "</p>

                      </div>
                    </div>
                    <div>
                      <div className='slider-wrapper'>
                        <div className='img-wrapper'>
                          <img src={slider3} alt="slider-img" className='img-fluid' />
                        </div>
                        <div className='text-center '>
                          <h5>DelAgua Group</h5>
                        </div>
                        <p className='pafter'>"Earthood during their first-time engagement with the client, learnt that the much complex
                          cook stove project in Rwanda had multiple issues of poor data representation which eventually led to lower emission
                          reductions reported than actual, resulting in a low cash flow, non-viable business case for the Project Owner. This
                          was sorted with heavy and in-depth technical assessment by the audit team.
                          The Project Owner continues to place their trust with their multiple other projects in Earthood.
                          "</p>

                      </div>
                    </div>

                  </Slider>
                </div>
              </div>
            </div>
          </section>
          {/* testimonial end*/}
        </div>
      </Layout>
    </Fragment>

  )
}

export default Casestudy;


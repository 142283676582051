import React, { useState, useEffect } from 'react'
import Slider from 'react-slick';
import left_img from '../assets/images/testimonial/testimonial-bg.png'
import avatar from '../assets/images/testimonial/author.png'
import { getTestimonialList } from '../Redux/Home/action';

const Testimonial=() => {
    const [testimonialList, setTestimonialList]=useState([])

    var settings={
        infinite: true,
        speed: 1000,
        dots: true,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoPlay: true,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                }
            }
        ]
    };
    // useEffect(() => {
    //     getTestimonialList()((response) => {
    //         if (response && response.status) {
    //             setTestimonialList(response?.data)
    //         }
    //     });
    // }, [])

    return (
        <React.Fragment>
            <div className='testimonial' id='testimonial'>
                <div className='container plr-100'>
                    <div className='testim-bg'></div>
                    <div className='testim-content'>
                        <div className='row d-center'>
                            <div className='col-md-5'>
                                <div className='testim-left'>
                                    <div className='img-wrapper'>
                                        <img src={left_img} className="img-fluid" alt="Earthood" id='testimonial_image' />
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-7'>
                                <div className='testim-right'>
                                    <div className='testim-content'>
                                        <div className='title'>
                                            <h3>What they Say</h3>
                                        </div>
                                        <div className='service-slider'>
                                            <Slider {...settings}>
                                                <div>
                                                    <div className='slider-wrapper'>

                                                        <h4>Masters of First Of Its Kind Project!</h4>
                                                        <p className='pafter'>Earthood was instrumental in the project’s progress, specially
                                                            when it was a first of it’s kind! We needed an auditor who could understand our idea and
                                                            deliver on time. The audit team worked seamlessly and their findings helped the project
                                                            to take a better shape. Thank you Kavi for your value added technical approach to make
                                                            this one a success. Looking forward to more</p>
                                                        <p className='author'>

                                                            <p className='author-name'>Isabelle Botticelli <span> COO - Mootral</span></p></p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='slider-wrapper'>

                                                        <h4>Took all the Pressure Off!</h4>
                                                        <p className='pafter'>Earthood changed our perception of auditors completely once we
                                                            switched to them. Seamless deliveries, constant support and fail-proof auditing.
                                                        </p>
                                                        <p className='author'>

                                                            <p className='author-name'>James Beaumont <span> CMD - DelAgua Group</span></p></p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='slider-wrapper'>

                                                        <h4>Transparent and unbiased opinion</h4>
                                                        <p className='pafter'>An insightful, elegant and competent team which illustrates
                                                            utmost clarity on the CDM EB rules and their application in the projects especially for
                                                            CDM POAverifications. ESPL team always give you a transparent and unbiased opinion even
                                                            on the complex issues arising because of multiple countries, variable technologies and
                                                            methodologies involved in the CDM PoAs.</p>
                                                        <p className='author'>

                                                            <p className='author-name'>Rohit Lohia <span>(CEO, Climate Secure) </span></p></p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='slider-wrapper'>

                                                        <h4>Invaluable advice</h4>
                                                        <p className='pafter'>Earthood is helping us with a large number of increasingly
                                                            complex projects. We are able to offer our clients innovative Green Buildings design
                                                            solutions with a reinstated confidence that compliance can be achieved without
                                                            compromise.</p>
                                                        <p className='author'>

                                                            <p className='author-name'>Mr. Sudhanshu Vashishtha <span> (MD, RAVIM Infra)</span></p></p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='slider-wrapper'>

                                                        <h4>12% reduction in consumption</h4>
                                                        <p className='pafter'>In just 12 months, Earthood helped us reduce energy
                                                            consumption by almost 12% purely by improving operational efficiency and minor tweaks
                                                            which we never paid heed to. I heartily recommend their service.
                                                        </p>
                                                        <p className='author'>

                                                            <p className='author-name'>Shishir Sangal <span> (Director, Siddheshwari Industries P. Ltd.)</span></p></p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='slider-wrapper'>

                                                        <h4>Direct Access to Quality Buyers By Effective Market Linkages</h4>
                                                        <p className='pafter'>You have opened a whole new world for us. We never thought
                                                            that farmers can get direct access to such a profile of clients ever, but with your
                                                            efforts on market linkages, we are at a far more comfortable position dealing with
                                                            quality buyers of our produce. Dhanyavaad bhaisaahab!</p>
                                                        <p className='author'>

                                                            <p className='author-name'>Mr. Arvind K. <span>(CEO, Lawad Sabji Producer Co. Ltd)</span></p></p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='slider-wrapper'>

                                                        <h4>Development through Upliftment</h4>
                                                        <p className='pafter'>We realised the importance of training and coaching when it
                                                            comes to continual improvement, only by the help and able guidance of Earthood. We
                                                            always consult our CBBO team whenever we have any doubts to clarify or wish to learn
                                                            more about a subject. We feel educated, uplifted and stronger with Earthood’s team
                                                            around us. Aashirwad aur shubhkaamnaayein!
                                                        </p>
                                                        <p className='author'>

                                                            <p className='author-name'>Ch. Ilam Singh Poswal<span>(President, Kisan Welfare Club of Karnal)</span></p></p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='slider-wrapper'>

                                                        <h4>High Accuracy & Precision</h4>
                                                        <p className='pafter'>In our wind farms, ESPL helped us to precisely find the
                                                            surface and underground water level and flow direction. This has helped our team to take
                                                            better decision to decide the optimal water drainage system location and type. Thank you
                                                            ESPL!

                                                        </p>
                                                        <p className='author'>

                                                            <p className='author-name'>Sivakumar Muthiah
                                                                <span>(Lead – Civil, Acciona Energy India Private Limited)</span></p></p>
                                                    </div>
                                                </div>

                                            </Slider>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default Testimonial

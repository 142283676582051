import React from 'react'
import Layout from '../../components/layout';
import earthscoop10 from '../../assets/images/earthscoop/10.jpg';
import earthscoop2 from '../../assets/images/earthscoop/2.png'
import earthscoop3 from '../../assets/images/earthscoop/3.png'
import earthscoop6 from '../../assets/images/earthscoop/6.png'
import { Link } from 'react-router-dom';
const Earthscoop10=() => {
    return (
        <Layout>
            <div className='inner-earthscoop'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-8'>
                            <div class="content">
                                <img className="img-fluid" src={earthscoop10} alt="Earthood" id='' />
                                <div className='title'><h3><span>CARBON BORDER ADJUSTMENT MECHANISM - TURKEY'S ANGLE TO EU BORDERS</span></h3>
                                    <h6>- BY KUBRA AGRIMAN (ASSISTANT GENERAL MANAGER – TURKEY BUSINESS)</h6>
                                </div>
                                <div className='pd-left'>
                                    <p>Green Deal Public Review Session, Green Summit, Sheraton Hotel, Adana</p>
                                    <p>The Green Summit event, organized in cooperation with the Adana Chamber of Industry and the German Agency for International Cooperation (GIZ), will be held on March 15, 2022, in Adana with sessions covering how the Turkish industry will be affected by the decisions taken within the scope of the European Green Deal published by the European Union on 11 December 2019, and how to eliminate the risks and threats to be encountered.
                                    </p>
                                    <p>In the sessions, it was often emphasized that the most important factor affecting international trade was the green transition policies accelerated around the world. Following the announcement of the European Green Deal, the target of being the first carbon-neutral continent by 2050, the EU Commission committed to adapt the EU's climate, energy, transport, and taxation policies to reduce net greenhouse gas emissions by at least 55% by 2030 compared to 1990 levels. With the publication of the Climate Law, EU Institutions and the Member States have become obliged to take the necessary measures at the EU and national levels to achieve the target. (European Comission, 2021).</p>
                                    <p>In July 2021, the ‘Fit for 55’ package was proposed. With this package, the Commission presented the Carbon Border Adjustment Mechanism (CBAM) as a market dynamic designed as a climate action tool to prevent the risk of carbon leakage, protecting the EU's integrity and global climate policy by reducing greenhouse gas emissions in the EU and globally, and encouraging relevant sectors to modernize, become more sustainable and reduce carbon content. (European Comission, n.d.).</p>
                                    <p>With CBAM, which constitutes the hot topic of the Green Summit event, it has been announced that sectors and companies outside the EU that already have a lower carbon content in their production cycles or that a similar carbon pricing mechanism is applied will benefit from this. In this context, the importance of establishing a Carbon Pricing Mechanism in Turkey was emphasized.</p>
                                    <p>Although the aluminum, cement, fertilizer, electrical power generation and iron-steel sectors will be regulated in the first phase within the scope of SKDM, Ms. Bahar GÜÇLÜ, Deputy Director General at the Ministry of Trade, announced that it is foreseen that sectors such as chemistry, glass and ceramics will be subject to regulation within this scope in the future.</p>
                                    <p>The CBAM, which is expected to become law in the autumn, was accepted by the European Council on March 15, 2022, when the Green Summit event was held. Within the scope of CBAM, it is aimed to import products produced in non-EU countries whose climate policies are not as ambitious as the EU. In this context, it is one of the important details mentioned in the Green Summit event that nearly 10% of Turkey's exports will be impacted by this practice.</p>
                                    <p>It was emphasized that within the scope of CBAM, a competent authority on the subject will be assigned in member countries. The implementation will be carried out by the importers located in the EU, and in this context, the importer will submit a declaration to the authorized authority containing the amount of the product imported with the equivalent emission volume on an annual basis. By purchasing CBAM certificates equivalent to the declared emission volume, it will comply with the pricing of these emissions.</p>
                                    <p>The first 3 years of SKDM, which will start in 2023, will be considered as a pilot phase and only reporting obligations will be imposed at this stage. Scope 1 and scope 2 emissions will be requested from the exporter on a facility basis and reporting will be carried out accordingly. On the other hand, the financial obligation is expected to start in 2026.</p>
                                    <p>At the event, it was discussed that issues such as product-based calculation, emissions verification processes and verification bodies have not yet been clarified but will become clear during the enactment process. While the grandfathering is still in force to prevent carbon leakage, it is aimed to gradually phase out the free allowances by 2026 and completely within 10 years. As the free allowances are allocated to the importer in the EU in 2026, the subject of using the same amount of the free allowances by the exporter is also expected to be applicable.</p>

                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='sticky-bar positionsticky'>
                                <div className='post-title-wrap'>
                                    <Link to=''>POPULAR POSTS</Link>
                                </div>
                                <div className='post-wrapper alignleft mrt-20'>
                                    <div className='post-img mrr-20'>
                                        <img className="img-fluid" src={earthscoop2} alt="Earthood" id='' />
                                    </div>
                                    <div className='post-content'>
                                        <div className='post-titile'>
                                            <h4><Link to='/earthscoop2'> Key Stakeholder’s Perspective On Esg</Link></h4>
                                        </div>
                                        <div className='post-author'>
                                            <h6>- AVINASH KUMAR (EXECUTIVE DIRECTOR – ESG & NET ZERO)</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className='post-wrapper alignleft mrt-20'>
                                    <div className='post-img mrr-20'>
                                        <img className="img-fluid" src={earthscoop3} alt="Earthood" id='' />
                                    </div>
                                    <div className='post-content'>
                                        <div className='post-titile'>
                                            <h4><Link to='/earthscoop3'>UNITED NATIONS’ BRIDGE FROM KYOTO TO PARIS</Link></h4>
                                        </div>
                                        <div className='post-author'>
                                            <h6>- BY: DR. KAVIRAJ SINGH ( M D )</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className='post-wrapper alignleft mrt-20'>
                                    <div className='post-img mrr-20'>
                                        <img className="img-fluid" src={earthscoop6} alt="Earthood" id='' />
                                    </div>
                                    <div className='post-content'>
                                        <div className='post-titile'>
                                            <h4><Link to='/earthscoop6'>THE WORLD’S DRIVE TOWARDS CARBON NEUTRALITY</Link></h4>
                                        </div>
                                        <div className='post-author'>
                                            <h6>- BY: ARCHIT SRIVASTAVA</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Earthscoop10;

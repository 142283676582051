import React from 'react'
import Layout from '../../components/layout';
import earthscoop11_img1 from '../../assets/images/earthscoop/11.1.jpg';
import earthscoop11_img2 from '../../assets/images/earthscoop/11.2.jpg';
import earthscoop2 from '../../assets/images/earthscoop/2.png'
import earthscoop3 from '../../assets/images/earthscoop/3.png'
import earthscoop6 from '../../assets/images/earthscoop/6.png'
import { Link } from 'react-router-dom';
const Earthscoop11=() => {
    return (
        <Layout>
            <div className='inner-earthscoop'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-8'>
                            <div class="content">
                                <img className="img-fluid" src={earthscoop11_img1} alt="Earthood" id='' />
                                <div className='title'><h3><span>DECODING ARTICLE 6.4 OF THE PARIS AGREEMENT: PARIS MECHANISM</span></h3>
                                    <h6>- BY: DR. KAVIRAJ SINGH</h6>
                                </div>
                                <div className='pd-left'>
                                    <p>After two weeks of tense negotiations and more than a day of extension than the planned event, the world leaders finally agreed on a deal in Glasgow on 13th November 2021. One of the most debated issues for last many years was article 6.4 of the Paris agreement which has taken better shape than ever. The details appear to be a bit complex but following is the summary what does it mean to the Climate Change, countries and companies.</p>
                                    <p>1. ‘Paris Mechanism’ (New name of CDM)</p>
                                    <p>The parties to the Paris Agreement (countries) agreed to adopt the rules, modalities and procedures for the mechanism established by Article 6.4 of the Paris Agreement. Parties will designate a body that will supervise the mechanism with its membership and rules of procedure and name it the ‘Supervisory Body’. The body will work more or less same as Executive Board to the CDM. This body will have at least two meetings in 2022. Any Party can generate the credits under the new mechanism and trade these to achieve their NDC targets. This open ups the new market for private companies to invest in carbon offset projects and trade the Paris Approved Carbon Credits.</p>
                                    <p>2. The Supervisory Body & Secretariat</p>
                                    <p>The Supervisory Body shall govern the mechanism under the authority and guidance of the CMA being fully accountable to CMA. The main task of supervisory body will be;</p>
                                    <ul>
                                        <li>Develop provisions for the development and approval of methodologies, validation, registration, monitoring, verification and certification, issuance,    renewal, first transfer from the mechanism registry, voluntary cancellation and other processes.</li>
                                        <li>Develop and approve methodologies and Review the existing CDM and other market mechanism program like VCS/GS/GCC, methodologies and in view to use them under the Paris mechanism</li>
                                        <li>Accredit the Operational Entities as Designated Operational Entities (Validation and Verification Auditors) under the new mechanism</li>
                                        <li>Establishing a registry for the mechanism</li>
                                        <li>Elaborate and further develop the rules for the new mechanism for recommendations in next COP in November in 2022.</li>
                                        <li>Registration and Verification of the activities as article 6.4</li>
                                        <li>Maintain the registry for the mechanism</li>
                                    </ul>
                                    <p>The supervisory committee shall be served by secretariat to perform its functions in the operation of the mechanism in accordance with the rules, modalities and procedures.</p>
                                    <p>3. Participating Party or Host Parties</p>
                                    <p>The host countries participating in mechanism shall inform the supervisory committee on:</p>
                                    <ul>
                                        <li>Information on the crediting period if its fixed or renewableInformation on the crediting period if its fixed or renewable</li>
                                        <li>How the activity relates and contribute to the implementation of its NDC</li>
                                        <li> Provide statement if the ERs issued will be used towards NDC or other international mitigation process like CORSIA. If ERs are intended to be used
                                            towards other purposes corresponding adjustment must be applied. a. Rest of the requirements are broadly same as in CDM like</li>
                                        <li>Country is a party to Paris Agreement and has comminated its NDC</li>
                                        <li> Provide authorization to the activity like CDM</li>
                                        <li>Declared a National Authority for the mechanism</li>
                                        <li>Specify baseline approaches, methodological requirement and additionality</li>
                                        <li>Crediting period to be applied and how its inline with its NDC</li>
                                    </ul>
                                    <p>4. Activity, Emission Reduction and participating Parties</p>
                                    <p>Any public or private entity named activity participants can participate in the emission reduction activity to register as an Article 6.4 Activity. The activity shall be design as per set rules and requirements by CMA and supervisory body.</p>
                                    <h5>This activity-</h5>
                                    <ul>
                                        <li>Shall have proven additionally and must not lead to increase in global emission</li>
                                        <li>Can be a Project Activity (PA) or Program of Activity (PoA)</li>
                                        <li>Undergo stakeholders consultation</li>
                                        <li>Shall apply a crediting period for the issuance of ER for a maximum 5 years, renewable a maximum of twice or maximum of 10 yrs with no option of renewable. Removal activity can apply for 15 yrs of renewable a maximum of twice.</li>
                                        <li>Crediting period shall not start before 2021 Other requirements like additionally demonstration, application of methodology, monitoring etc will be the same as in CDM.</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="content">
                                <img className="img-fluid" src={earthscoop11_img2} alt="Earthood" id='' />

                                <div className='pd-left'>
                                    <p>5. Validation and Verification</p>
                                    <p>The DOE shall assess the activity against the set rules and send the request for registration to supervisory body with the validation outcome. Supervisory body then will register the activity as an article ‘6.4 Activity’ The activity participant shall monitor he emissions and then DOE shall review and determine if the implementation of the activity and achieved ER inline to the set requirement. Supervisory body finally provide written assurance and distinguish that the authorized credits are for use towards the achievement of NDC and/or for other international mitigation purposes . The whole process of validation and verification is same as in existing CDM rules except for the NDC tag and 5% transfer of credits at first issuance towards the adaptation funds. At every subsequent issuances 2% credits will also be cancelled towards global mitigation goals.</p>
                                    <p>6. Mechanism Registry, Voluntary Cancellation & Levy of Shares of Proceeds</p>
                                    <p>Activity participants (project developers) may voluntarily request the registry administration to cancel the mechanism registry for any specified amount of credits. The registry will have pending account, holding account, retirement account, cancellation account and a share of proceed. The mechanism registry will be connected to other international registries under the Paris agreement framework. A Levey of 5% of Article 6.4 issuance shall be charged by the registry at every issuance.</p>
                                    <p>7. Avoidance of Double Accounting</p>
                                    <p>Where a host party (country) authorized A-6.4 ERs for use towards NDCs a corresponding adjustment for the first transferred shall be applied. The country where the mitigation took place subtracts the emission reduction from its accounting, while the acquiring country adds the emission reduction to its GHG accounting. Where a host party (country) authorized A-6.4 ERs for use towards NDCs a corresponding adjustment for the first transferred shall be applied. The country where the mitigation took place subtracts the emission reduction from its accounting, while the acquiring country adds the emission reduction to its GHG accounting. This will avoid any double accounting of emissions in mechanism. Similarly, if country authorizes credits to be used for other international mitigation purpose, a corresponding adjustment needs to be applied.</p>
                                    <p>8. Transition of Clean Development Mechanism (CDM) into Paris Mechanism</p>
                                    <p>The good news for the CDM project developers. Registered CDM PA or POA and also provisionally registered PA or POA may be transited into Paris Mechanism. The requirements are as follows;

                                    </p>
                                    <ul>
                                        <li>The request for such transition must be made before 31st December 2023.</li>
                                        <li> Host country approval for such project must reach supervisory committee before 31st December 2025</li>
                                        <li>These projects can use applied CDM methodology until 31st December 2025 but thereafter must use the methodology approved by Paris Mechanism</li>
                                    </ul>
                                    <p>9. Use of existing CERs (CDM Credits)</p>
                                    <p>Any CERs issued under CDM may be used towards achievement of an NDC provided following conditions are met;</p>
                                    <ul>
                                        <li>CDM PA/PoA was registered after 1st Jan 2013</li>
                                        <li>These CERs must be transferred to Paris Mechanism registry as pre-2021 emission reduction</li>
                                        <li>CERs can only be used towards achievement of first NDC only </li>
                                        <li>No corresponding adjustment to such CERs and no share of proceeds applied</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='sticky-bar positionsticky'>
                                <div className='post-title-wrap'>
                                    <Link to=''>POPULAR POSTS</Link>
                                </div>
                                <div className='post-wrapper alignleft mrt-20'>
                                    <div className='post-img mrr-20'>
                                        <img className="img-fluid" src={earthscoop2} alt="Earthood" id='' />
                                    </div>
                                    <div className='post-content'>
                                        <div className='post-titile'>
                                            <h4><Link to='/earthscoop2'> Key Stakeholder’s Perspective On Esg</Link></h4>
                                        </div>
                                        <div className='post-author'>
                                            <h6>- AVINASH KUMAR (EXECUTIVE DIRECTOR – ESG & NET ZERO)</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className='post-wrapper alignleft mrt-20'>
                                    <div className='post-img mrr-20'>
                                        <img className="img-fluid" src={earthscoop3} alt="Earthood" id='' />
                                    </div>
                                    <div className='post-content'>
                                        <div className='post-titile'>
                                            <h4><Link to='/earthscoop3'>UNITED NATIONS’ BRIDGE FROM KYOTO TO PARIS</Link></h4>
                                        </div>
                                        <div className='post-author'>
                                            <h6>- BY: DR. KAVIRAJ SINGH ( M D )</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className='post-wrapper alignleft mrt-20'>
                                    <div className='post-img mrr-20'>
                                        <img className="img-fluid" src={earthscoop6} alt="Earthood" id='' />
                                    </div>
                                    <div className='post-content'>
                                        <div className='post-titile'>
                                            <h4><Link to='/earthscoop6'>THE WORLD’S DRIVE TOWARDS CARBON NEUTRALITY</Link></h4>
                                        </div>
                                        <div className='post-author'>
                                            <h6>- BY: ARCHIT SRIVASTAVA</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Earthscoop11;
